import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {UserModel} from "../../../model/user.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PolicyService {

  constructor(private httpClient: HttpClient) {
  }

  public acceptPolicy(status = '1'): Observable<UserModel> {
    let params = new HttpParams().set('accept', status);
    return this.httpClient.get(environment.api_url + '/accept_policy', {params: params}).pipe(map((res: any) => {
      if (res.content) {
        return new UserModel().deserialize(res.content);
      }
    }));
  }
}
