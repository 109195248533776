import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';

import {SettingsFacade} from '../../settings/settings.facade';
import {Observable, throwError} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable()
export class LicenseInterceptorService implements HttpInterceptor {
  constructor(public auth: SettingsFacade, private toastr: ToastrService, private route: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap( evt => {
      if (evt instanceof HttpResponse) {
        if (evt.url !== environment.api_url + '/settings/license' &&
          evt.url !== environment.api_url + '/company/show?filter=all' && evt.url !== environment.api_url + '/company/woodpecker') {
          if (evt.body && evt.body.error !== 'license') {

          }
        }
        if (evt.body && evt.body.error === 'license') {
          this.toastr.error('License expired', 'License').onHidden.pipe(take(1)).subscribe(() => {
            this.route.navigate(['/settings']);
          });
        }
      }
    }));
  }
}
