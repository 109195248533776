import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LicenseState {

  private license = new BehaviorSubject<boolean>(true);
  private canMakeRequest = new BehaviorSubject<boolean>(true);

  constructor() {}

  getLicense(): Observable<boolean> {
    return this.license.asObservable();
  }

  setLicense(license: boolean) {
    this.license.next(license);
  }

  getCanMakeRequest(): Observable<boolean>{
    return this.canMakeRequest.asObservable();
  }

  setCanMakeRequest(state: boolean): void{
    this.canMakeRequest.next(state);
  }
}
