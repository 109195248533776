import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SettingsState} from '../../state/settings.state';
import {SettingsFacade} from '../../settings.facade';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WoodpeckerLabelsModel} from '../../model/woodpecker-labels.model';
import {ToastrService} from 'ngx-toastr';
import {catchError} from 'rxjs/operators';

@Component({
  selector: "app-woodpecker",
  templateUrl: "woodpecker.component.html",
  styleUrls: ["woodpecker.component.css"],
})

export class WoodpeckerComponent implements OnInit{

  woodpeckerLabels$: Observable<any>;
  woodpeckerForm: FormGroup;
  isUpdating$: Observable<boolean>;

  constructor(private settingsState: SettingsState,
              private settingsFacade: SettingsFacade,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              ) {
  }

  ngOnInit(): void {
    this.woodpeckerLabels$ = this.settingsFacade.getWoodpecker();
    this.woodpeckerForm = this.formBuilder.group({
      // email: [""],
      // first_name: [""],
      // last_name: [""],
      industry: ["", [Validators.maxLength(191)]],
      website: ["", [Validators.maxLength(191)]],
      tags: ["", [Validators.maxLength(191)]],
      title: ["", [Validators.maxLength(191)]],
      // phone: [""],
      // address: [""],
      // city: [""],
      // state: [""],
      // country: [""],

      snippet1: ["", [Validators.maxLength(191)]],
      snippet2: ["", [Validators.maxLength(191)]],
      snippet3: ["", [Validators.maxLength(191)]],
      snippet4: ["", [Validators.maxLength(191)]],
      snippet5: ["", [Validators.maxLength(191)]],
      snippet6: ["", [Validators.maxLength(191)]],
      snippet7: ["", [Validators.maxLength(191)]],
      snippet8: ["", [Validators.maxLength(191)]],
      snippet9: ["", [Validators.maxLength(191)]],
      snippet10: ["", [Validators.maxLength(191)]],
      snippet11: ["", [Validators.maxLength(191)]],
      snippet12: ["", [Validators.maxLength(191)]],
      snippet13: ["", [Validators.maxLength(191)]],
      snippet14: ["", [Validators.maxLength(191)]],
      snippet15: ["", [Validators.maxLength(191)]],
    });
    this.isUpdating$ = this.settingsState.isUpdating$();
    this.woodpeckerLabels$.subscribe((res: WoodpeckerLabelsModel) => {
      if(res){
        this.woodpeckerForm.patchValue(res);
      }
    });
  }

  save(): void{
    if(this.woodpeckerForm.valid){
      this.settingsFacade.updateFlowWoodpecker(this.woodpeckerForm.value).subscribe((res) => {
        this.toastrService.success("Success", "Saved");
        this.settingsState.setUpdating(false);
      }, (res) => {
        this.settingsState.setUpdating(false);
        if(res.content instanceof Array){
          this.toastrService.error(res.content.join("<br>"), "Error", {enableHtml: true})
        }
      })
    }
  }

  getControl(name: string){
    return this.woodpeckerForm.get(name);
  }

  getMaxLengthMessage(controlName: string){
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }
}
