import { Deserializable } from "../../model/deserializable";

export class SearchResultSingleModel implements Deserializable {

  public country: string;
  public disposable: boolean;
  public domain: string;
  public organisation: string;
  public state: string;
  public webmail: string;
  public emails: {
    department: string,
    first_name: string,
    last_name: string,
    linkedin: string,
    phone_number: string,
    position: string,
    seniority: string,
    twitter: string,
    type: string,
    value: string,
  }[];

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
