import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ResultsFacade} from '../../../results/results.facade';
import {CompanyFacade} from '../../../company/company.facade';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() modalTitle;
  @Input() message;
  @Input() type;
  @Input() id;
  @Input() ids;
  @Input() needToRedirect = true;
  @Input() companyType = 'all';

  constructor(public activeModal: NgbActiveModal, private resultsFacade: ResultsFacade, private companyFacade: CompanyFacade,
              private router: Router) { }

  ngOnInit() {

  }

  action() {
    switch (this.type) {
      case 'results':
        this.deleteResults();
        break;
      case 'result':
        this.deleteResult();
        break;
      case 'company':
        this.deleteCampaign();
        break;
      case "single":
        this.deleteEmail();
        break;
      case "emails":
        this.deleteEmails();
        break;
    }
  }

  deleteResult() {
    this.resultsFacade.deleteResult(this.id);
    this.activeModal.close();
  }

  deleteEmail(){
    this.resultsFacade.deleteEmail(this.id);
    this.activeModal.close();
  }

  deleteEmails(){
    if(this.ids) {
      this.resultsFacade.deleteEmails(this.ids);
    }
    this.activeModal.close();
  }

  deleteCampaign() {
    this.companyFacade.deleteCampaign(this.id, this.companyType);
    if(this.needToRedirect){
      this.router.navigate(['/']);
    }
    this.activeModal.close();
  }

  deleteResults() {
    if(this.ids) {
      this.resultsFacade.deleteResults(this.ids);
    }
    this.activeModal.close();
  }
}
