import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { PursueVariablesModel } from "../../model/pursue-variables.model";
import { PursueLabelModel } from "../../../settings/model/pursue-label.model";
import { Company } from "../../model/company.model";
import { tap } from "rxjs/operators";
import { CompanyFacade } from "../../company.facade";
import {SettingsFacade} from "../../../settings/settings.facade";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-pursue-tab",
  templateUrl: './pursue-tab.component.html',
  styleUrls: ['./pursue-tab.component.scss']
})

export class PursueTabComponent implements OnInit{

  @Input() company: Company;

  variables: PursueVariablesModel;
  pursueVariables$: Observable<{ name: string, label: string, type: string[], defaultValue: string }[]>;
  pursueVariablesForm: FormGroup;
  isLoading$: Observable<boolean>;
  pursueLabels$: Observable<PursueLabelModel>;
  resultVariables$: Observable<{name: string, value: string, type: string[]}[]>;
  checks = {};
  faCheck = faCheck;

  constructor(private formBuilder: FormBuilder,
              private companyFacade: CompanyFacade,
              private settingsFacade: SettingsFacade,
              public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.variables = this.company.pursue_variables;
    this.pursueVariables$ = this.companyFacade.getFlowPursueVariables().pipe(
      tap((res: {name: string, label: string, type: string[], defaultValue: string}[]) => {
        if(!this.pursueVariablesForm){
          this.initPursueForm(res);
        }
      }),
    );
    this.isLoading$ = this.companyFacade.isUpdating$();
    this.pursueLabels$ = this.settingsFacade.getPursue();
    this.resultVariables$ = this.companyFacade.getResultVariables();
  }

  initPursueForm(res){
    const controls = {};
    for(let i = 0; i < res.length; i++){
      if(this.needToAddControl(res[i])){
        const variableValue = res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name];
        if(variableValue && variableValue.indexOf("{{") !== -1 && variableValue.indexOf("}}") !== -1){
          this.checks[res[i].name] = true;
        }
        controls[res[i].name] = [{value: res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name], disabled: !!res[i].defaultValue}, res[i].validators];
      }
    }
    this.pursueVariablesForm = this.formBuilder.group(controls);
  }

  needToAddControl(data: any): boolean {
    return data.type.indexOf("all") !== -1 || data.type.indexOf(this.company.type) !== -1;
  }

  getControl(name: string): AbstractControl{ //ToDO this function need to create globally to prevent code repeat
    return this.pursueVariablesForm.get(name);
  }

  getMaxLengthMessage(controlName: string){ //ToDO this function need to create globally to prevent code repeat
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

  change(item: string){
    this.pursueVariablesForm.get(item).setValue("");
  }

  save() {
    this.companyFacade.setUpdating(true);
    this.companyFacade.updateFlowPursueVariables(this.company.id, this.pursueVariablesForm.value).subscribe((res) => {
      this.companyFacade.setUpdating(false);
      res.type = "pursue";
      this.activeModal.close(res);
    }, (res) => {
      this.companyFacade.setUpdating(false);
    });
  }

}
