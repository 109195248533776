import {Deserializable} from './../../model/deserializable';

export class WoodpeckerModel implements Deserializable {
  public id: number;
  public name: string;
  public status: string;
  public created: string;
  public from_name: string;
  public gdpr_unsubscribe: boolean;
  public folder_name: string;
  public folder_id: number;
  public from_email: string;
  public per_day: number;
  public bcc: string;
  public cc: string;

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
