import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {ImgFallbackModule} from 'ngx-img-fallback';
import {ModalComponent} from '../modal/modal.component';
import {SingleTableComponent} from './single.table.component';

@NgModule({
  declarations: [SingleTableComponent],
  imports: [
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    NgbRatingModule,
    ImgFallbackModule
  ],
  entryComponents: [
    ModalComponent
  ],
  exports: [SingleTableComponent]
})

export class SingleTableModule {}
