import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { ProfileComponent } from './components/profile/profile.component';
import {LinkedinGuard} from './guards/linkedin.guard';

const routes: Routes = [
  {
    path: 'settings',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/integrations',
    component: IntegrationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'connect/linkedin',
    component: ProfileComponent,
    canActivate: [LinkedinGuard],
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
