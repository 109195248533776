import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserModel} from '../../model/user.model';
import {WoodpeckerLabelsModel} from '../model/woodpecker-labels.model';
import {LemlistLabelModel} from "../model/lemlist-label.model";
import {MailshakeLabelModel} from "../model/mailshake-label.model";
import {PursueLabelModel} from "../model/pursue-label.model";
import {HighLevelVariablesModel} from "../../company/model/high-level-variables.model";
import {HighLevelLabelModel} from "../model/high-level-label.model";

@Injectable()
export class SettingsState {

  private connecting$ = new BehaviorSubject<boolean>(false);
  private user$ = new BehaviorSubject<UserModel>(null);
  private source$ = new BehaviorSubject<boolean>(false);
  private integrations$ = new BehaviorSubject<{woodpecker: WoodpeckerLabelsModel, lemlist: LemlistLabelModel, mailshake: MailshakeLabelModel, pursue: PursueLabelModel, highLevel: HighLevelLabelModel}>(null);
  private updating$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  isConnecting$(): Observable<boolean> {
    return this.connecting$.asObservable();
  }

  setConnecting(connecting: boolean) {
    this.connecting$.next(connecting);
  }

  setSource(connecting: boolean) {
    this.source$.next(connecting);
  }

  setUser(user: UserModel) {
    this.user$.next(user);
  }

  updateUser(user: UserModel): UserModel{
    const oldUser = this.user$.getValue();
    const newUser = Object.assign(oldUser, user);
    this.user$.next(newUser);
    return newUser;
  }

  getUser$(): Observable<UserModel> {
    return this.user$.asObservable();
  }

  updateFlowWoodpecker(data: WoodpeckerLabelsModel): void{
    const integrations = this.integrations$.getValue();
    integrations.woodpecker = data;
    this.integrations$.next(integrations);
  }

  updateFlowLemlist(data: LemlistLabelModel): void{
    const integrations = this.integrations$.getValue();
    integrations.lemlist = data;
    this.integrations$.next(integrations);
  }

  updateFlowMailshake(data: any): void{ //ToDo need to define model for this
    const integrations = this.integrations$.getValue();
    integrations.mailshake = data;
    this.integrations$.next(integrations);
  }

  updateFlowPursue(data: PursueLabelModel): void{
    const integrations = this.integrations$.getValue();
    integrations.pursue = data;
    this.integrations$.next(integrations);
  }

  updateFlowHighLevel(data){
    const integrations = this.integrations$.getValue();
    integrations.highLevel = data;
    this.integrations$.next(integrations);
  }

  getSource() {
    return this.source$.asObservable();
  }

  getUserModel(): UserModel {
    return this.user$.getValue();
  }

  setUpdating(updating: boolean): void{
    this.updating$.next(updating);
  }

  isUpdating$(){
    return this.updating$.asObservable();
  }

  setIntegration(integration: any): void{
    this.integrations$.next(integration);
  }

  getIntegrations(){
    return this.integrations$.asObservable();
  }

  updateUserConnectWoodpecker(token) {
    const user = this.user$.getValue();
    user.woodpecker = token;
    this.user$.next(user);
  }
}
