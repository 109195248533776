import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AdminFacade} from '../../../admin.facade';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private admin: AdminFacade) { }

  ngOnInit() {
  }

  delete() {
    this.admin.activeInactiveUser(this.data.user, this.data.active);
    this.onNoClick();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
