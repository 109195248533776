import {Deserializable} from '../../model/deserializable';


export class SearchModel implements Deserializable {
  public description: string;
  public place_id: string;

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
