import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RelatedUserFacade} from '../../../related-user.facade';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-related-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  userCreateForm: FormGroup;
  admin: false;
  subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<UserComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              public formBuilder: FormBuilder,
              private facade: RelatedUserFacade
              ) {}

  ngOnInit() {
    if (this.data.type === 'create') {
      this.userCreateForm = this.formBuilder.group({
        id: [''],
        name: [''],
        username: [''],
        password: [''],
        email: ['', [Validators.email]],
      });
      this.userCreateForm.controls['username'].disable();
    } else if (this.data.type === 'update') {
      const date = new Date(this.data.userData.license);
      this.userCreateForm = this.formBuilder.group({
        id: [this.data.userData.id],
        name: [this.data.userData.name],
        username: [this.data.userData.username],
        password: [''],
        email: [this.data.userData.email, [Validators.email]],
      });
      this.userCreateForm.controls['username'].disable();
    }
  }
  get f() { return this.userCreateForm.controls; }

  getErrorMessage(valueForm) {
    switch (valueForm) {
      case 'email':
        return this.f.email.errors.required ? 'You must enter a value' :
          this.f.email.errors.email ? 'Not a valid email' :
            '';
      default:
        break;
    }
  }

  action() {
    if (this.data.type === 'update') {
      this.userCreateForm.controls['username'].enable();
      this.facade.updateUser(this.userCreateForm.value);
    }
    this.userCreateForm.controls['username'].disable();
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {}

}
