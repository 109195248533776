import {NgModule} from "@angular/core";
import {RemovalComponent} from "./removal.component";
import {RemovalService} from "./api/removal.service";
import {CommonModule} from "@angular/common";
import {RemovalRoutingModule} from "./removal-routing.module";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    RemovalComponent,
  ],
  imports: [
    CommonModule,
    RemovalRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    RemovalService
  ]
})

export class RemovalModule {
}
