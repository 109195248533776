import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyFacade } from '../../company.facade';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Company} from '../../model/company.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() modalTitle;
  @Input() modalType;
  @Input() companyId;
  companyForm: FormGroup;
  submitted = false;

  isProgress$: Observable<boolean>;

  constructor(public activeModal: NgbActiveModal, private companyFacade: CompanyFacade, private formBuilder: FormBuilder,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.isProgress$ = this.companyFacade.isUpdating$();
    this.companyForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['location', [Validators.required]]
    });
  }

  create_update() {
    if (this.companyForm.invalid) {
      return;
    }
    this.submitted = true;
    if (this.modalType === 'create') {
      this.companyFacade.createflowCompanies(this.companyForm.value).subscribe(res => {
        if (res instanceof Company) {
          this.toastr.success('Complete!', 'Success');
          this.activeModal.close();
          this.companyFacade.loadflowCompanies(this.companyForm.value.type).subscribe();
        }
        this.submitted = false;
      });
    }
  }

  get f() { return this.companyForm.controls; }

}
