import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyService } from './api/company.service';
import { CompanyComponent } from './components/company/company.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { CompanyFacade } from './company.facade';
import { CompanyState } from './state/company.state';
import { CompanyListComponent } from './containers/company-list/company-list.component';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyCreateComponent } from './containers/company-create/company-create.component';
import { ModalComponent } from './containers/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingModalComponent } from './containers/setting-modal/setting-modal.component';
import { LemlistTabComponent } from "./containers/lemlist-tab/lemlist-tab.component";
import { MailshakeTabComponent } from "./containers/mailshake-tab/mailshake-tab.component";
import { PursueTabComponent } from "./containers/pursue-tab/pursue-tab.component";
import { HighLevelTabComponent } from "./containers/high-level-tab/high-level-tab.component";



@NgModule({
  declarations: [
    CompanyComponent,
    CompanyListComponent,
    CompanyCreateComponent,
    ModalComponent,
    SettingModalComponent,
    LemlistTabComponent,
    MailshakeTabComponent,
    PursueTabComponent,
    HighLevelTabComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MalihuScrollbarModule,
    CompanyRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CompanyComponent],
  entryComponents: [
    ModalComponent,
    SettingModalComponent,
  ],
  providers: [CompanyService, CompanyFacade, CompanyState]
})
export class CompanyModule { }
