import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SearchResultModel} from '../../search/model/searchResult.model';
import {WoodpeckerModel} from '../../settings/model/woodpecker.model';

@Injectable()
export class ResultState {
  private loadResult$ = new BehaviorSubject<boolean>(false);
  private resultsDate$ = new BehaviorSubject<SearchResultModel[]>([]);
  private resultsDatePeople$ = new BehaviorSubject<SearchResultModel[]>([]);
  private woodpeckerCampaigns$ = new BehaviorSubject<WoodpeckerModel[]>(null);
  private totalItems$ = new BehaviorSubject<number>(0);
  private allItems$ = new BehaviorSubject<number>(0);
  private withEmailItems$ = new BehaviorSubject<number>(0);
  private withoutEmailItems$ = new BehaviorSubject<number>(0);
  private resultSingle = new BehaviorSubject<any>([]);
  private nextPage$ = new BehaviorSubject<number | null>(null);
  private searchHasNextPage = false;
  private addressComponents: Object = {
    state: "administrative_area_level_1",
    country: "country",
    subcountry: "administrative_area_level_2",
    city: "locality",
    sublocality: "sublocality",
    street: "route",
    street_number: "street_number",
    postal_code: "postal_code",
  };
  private itemsPerPage = 50;
  private searchValue = "";
  private currentPage = 0;

  constructor() { }

  isLoading$(): Observable<boolean> {
    return this.loadResult$.asObservable();
  }

  getItemsPerPage(): number{
    return this.itemsPerPage;
  }

  setItemsPerPage(items: number){
    this.itemsPerPage = items;
  }

  getSearchValue(): string{
    return this.searchValue;
  }

  setSearchValue(value: string){
    this.searchValue = value;
  }

  getCurrentPage(): number{
    return this.currentPage;
  }

  setCurrentPage(page: number): void{
    this.currentPage = page;
  }

  setSearchHasNexPage(hasNextPage: boolean): void{
    this.searchHasNextPage = hasNextPage;
  }

  getSearchHasNextPage(): boolean{
    return this.searchHasNextPage;
  }

  setTotalItems(items: number): void{
    this.totalItems$.next(items);
  }

  setAllItems(items: number): void{
    this.allItems$.next(items);
  }
  getAllItems(): Observable<number>{
    return this.allItems$.asObservable();
  }

  setWithEmailsItems(items: number): void{
    this.withEmailItems$.next(items);
  }
  getWithEmailsItems(): Observable<number>{
    return this.withEmailItems$.asObservable();
  }

  setWithOutEmailsItems(items: number): void{
    this.withoutEmailItems$.next(items);
  }
  getWithOutEmailsItems(): Observable<number>{
    return this.withoutEmailItems$.asObservable();
  }

  getTotalItems(): Observable<number>{
    return this.totalItems$.asObservable();
  }

  getAddressComponents(){
    return this.addressComponents;
  }

  setLoading(loading: boolean) {
    this.loadResult$.next(loading);
  }

  getResult$(): Observable<SearchResultModel[]> {
    return this.resultsDate$.asObservable();
  }

  getResultPeople$(): Observable<SearchResultModel[]> {
    return this.resultsDatePeople$.asObservable();
  }

  getResultSingle(): Observable<any>{
    return this.resultSingle.asObservable();
  }

  setResult(result: SearchResultModel[]) {
    this.resultsDate$.next(result);
  }

  addFlowResult(result: SearchResultModel[]){
    const results = this.resultsDate$.getValue();
    results.push(...result);
    this.resultsDate$.next(results);
  }

  addFlowResultPeople(result: SearchResultModel[]){
    const results = this.resultsDatePeople$.getValue();
    results.push(...result);
    this.resultsDatePeople$.next(results);
  }

  addFlowResultSingle(result: SearchResultModel[]){
    const results = this.resultSingle.getValue();
    results.push(...result);
    this.resultSingle.next(results);
  }

  setResultPeople(result: SearchResultModel[]) {
    this.resultsDatePeople$.next(result);
  }

  setNextPage(page: number | null): void{
    this.nextPage$.next(page);
  }

  getNextPage(): Observable<number | null>{
    return this.nextPage$.asObservable();
  }

  setResultSingle(result){
    this.resultSingle.next(result);
  }

  setwoodpeckerCampaigns(campaigns: WoodpeckerModel[]) {
    this.woodpeckerCampaigns$.next(campaigns);
  }

  getwoodpeckerCampaigns$() {
    return this.woodpeckerCampaigns$.asObservable();
  }

  deleteResult(id: number) {
    let results = this.resultsDatePeople$.getValue();
    let indexOfUpdated = results.findIndex(result => result.id === id);
    if (indexOfUpdated === -1) {
      results = this.resultsDate$.getValue();
      indexOfUpdated = results.findIndex(result => result.id === id);
      results.splice(indexOfUpdated, 1);
      this.resultsDate$.next([...results]);
    } else {
      results.splice(indexOfUpdated, 1);
      this.resultsDatePeople$.next([...results]);
    }
  }

  deleteEmail(id: number){
    let results = this.resultSingle.getValue();
    let indexOfElement = results.findIndex(result => result.id === id);
    if(indexOfElement !== -1){
      results.splice(indexOfElement, 1);
      this.resultSingle.next([...results]);
    }
  }
}
