import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../model/company.model';
import { CompanyFacade } from '../../company.facade';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { ModalComponent } from "../../../main/tables/modal/modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit, OnDestroy {

  public companies$: Observable<Company[]>;
  public updating$: Observable<boolean>;
  public scrollbarOptions = { axis: 'y', theme: 'dark' };
  public selectedType = 'all';
  faTrashAlt = faTrashAlt;

  constructor(public facade: CompanyFacade, private modalService: NgbModal) { }

  ngOnInit() {
    this.companies$ = this.facade.getflowCompanies$();
    this.updating$ = this.facade.isUpdatingFilter$();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('campaigns-page');
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('campaigns-page');
  }

  filter($event, input) {
    $event.stopPropagation();
    this.facade.loadflowCompanies(input).subscribe();
    if (input === 'all') {
      this.companies$ = this.facade.getflowCompanies$();
    } else {
      this.companies$ = this.facade.getflowCompaniesFilter$();
    }
    this.selectedType = input;
  }

  deleteCampaign(id: number): void{
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Delete campaign';
    modalRef.componentInstance.message = 'Are you sure you want to delete the campaign?';
    modalRef.componentInstance.type = 'company';
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.companyType = this.selectedType;
    modalRef.componentInstance.needToRedirect = false;
  }

}
