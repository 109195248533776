import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {SearchResultModel} from '../../../search/model/searchResult.model';
import {faCheck, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../modal/modal.component';
import {environment} from '../../../../environments/environment';
import {ResultsFacade} from '../../../results/results.facade';
import {Company} from '../../../company/model/company.model';
import { EmailsModalComponent } from './emails-modal/emails-modal.component';
import { HttpClient } from '@angular/common/http';
import { EmailDetailsState } from './emails-modal/state/EmailDetails.state';

@Component({
  selector: 'app-people-table',
  templateUrl: './people.table.component.html',
  styleUrls: ['./people.table.component.css']
})
export class PeopleTableComponent implements OnInit,OnChanges, OnDestroy {
  @Input() changePage: any;
  @Input() isCheckboxes: boolean = false;
  @Input() tableData: Observable<any>;
  @Input() totalItems: number;
  @Input() checkboxes: boolean = true;
  @Input() company: Company;
  @Input() ghlSelectedCnt: any;
  @Output() withoutResultsGhl = new EventEmitter();
  @Output() outputData = new EventEmitter();
  @Output() outputDataGHL = new EventEmitter();
  @Output() selectAll = new EventEmitter();
  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  public select = false;
  public selectAllResults = false;
  public checkBxes = [];
  public allCheckBoxes = [];
  public ghlCheckBoxes = [];
  public withoutResults = [];
  public checkedList: any;
  public checkedListGHL: any;
  public open_table: number;
  public noResult = false;
  private subscriber;
  nextPage: number | null;
  private nextPageSubscription: Subscription;

  @Output() peopleLoaded = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private resultsFacade: ResultsFacade,
    private httpClient: HttpClient,
    private emailDetailsState: EmailDetailsState
  ) { }

  ngOnInit() {
    this.collectCheckboxData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(typeof changes.company !== "undefined" && !changes.company.firstChange){
      this.select = false;
      this.selectAllResults = false;
      this.collectCheckboxData();
    }
    if(typeof changes.changePage !== "undefined" && !changes.changePage.firstChange) {
      if(this.selectAllResults) {
        this.select = true;
        this.selectAll.emit(this.selectAllResults);
        this.ghlCheckBoxes.map((item: any) => {
          return item.isChecked = true;
        });
      }
    }
  }

  collectCheckboxData(){
    this.nextPageSubscription = this.resultsFacade.getNextPage().subscribe((res) => {
      this.nextPage = res;
    })
    this.subscriber = this.tableData.subscribe( res => {
      this.select = false;
      this.checkBxes = [];
      this.ghlCheckBoxes = [];
      this.noResult = false;
      if (res !== null && res.length !== 0) {
        res.map((item, index) => {
          this.open_table = 0;
          let isAdded = false;
          this.allCheckBoxes.map((checkbox, index) => {
            if(checkbox.value === item.name) {
              isAdded = true;
              return;
            }
          });
          if(!isAdded) {
            this.checkBxes.push({
              id: index,
              value: item.name,
              isChecked: true
            });
          }
          this.allCheckBoxes.push({
            id: index,
            value: item.name,
            isChecked: true
          });
          this.ghlCheckBoxes.push({
            id: index,
            value: item.id,
            to_be_sent_ghl: item.to_be_sent_ghl,
            isChecked: false
          });
        });
      } else {
        this.noResult = true;
      }
      this.getCheckedItemList();
    });
  }
  collectTableData() {
    this.outputData.emit(this.checkedList);
    this.peopleLoaded.emit('people');
  }

  checkUncheckAll() {
    this.ghlCheckBoxes.forEach((item, index) => {
      this.ghlCheckBoxes[index].isChecked = this.select;
    });
    if(!this.select) {
      this.selectAllResults = false;
    }
    this.getCheckedItemListGHL();
  }
  checkUncheckAllResults() {
    this.selectAllResults = !this.selectAllResults;
    this.selectAll.emit(this.selectAllResults);
    if(!this.selectAllResults) {
      this.select = false;
      this.checkUncheckAll();
    }
  }


  isAllSelected(changedItem) {
    this.select = changedItem.isChecked;
    if(!this.select && this.selectAllResults) {
      this.withoutResults.push(changedItem.value)
    } else {
      this.withoutResults = this.withoutResults.filter((item, i)=> this.withoutResults[i] !== changedItem.value)
    }

    this.withoutResultsGhl.emit(this.withoutResults);
    this.getCheckedItemListGHL();
  }
  getCheckedItemListGHL() {
    this.checkedListGHL = [];
    this.ghlCheckBoxes.forEach((item, index) => {
      if (this.ghlCheckBoxes[index].isChecked) {
        this.tableData
          .pipe(
            map((result: SearchResultModel[]) => {
              return result.find((res: SearchResultModel) => {
                return res.id === this.ghlCheckBoxes[index].value;
              });
            }))
          .subscribe(result => {
            if(result)
              this.checkedListGHL.push(result.id);
          });
      }
    });
    this.collectGHLTableData();
  }
  collectGHLTableData() {
    this.outputDataGHL.emit(this.checkedListGHL);
  }


  getCheckedItemList() {
    this.checkedList = [];
    this.checkBxes.forEach((item, index) => {
      if (this.checkBxes[index].isChecked) {
        this.tableData.pipe().subscribe(results => {
          results.map( ( result ) => {
            if(item.value === result.name) {
              this.checkedList.push(result);
              return;
            }
          })
        });
      }
    });
    this.collectTableData();
  }

  show(i) {
    if (this.open_table === i) {
      this.open_table = 0;
    } else {
      this.open_table = i;
    }
  }
  viewEmails(result): void {
    try {
      this.httpClient.post(`${environment.api_url}/company/email-details/${result.id}`, {}).pipe(
          map( (response: any) => {
            this.emailDetailsState.setEemailDetails$(response);
            this.modalService.open(EmailsModalComponent, { size: 'xl' });
          })).subscribe();
    } catch (e) {}
  }
  deleteResult(id) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Delete result';
    modalRef.componentInstance.message = 'Are you sure you want to delete the result?';
    modalRef.componentInstance.type = 'result';
    modalRef.componentInstance.id = id;
  }
  deleteSelectedResults() {
    if(this.checkedListGHL && this.ghlSelectedCnt) {
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.modalTitle = 'Delete results';
      modalRef.componentInstance.message = 'Are you sure you want to delete the selected results?';
      modalRef.componentInstance.type = 'results';
      modalRef.componentInstance.ids = this.checkedListGHL;
    }
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.nextPageSubscription.unsubscribe();
    this.resultsFacade.setNextPage(0);
  }

  get defaultimage(): string {
    return environment.api_image + 'img/avatar.png';
  }

  loadMorePeople() {
    this.resultsFacade.loadResult(this.company.id, this.nextPage).subscribe();
  }

}
