import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsComponent } from './components/results/results.component';
import { ResultsRoutingModule } from './results-routing.module';
import { ResultsApi } from './api/results.api';
import { ResultState } from './state/result.state';
import { ResultsFacade } from './results.facade';
import { TableModule } from '../main/tables/location/table.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PeopleTableModule } from '../main/tables/people/peopleTable.module';
import {SingleTableModule} from '../main/tables/single/single.table.module';
import {PaginationComponent} from "./components/pagination/pagination.component";



@NgModule({
  declarations: [ResultsComponent, PaginationComponent],
  imports: [
    CommonModule,
    ResultsRoutingModule,
    TableModule,
    PeopleTableModule,
    SingleTableModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  exports: [ResultsRoutingModule],
  providers: [ResultsApi, ResultState, ResultsFacade]
})
export class ResultsModule { }
