import {Deserializable} from '../../model/deserializable';

export class WoodpeckerLabelsModel implements Deserializable{

  public user_id: number;
  public woodpecker_id: number;
  public email: string;
  public first_name: string;
  public last_name: string;
  public status: string;
  public industry: string;
  public website: string;
  public tags: string;
  public title: string;
  public phone: string;
  public address: string;
  public state: string;
  public city: string;
  public country: string;
  public snippet1: string;
  public snippet2: string;
  public snippet3: string;
  public snippet4: string;
  public snippet5: string;
  public snippet6: string;
  public snippet7: string;
  public snippet8: string;
  public snippet9: string;
  public snippet10: string;
  public snippet11: string;
  public snippet12: string;
  public snippet13: string;
  public snippet14: string;
  public snippet15: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
