import {Injectable} from '@angular/core';
import {SettingsState} from './state/settings.state';
import {SettingsApi} from './api/settings.api';
import {Observable} from 'rxjs';
import {UserModel} from '../model/user.model';

import {environment} from '../../environments/environment';
import {map, take, tap} from 'rxjs/operators';
import {LinkedinModel} from './model/linkedin.model';
import {LicenseState} from '../license/license.state';
import {WoodpeckerLabelsModel} from './model/woodpecker-labels.model';
import {ProspectLabelModel} from './model/prospect-label.model';
import {LemlistLabelModel} from "./model/lemlist-label.model";
import {Validators} from "@angular/forms";
import {MailshakeLabelModel} from "./model/mailshake-label.model";
import {PursueLabelModel} from "./model/pursue-label.model";
import {ConnectSourceResponseModel} from "./model/connect-source-response.model";
import { CookieService } from "../services/cookie.service";

@Injectable({
  providedIn: 'root'
})
export class SettingsFacade {

  constructor(private settingsState: SettingsState, private settingsApi: SettingsApi, private license: LicenseState, private cookie: CookieService) {
  }

  isConnecting$(): Observable<boolean> {
    return this.settingsState.isConnecting$();
  }

  setConnecting(connecting: boolean) {
    this.settingsState.setConnecting(connecting);
  }

  setUser(user: UserModel): void {
    this.settingsState.setUser(user);
  }

  updateFlowUser(user: UserModel): UserModel{
    return this.settingsState.updateUser(user);
  }

  setSource(connecting: boolean) {
    this.settingsState.setSource(connecting);
  }

  getSource(): Observable<boolean> {
    return this.settingsState.getSource();
  }

  getUser$(): Observable<UserModel> {
    return this.settingsState.getUser$();
  }

  getUserModel(): UserModel {
    return this.settingsState.getUserModel();
  }

  isUpdating$(): Observable<boolean>{
    return this.settingsState.isUpdating$();
  }

  connecting$(connecting): Observable<ConnectSourceResponseModel> {
    return this.settingsApi.connectSource(connecting).pipe(tap(res => {
      const user = this.updateFlowUser(res.user);
      this.cookie.setCookie('user', JSON.stringify(user), 1);
    }));
  }

  disconnectSource$(type): Observable<UserModel> {
    return this.settingsApi.disconnect({source: type}).pipe(tap(res => {
      const user = this.updateFlowUser(res);
      this.cookie.setCookie('user', JSON.stringify(user), 1);
    }));
  }

  setIntegrations(integration: any): void {
    this.settingsState.setIntegration(integration)
  }

  getIntegrations(): Observable<any> {
    return this.settingsState.getIntegrations();
  }

  getWoodpecker(): Observable<WoodpeckerLabelsModel> {
    return this.getIntegrations().pipe(
      map((res: any) => {
        if (res && res.woodpecker) {
          return res.woodpecker;
        }
        return res;
      })
    );
  }

  authWithJWT(token: string): Observable<any>{
    return this.settingsApi.authWithJWT(token);
  }

  getUser(): Observable<any>{
    return this.settingsApi.getUser();
  }

  updateFlowProspects(data): Observable<any> {
    return this.settingsApi.updateProspect(data);
  }

  updateFlowLemlist(data): Observable<any> {
    return this.settingsApi.updateLemlist(data).pipe(
      tap((res: any) => {
        if (res.status === "success") {
          this.settingsState.updateFlowLemlist(res.content);
        }
      })
    )
  }

  updateFlowMailshake(data): Observable<any> {
    return this.settingsApi.updateMailshake(data).pipe(
      tap((res: any) => {
        if (res.status === "success") {
          this.settingsState.updateFlowMailshake(res.content);
        }
      })
    )
  }

  updateFlowPursue(data){
    return this.settingsApi.updatePursue(data).pipe(
      tap((res: any) => {
        if(res.status === 'success'){
          this.settingsState.updateFlowPursue(res.content)
        }
      })
    )
  }

  updateFlowHighLevel(data){
    return this.settingsApi.updateHighLevel(data).pipe(
      tap((res: any) => {
        if(res.status === 'success'){
          this.settingsState.updateFlowHighLevel(res.content)
        }
      })
    )
  }

  getProspect(): Observable<ProspectLabelModel> {
    return this.getIntegrations().pipe(
      map((res) => {
        if (res && res.prospect) {
          return res.prospect;
        }
        return res;
      })
    )
  }

  getLemlist(): Observable<LemlistLabelModel> {
    return this.getIntegrations().pipe(
      map((res) => {
          if (res && res.lemlist) {
            return res.lemlist;
          } return res;
        }
      )
    )
  }

  getHighLevel(){
    return this.getIntegrations().pipe(map((res) => {
      if(res && res.high_level_variables){
        return res.high_level_variables;
      }
      return res;
    }))
  }

  getPursue(): Observable<PursueLabelModel>{
    return this.getIntegrations().pipe(
      map((res) => {
          if (res && res.pursue) {
            return res.pursue;
          } return res;
        }
      )
    )
  }

  getMailshake(): Observable<MailshakeLabelModel> {
    return this.getIntegrations().pipe(
      map((res) => {
          if (res && res.mailshake) {
            return res.mailshake;
          } return res;
        }
      )
    )
  }

  updateFlowWoodpecker(data: any): Observable<any> {
    this.settingsState.setUpdating(true);
    return this.settingsApi.updateWoodpecker(data).pipe(
      tap((res: any) => {
        if (res.status === "success") {
          this.settingsState.updateFlowWoodpecker(res.content);
        }
      })
    );
  }

  updateUserConnectWoodpecker(token) {
    this.settingsState.updateUserConnectWoodpecker(token);
  }

  updateUser(userData, file = null): Observable<UserModel> {
    return this.settingsApi.updateUser(userData);
  }

  linkedinConnect() {
    const x = screen.width / 2 - 400 / 2;
    const y = screen.height / 2 - 700 / 2;
    window.open(environment.linkedIn, '_blank', 'height=700,width=400,left=' + x + ',top=' + y);
  }

  checkLicense() {
    this.settingsApi.checkLicense().pipe(take(1)).subscribe(res => {
      this.license.setLicense(res.license);
    });
  }

  resetPassword(email): Observable<{ status: boolean }> {
    return this.settingsApi.resetPassword(email);
  }

  checkResetCode(code): Observable<any> {
    return this.settingsApi.checkResetCode(code);
  }

  getFormLabels(res: {labels: {name: string, label: string, defaultValue: string, validators: Validators[]}[], variables: any}){
    const formArray = {};
    for(let label of res.labels){
      if(!label.defaultValue){
        formArray[label.name] = [(res.variables && res.variables[label.name]) ? res.variables[label.name] : "", label.validators]
      }
    }
    return formArray;
  }

  getLicenseData(): Observable<any>{
    return this.settingsApi.getLicenseData();
  }
}
