import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {faLock, faUserAlt, faAt, faEyeSlash, faEye, faExclamation} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SettingsFacade} from '../../settings.facade';
import {Observable, Subscription} from 'rxjs';
import {UserModel} from '../../../model/user.model';
import {LicenseState} from '../../../license/license.state';
import {ToastrService} from 'ngx-toastr';
import {GeoState} from "../../../geo/geo.state";
import { CookieService } from "../../../services/cookie.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  faLock = faLock;
  faUserAlt = faUserAlt;
  faAt = faAt;
  faEyeSlash = faEyeSlash;
  faEye = faEye;
  submitted = false;
  public userForm: FormGroup;
  public user$: Observable<UserModel>;
  public selectedFile;
  public license$: Observable<boolean>;
  public isBlocked$: Observable<boolean>;
  password = {error: false, message: ''};
  newType = false;
  oldType = false;
  faExclamation = faExclamation;
  antiquityValues = [1, 2, 3, 0];
  defaultAntiquityValue = 0;
  leadsReceived: number;
  leadsReceiveLimitation: number;
  peopleLocationReceived: number;
  peopleLocationReceiveLimitation: number;
  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder, private settings: SettingsFacade, private cd: ChangeDetectorRef,
              private license: LicenseState, private toastr: ToastrService, private geo: GeoState, private cookie: CookieService) { }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('settings-page');
    this.license$ = this.license.getLicense();
    this.isBlocked$ = this.geo.getBlockedStatus();
    this.user$ = this.settings.getUser$();
    this.subscriptions.push(this.settings.getLicenseData().subscribe((res) => {
      this.leadsReceived = res.leadsReceived;
      this.leadsReceiveLimitation = res.leadsReceiveLimitation;
      this.peopleLocationReceived = res.peopleLocationReceived;
      this.peopleLocationReceiveLimitation=res.peopleLocationReceiveLimitation;
    }));
    this.user$.subscribe( user => { //ToDo need to unsubscribe
      this.userForm = this.formBuilder.group({
        id: [user.id, [Validators.required]],
        name: [user.name, [Validators.required]],
        username: [(user.username !== 'null' ? user.username : ''), [Validators.nullValidator]],
        email: [user.email, [Validators.required, Validators.email]],
        avatar: [''],
        antiquity: [user.antiquity ? user.antiquity : this.defaultAntiquityValue],
        oldPassword: [''],
        newPassword: ['', [Validators.minLength(6)]]
      }, {
        validators: this.Password('oldPassword', 'newPassword')
      });
    });
  }

  private Password(oldPassword1: string, newPassword1: string) {
    return (formGroup: FormGroup) => {
      const old = formGroup.controls[oldPassword1];
      const newP = formGroup.controls[newPassword1];

      if (newP.errors && !newP.errors.newPass) {
        return;
      }
      if (old.value !== '' && newP.value === '') {
        newP.setErrors({ newPass: true });
      } else if (old.value === '' && newP.value !== '') {
        old.setErrors({ oldPass: true });
      } else {
        newP.setErrors(null);
        old.setErrors(null);
      }
    };
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userForm.get('avatar').setValue(file);
    }
  }

  get f() { return this.userForm.controls; }

  updateUser() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('avatar', this.userForm.get('avatar').value);
    formData.append('id', this.userForm.get('id').value);
    formData.append('name', this.userForm.get('name').value);
    formData.append('email', this.userForm.get('email').value);
    formData.append('oldPassword', this.userForm.get('oldPassword').value);
    formData.append('newPassword', this.userForm.get('newPassword').value);
    formData.append("antiquity", this.userForm.get("antiquity").value);
    this.settings.updateUser(formData).subscribe( res => {
      const newUser = this.settings.updateFlowUser(res);
      this.cookie.setCookie('user', JSON.stringify(newUser), 1);
      this.toastr.success('Complete!', 'Success');
    }, (error) => {
      if (error.hasOwnProperty('oldPassword')) {
        this.password.error = true;
        this.password.message = error.oldPassword[0];
      }
      const messages = Object.values(error);
      if(messages && messages instanceof Array){
        this.toastr.warning(messages.join("<br>"), "Error", {enableHtml: true})
      }
      // this.toastr.warning(error.oldPassword[0], 'Error!');
      setTimeout(() => {
        this.password.error = false;
      }, 2000);
    });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('settings-page');
    for(let subscription of this.subscriptions){
      subscription.unsubscribe();
    }
  }

}
