import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {SettingsFacade} from '../settings/settings.facade';
import {LicenseState} from './license.state';

@Injectable({
  providedIn: 'root'
})
export class LicenseGuard implements CanActivate {

  constructor(private settings: SettingsFacade, private license: LicenseState, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     this.license.getLicense().subscribe(res => {
        if (res) {
          return true;
        } else {
          this.router.navigate(['/settings']);
        }
     });
     return true;
  }
}
