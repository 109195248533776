import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtService } from './../../services/jwt/jwt.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { SettingsFacade } from '../../settings/settings.facade';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotModalComponent } from '../modal/forgot-modal/forgot-modal.component';
import { CookieService } from "../../services/cookie.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  faCheck = faCheck;
  focusLogin = false;
  focusPass = false;
  public formError = false;
  currentYear = new Date().getFullYear();

  constructor(private formBuilder: FormBuilder, public auth: JwtService, public router: Router, private settings: SettingsFacade,
              private modalService: NgbModal, private cookie: CookieService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.modalService.dismissAll();
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.formError = false;
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    try {
      this.auth.login(this.registerForm.value.email, this.registerForm.value.password).subscribe(
        res => {
          this.settings.setUser(res);
          this.cookie.setCookie('user', JSON.stringify(res), 1);
          window.location.reload();
      },
        error => {
          this.formError = true;
        console.error(error);
      });
    } catch (e) {
      console.error(e);
    }
  }

  resetPassword() {
    const modalRef = this.modalService.open(ForgotModalComponent);
    modalRef.componentInstance.type = true;
  }

}
