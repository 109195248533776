import {Deserializable} from "../../model/deserializable";

export class PursueLabelModel implements Deserializable{
  email: string;
  name: string;
  address: string;
  data1: string;
  data2: string;
  data3: string;
  data4: string;
  data5: string;
  data6: string;
  data7: string;
  data8: string;
  data9: string;
  data10: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
