import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Company} from '../model/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) { }

  show(filter = 'all'): Observable<Company[]> {
    return this.httpClient.get<Company[]>
    (environment.api_url + `/company/show?filter=${filter}`, {}).pipe(
      map( (response: any) => response.content.map(company => new Company().deserialize(company))));
  }

  create_company(name): Observable<Company> {
    return this.httpClient.post<Company>(environment.api_url + `/company/create`, name).pipe(map(
      (response: any) => new Company().deserialize(response.content)));
  }

  update_company(company: Company): Observable<Company> {
    return this.httpClient.post<Company>(environment.api_url + '/company/update', company, {headers: { ignoreLoadingBar: '' }}).pipe(map(
      (response: any) => new Company().deserialize(response.content)));
  }

  load_company(companyId): Observable<Company> {
    return this.httpClient.get<Company>(environment.api_url + `/company/${companyId}`, {}).pipe(
      map((response: any) => new Company().deserialize(response.content))
    );
  }

  updateWoodpeckerVariables(companyId: number, data: any): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/company/woodpecker/${companyId}`, data);
  }

  getIntegrationCampaigns(source: string): Observable<any>{
    return this.httpClient.get(`${environment.api_url}/integrations/campaigns/${source}`)
  }

  updateLemlistVariables(companyId: number, data): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/company/lemlist/${companyId}`, data);
  }

  updateHighLevelVariables(companyId: number, data): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/company/high_level/${companyId}`, data);
  }

  updateMailshakeVariables(companyId: number, data): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/company/mailshake/${companyId}`, data);
  }

  deleteCampaign(companyId) {
    return this.httpClient.post(environment.api_url + '/company/delete', {id: companyId});
  }

  zapierAccessAll(companyId: number, checked: boolean, searchValue: string = ""): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/company/email/zapierall`, {id: companyId, checked: checked, keyword: searchValue});
  }

  updatePursueVariables(companyId: number, data: any) {
    return this.httpClient.put(`${environment.api_url}/company/pursue/${companyId}`, data);
  }

  getCompanyHighLevelVariables(companyId: number){
    return this.httpClient.get(`${environment.api_url}/company/${companyId}/variables/high_level`);
  }
}
