import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {GeoState} from "../../geo/geo.state";

@Injectable()
export class GeoInterceptorService {

  private messageShown = false;

  constructor(private route: Router, private toastrService: ToastrService, private geoState: GeoState) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: any): Observable<HttpEvent<any>> => {
      if(err.status === "error" && err.message === "The Project is Blocked For Europe Countries"){
        if(!this.messageShown){
          this.geoState.setBlockedStatus(true);
          this.messageShown = true;
          this.toastrService.error("ERROR!", err.message)
        }
        this.route.navigate(["/blocked"]);
      }
      throw err;
    }));
  }
}
