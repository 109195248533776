import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "pagination.component.html",
  styleUrls: ["pagination.component.css"]
})

export class PaginationComponent implements OnInit, OnChanges{

  @Input() totalItems: number;
  @Input() elementsPerPage: number;
  @Input() currentPage: number;
  @Input() companyId: number;
  @Output() onPagination = new EventEmitter<number>()
  pagination: number[] = [];
  nextActive = false;
  prevActive = false;
  readonly itemsPerSide = 3;
  private isLoading = false;

  constructor() {
  }

  ngOnInit() {
    this.generatePagination();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if((changes.elementsPerPage && !changes.elementsPerPage.isFirstChange())
      || (changes.currentPage && !changes.currentPage.isFirstChange())
      || (changes.companyId && !changes.companyId.isFirstChange())
      || (changes.totalItems && !changes.totalItems.isFirstChange())
    ){
      this.generatePagination()
    }
  }

  generatePagination(): void{
    this.pagination = [];
    const pageCount = this.getPageCount();
    this.prevActive = this.currentPage > 1;
    this.nextActive = this.currentPage < pageCount;
    let firstPage = this.currentPage - this.itemsPerSide;
    if(firstPage < 1){
      firstPage = 1;
    }

    for(let i = firstPage; i < this.currentPage + this.itemsPerSide; i++){
      if(i <= pageCount){
        this.pagination.push(i);
      } else break;
    }
  }

  paginate(page: number, active: boolean = true){
    if(page !== this.currentPage && active){
      this.onPagination.emit(page);
    }
  }

  private  getPageCount(): number {
    let totalPage: number = 0;
    if(this.totalItems > 0 && this.elementsPerPage > 0){
      const pageCount = this.totalItems / this.elementsPerPage;
      const roundedPageCount = Math.floor(pageCount);

      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return totalPage;
  }
}
