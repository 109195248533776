import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Injectable} from "@angular/core";
import {LicenseState} from "../../license/license.state";
import {LoadingBarService} from "@ngx-loading-bar/core";
import {SearchFacade} from "../../search/search.facade";

@Injectable()
export class RequestCountInterceptorService implements HttpInterceptor{

  constructor(private toastrService: ToastrService, private licenseState: LicenseState, private loadingBar: LoadingBarService, private searchFacade: SearchFacade) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((event: any) => {
        this.loadingBar.complete();
        if (event instanceof HttpErrorResponse) {
          if (event.status === 403) {
            this.licenseState.setCanMakeRequest(false);
            this.toastrService.error(this.searchFacade.generateLicenseErrorMessage(event.error.message), null, {
              enableHtml: true
            });
            this.searchFacade.setUpdating(false);
            this.searchFacade.setUpdatingRes(false);
          }
        }
        return throwError(event);
      })
    )
  }
}
