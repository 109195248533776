import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PaginateUser} from '../model/paginate-user';
import {ProductsModel} from "../model/products-model";
import {SubscriptionTypeModel} from "../model/subscription-type-model";

@Injectable({
  providedIn: 'root'
})
export class AdminState {
  private isUpdating$ = new BehaviorSubject<boolean>(false);
  private adminUsers$ = new BehaviorSubject<PaginateUser>(null);
  private adminAllUsers$ = new BehaviorSubject<PaginateUser>(null);
  private query: string = '';
  private productIds$ = new BehaviorSubject<ProductsModel[]>([
    {
      name: "monthly",
      requestCount: 1000,
      id: 34781
    },
    {
      name: "monthly",
      requestCount: 4000,
      id: 55102
    },
    {
      name: "monthly",
      requestCount: 4000,
      id: 55103
    },
    {
      name: "monthly",
      requestCount: 1000,
      id: 64156
    },
    {
      name: "monthly",
      requestCount: 2000,
      id: 64157
    },
    {
      name: "monthly",
      requestCount: 40,
      id: 67521
    },
    {
      name: "yearly",
      requestCount: 12000,
      id: 67983
    },
    {
      name: "yearly",
      requestCount: 1000,
      id: 67984
    },
    {
      name: "yearly",
      requestCount: 1000,
      id: 67985
    },
    {
      name: "yearly",
      requestCount: 2000,
      id: 67986
    },
    {
      name: "yearly",
      requestCount: 5000,
      id: 67987
    },
    {
      name: "monthly",
      requestCount: 1000,
      id: 74843
    },
  ]);

  private subscriptionTypes$ = new BehaviorSubject([
    {
      name: 'Monthly',
      slug: 'monthly'
    },
    {
      name: 'Yearly',
      slug: 'yearly'
    },
    {
      name: 'Trial',
      slug: 'trial'
    }
  ])

  constructor() { }

  isUpdating(): Observable<boolean> {
    return this.isUpdating$.asObservable();
  }

  setUdating(trigger: boolean) {
    this.isUpdating$.next(trigger);
  }

  setQuery(query: string) {
    this.query = query;
  }

  getQeury(): string {
    return this.query;
  }

  getadminUsers(): Observable<PaginateUser> {
    return this.adminUsers$.asObservable();
  }
  getAdminAllUsers(): Observable<any> {
    return this.adminAllUsers$.asObservable();
  }

  setAdminUsers(users: PaginateUser) {
    this.adminUsers$.next(users);
  }

  setAdminAllUsers(users: any) {
    this.adminAllUsers$.next(users);
  }

  getProductIds(): Observable<ProductsModel[]>{
    return this.productIds$.asObservable();
  }

  getSubscriptionTypes(): Observable<SubscriptionTypeModel[]>{
    return this.subscriptionTypes$.asObservable();
  }
}
