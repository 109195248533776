import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {RemovalComponent} from "./removal.component";

const routes: Routes = [
  {
    path: '',
    component: RemovalComponent
  },
  {
    path: 'accept/:id',
    component: RemovalComponent
  }
]

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})

export class RemovalRoutingModule {
}
