import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending',
  template: '<img src="assets/images/loader.gif" width="50" alt="location" style="padding-left: 20px" class="img-loc">',
})
export class PendingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
