import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { ProfileComponent } from './components/profile/profile.component';
import {SettingsState} from './state/settings.state';
import {SettingsFacade} from './settings.facade';
import {SettingsApi} from './api/settings.api';
import {WoodpeckerComponent} from './components/woodpecker/woodpecker.component';
import {LemlistComponent} from "./components/lemlist/lemlist.component";
import {MailshakeComponent} from "./components/mailshake/mailshake.component";
import {PursueComponent} from "./components/pursue/pursue.component";
import {HighLevelComponent} from "./components/high-level/high-level.component";
// import {ProspectComponent} from './components/prospect/prospect.component';



@NgModule({
  declarations: [
    IntegrationsComponent,
    ProfileComponent,
    WoodpeckerComponent,
    // ProspectComponent,
    LemlistComponent,
    PursueComponent,
    MailshakeComponent,
    HighLevelComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  providers: [SettingsState, SettingsFacade, SettingsApi]
})
export class SettingsModule { }
