import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Company} from '../model/company.model';
import {WoodpeckerModel} from '../../settings/model/woodpecker.model';
import {ValidatorFn, Validators} from '@angular/forms';
import {AutoresponderVariableModel} from "../model/autoresponder-variable.model";
import {AutoresponderLabelsModel} from "../model/autoresponder-labels.model";
import {CompanyService} from "../api/company.service";
import {map} from "rxjs/operators";
import {HighLevelVariablesModel} from "../model/high-level-variables.model";

@Injectable()
export class CompanyState {
  private readonly dbStringMaxLength = 191;
  private updating$ = new BehaviorSubject<boolean>(false);
  private companyHighLevelVariables = new BehaviorSubject({});
  private updatingFilter$ = new BehaviorSubject<boolean>(false);
  private flowCompanies$ = new BehaviorSubject<Company[]>([]);
  private flowCompaniesLocation$ = new BehaviorSubject<Company[]>([]);
  private flowCompany$ = new BehaviorSubject<Company>(new Company());
  private flowCompaniesFilter$ = new BehaviorSubject<Company[]>(null);
  private resultVariables$ = new BehaviorSubject<{name: string, value: string, type: string[]}[]>([
    {name: "First Name", value: "{{first_name}}", type: ["all"]},
    {name: "Last Name", value: "{{last_name}}", type: ["all"]},
    {name: "Full Name", value: "{{full_name}}", type: ["all"]},
    {name: "Company Name", value: "{{company_name}}", type: ["all"]},
    {name: "Email", value: "{{email}}", type: ["all"]},
    {name: "Rating", value: "{{rating}}", type: ["all"]},
    {name: "Company", value: "{{company}}", type: ["all"]},
    {name: "Phone Number", value: "{{phone_number}}", type: ["all"]},
    {name: "Country", value: "{{country}}", type: ["location"]},
    {name: "State", value: "{{state}}", type: ["location"]},
    {name: "City", value: "{{city}}", type: ["location"]},
    {name: "Address", value: "{{address}}", type: ["location"]},
    {name: "Website", value: "{{website}}", type: ["location"]},
    {name: "Position", value: "{{position}}", type: ["all"]},
    {name: "Seniority", value: "{{seniority}}", type: ["all"]},
    {name: "Department", value: "{{department}}", type: ["all"]},
    {name: "LinkedIn", value: "{{linkedin}}", type: ["all"]},
    {name: "Twitter", value: "{{twitter}}", type: ["all"]},
  ]);

  private lemlistLabels$ = new BehaviorSubject([
    {name: "email", label: "Email", defaultValue: "Email", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "firstName", label: "First Name", defaultValue: "First Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "lastName", label: "Last Name", defaultValue: "Last Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "companyName", label: "Company Name", defaultValue: "Company Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "picture", label: "Picture", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "phone", label: "Phone", defaultValue: "Phone Number", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "signature", label: "Phone", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet1", label: "Snippet1", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet2", label: "Snippet2", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet3", label: "Snippet3", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet4", label: "Snippet4", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet5", label: "Snippet5", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private lemlistVariables$ = new BehaviorSubject([
    {name: "email", label: "email", type: ["all"], defaultValue: "{{email}}", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "firstName", label: "First Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "lastName", label: "Last Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "companyName", label: "Company Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "picture", label: "Picture", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "phone", label: "Phone", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "signature", label: "Signature", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet1", label: "Snippet1", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet2", label: "Snippet2", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet3", label: "Snippet3", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet4", label: "Snippet4", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet5", label: "Snippet5", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private mailshakeLabels$ = new BehaviorSubject([
    {name: "email", label: "Email", defaultValue: "Email", validators: []},
    {name: "firstName", label: "First Name", defaultValue: "First Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "lastName", label: "Last Name", defaultValue: "Last Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "fullName", label: "Full Name", defaultValue: "Full Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet1", label: "Snippet1", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet2", label: "Snippet2", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet3", label: "Snippet3", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet4", label: "Snippet4", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet5", label: "Snippet5", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet6", label: "Snippet6", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet7", label: "Snippet7", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet8", label: "Snippet8", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet9", label: "Snippet9", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet10", label: "Snippet10", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private pursueLabels$ = new BehaviorSubject([
    {name: 'email', label: 'Email', defaultValue: 'Email', validators: []},
    {name: 'name', label: 'Name', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'address', label: 'Address', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data1', label: 'Data1', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data2', label: 'Data2', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data3', label: 'Data3', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data4', label: 'Data4', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data5', label: 'Data5', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data6', label: 'Data6', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data7', label: 'Data7', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data8', label: 'Data8', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data9', label: 'Data9', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data10', label: 'Data10', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private pursueVariables$ = new BehaviorSubject([
    {name: 'email', label: 'Email', type: ['all'], defaultValue: '{{email}}', validators: []},
    {name: 'name', label: 'Name', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'address', label: 'Address', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data1', label: 'Data1', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data2', label: 'Data2', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data3', label: 'Data3', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data4', label: 'Data4', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data5', label: 'Data5', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data6', label: 'Data6', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data7', label: 'Data7', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data8', label: 'Data8', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data9', label: 'Data9', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'data10', label: 'Data10', type: ['all'], defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private highLevelVariables$ = new BehaviorSubject([
    // {name: 'email', label: 'Email', type: ['all'], defaultValue: '{{email}}', canChooseFromVariables: true, validators: []},
    // {name: 'firstName', label: 'First Name', type: ['all'], defaultValue: '{{first_name}}', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'lastName', label: 'Last Name', type: ['all'], defaultValue: '{{last_name}}', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'name', label: 'Full Name', type: ['all'], defaultValue: '{{full_name}}', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'phone', label: 'Phone Number', type: ['all'], defaultValue: '{{phone_number}}', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'city', label: 'City', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'state', label: 'State', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'postalCode', label: 'Postal Code', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'website', label: 'Website', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'country', label: 'Country', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: []},
    // {name: 'company_name', label: 'Company Name', type: ['all'], defaultValue: '{{company_name}}', canChooseFromVariables: true, validators: []},
    // {name: 'linkedin', label: 'Linkedin Url', type: ['all'], defaultValue: '{{linkedin}}', canChooseFromVariables: true, validators: []},
    // {name: 'timezone', label: 'Timezone', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'dnd', label: 'DND', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'tags', label: 'Tags ( Separate by comma )', type: ['all'], defaultValue: '', canChooseFromVariables: false, validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'source', label: 'Source', type: ['all'], defaultValue: '', canChooseFromVariables: true, validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private highLevelLabels$ = new BehaviorSubject([
    // {name: 'email', label: 'Email', defaultValue: '{{email}}', validators: []},
    // {name: 'firstName', label: 'First Name', defaultValue: '{{first_name}}', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'lastName', label: 'Last Name', defaultValue: '{{last_name}}', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'name', label: 'Full Name', defaultValue: '{{full_name}}', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'phone', label: 'Phone Number', defaultValue: '{{phone_number}}', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'city', label: 'City', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'state', label: 'State', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'postalCode', label: 'Postal Code', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'website', label: 'Website', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'country', label: 'Country', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'company_name', label: 'Company Name', defaultValue: '{{company_name}}', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'linkedin', label: 'Linkedin Url', defaultValue: '{{linkedin}}', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'timezone', label: 'Timezone', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    // {name: 'dnd', label: 'DND', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'tags', label: 'Tags', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: 'source', label: 'Source', defaultValue: '', validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private mailshakeVariables$ = new BehaviorSubject([
    {name: "email", label: "Email", type: ["all"], defaultValue: "{{email}}", validators: []},
    {name: "firstName", label: "First Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "lastName", label: "Last Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "fullName", label: "Full Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet1", label: "Snippet1", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet2", label: "Snippet2", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet3", label: "Snippet3", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet4", label: "Snippet4", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet5", label: "Snippet5", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet6", label: "Snippet6", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet7", label: "Snippet7", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet8", label: "Snippet8", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet9", label: "Snippet9", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet10", label: "Snippet10", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ])

  private prospectLabels$ = new BehaviorSubject([
    {name: "email", label: "Email", defaultValue: "Email", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "first_name", label: "First Name", defaultValue: "First Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "last_name", label: "Last Name", defaultValue: "Last Name", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "organisation_name", label: "Organisation Name", defaultValue: "Company", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "description", label: "Description", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "jobtitle", label: "Job Title", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "linkedin_profile", label: "Linkedin", defaultValue: "LinkedIn", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "phone", label: "Phone Number", defaultValue: "Phone Number", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "country", label: "Country", defaultValue: "Country", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "state", label: "State", defaultValue: "State", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "city", label: "City", defaultValue: "City", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "industry", label: "Industry", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ]);

  private prospectVariables$ = new BehaviorSubject([
    {name: "email", label: "Email", defaultValue: "{{email}}", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "first_name", label: "First Name", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "last_name", label: "Last Name", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "organisation_name", label: "Organisation Name", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "description", label: "Description", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "domain", label: "Domain", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "jobtitle", label: "Job Title", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "linkedin_profile", label: "LinkedIn Profile", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "phone", label: "Phone", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "title", label: "Title", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "country", label: "Country", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "state", label: "State", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "city", label: "City", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "industry", label: "Industry", defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ]);
  private woodpeckerVariables$ = new BehaviorSubject<{name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[]}[]>([
    {name: "email", label: "Email", type: ["all"], defaultValue: "{{email}}", validators: []},
    {name: "first_name", label: "First Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "last_name", label: "Last Name", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "company", label: "Company", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "industry", label: "Industry", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "website", label: "Website", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "tags", label: "tags", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "title", label: "title", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "phone", label: "Phone Number", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "address", label: "Address", type: ["location"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "country", label: "Country", type: ["location"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "state", label: "State", type: ["location"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "city", label: "City", type: ["location"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet1", label: "Snippet1", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet2", label: "Snippet2", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet3", label: "Snippet3", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet4", label: "Snippet4", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet5", label: "Snippet5", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet6", label: "Snippet6", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet7", label: "Snippet7", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet8", label: "Snippet8", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet9", label: "Snippet9", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet10", label: "Snippet10", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet11", label: "Snippet11", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet12", label: "Snippet12", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet13", label: "Snippet13", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet14", label: "Snippet14", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
    {name: "snippet15", label: "Snippet15", type: ["all"], defaultValue: "", validators: [Validators.maxLength(this.dbStringMaxLength)]},
  ]);

  private companyAutoselectOption = [
    {name: "Manual", action: "manual"},
    {name: "Select First", action: "select_first"},
    {name: "Select First Three", action: "select_first_three"},
    {name: "Select All", action: "select_all"}
  ];
  constructor(
    private companyService: CompanyService,

  ) {}

  isUpdating$() {
    return this.updating$.asObservable();
  }

  isUpdatingFilter$() {
    return this.updatingFilter$.asObservable();
  }

  getResultVariables(): Observable<{name: string, value: string, type: string[]}[]>{
    return this.resultVariables$.asObservable();
  }

  getFlowProspectVariables(): Observable<{name: string, label: string, defaultValue: string, validators: ValidatorFn[]}[]>{
    return this.prospectVariables$.asObservable();
  }

  getFlowProspectLabels(): Observable<{name: string, label: string, defaultValue, validators: Validators[]}[]>{
    return this.prospectLabels$.asObservable();
  }

  getFlowHighLevelVariables(): Observable<AutoresponderVariableModel[]>{
    return this.highLevelVariables$.asObservable();
  }

  getFlowHighLevelLabels(): Observable<AutoresponderLabelsModel[]>{
    return this.highLevelLabels$.asObservable();
  }

  getFlowLemlistVariables(): Observable<{ name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[] }[]>{
    return this.lemlistVariables$.asObservable();
  }

  getFlowLemlistLabels(): Observable<{ name: string, label: string,defaultValue: string, validators: ValidatorFn[] }[]>{
    return this.lemlistLabels$.asObservable();
  }

  getFlowMailshakeLabels(): Observable<{ name: string, label: string,defaultValue: string, validators: ValidatorFn[] }[]>{
    return this.mailshakeLabels$.asObservable();
  }

  getFlowMailshakeVariables(): Observable<{name: string, label: string, type: string[], defaultValue: string}[]>{
    return this.mailshakeVariables$.asObservable();
  }

  getFlowPursueLabels(): Observable<{ name: string, label: string,defaultValue: string, validators: ValidatorFn[] }[]>{
    return this.pursueLabels$.asObservable();
  }

  getFlowPursueVariables(): Observable<{name: string, label: string, type: string[], defaultValue: string}[]>{
    return this.pursueVariables$.asObservable();
  }

  getFlowWoodpeckerVariables(): Observable<{name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[] | []}[]>{
    return this.woodpeckerVariables$.asObservable();
  }

  setUpdating(isUpdating: boolean) {
    this.updating$.next(isUpdating);
  }

  setUpdatingFilter(isUpdating: boolean) {
    this.updatingFilter$.next(isUpdating);
  }

  getAutoselectOptions(){
    return this.companyAutoselectOption;
  }

  getflowCompanies$() {
    return this.flowCompanies$.asObservable();
  }

  setflowCompanies(companies: Company[]) {
    this.flowCompanies$.next(companies);
  }

  getflowCompaniesFilter$() {
    return this.flowCompaniesFilter$.asObservable();
  }

  setFlowCompanyHighLevelVariables(data, companyId): void {
    const currentVariables = this.companyHighLevelVariables.getValue();
    currentVariables[companyId] = data;
    this.companyHighLevelVariables.next(currentVariables);
  }

  getFlowCompanyHighLevelVariables(companyId: number): Observable<any>{
    const companyHighLevelVariables = this.companyHighLevelVariables.getValue();
    if(companyHighLevelVariables[companyId]){
      return of(companyHighLevelVariables[companyId]);
    }
    return this.companyService.getCompanyHighLevelVariables(companyId).pipe(map((item: any) => {
      const variables = new HighLevelVariablesModel().deserialize(item.content);
      this.setFlowCompanyHighLevelVariables(variables, companyId);
      return variables;
    }));
  }

  setflowCompaniesFilter(companies: Company[]) {
    this.flowCompaniesFilter$.next(companies);
  }

  addflowCompanies(company: Company) {
    const currentValue = this.flowCompanies$.getValue();
    if (currentValue === null) {
      this.flowCompanies$.next([company]);
    } else {
      this.flowCompanies$.next([company, ...currentValue]);
    }
  }

  decrementCurrentCompanyResults(count){
    const myCompanies = this.flowCompanies$.getValue();
    const currentCompany = this.flowCompany$.getValue();
    for(let company of myCompanies){
      if(company.id === currentCompany.id){
        company.results_count = company.results_count - count;
        company.emails_count = company.emails_count - count;
      }
    }
    this.flowCompanies$.next(myCompanies);
  }

  getflowCompany$() {
    return this.flowCompany$.asObservable();
  }

  setflowCompany(company: Company) {
    this.flowCompany$.next(company);
  }

  loadflowCompanyLocal$(companyId) {
      const companies = this.flowCompanies$.getValue();
      const getCompany = companies.findIndex( company => company.id === parseInt(companyId, 10));
      if (getCompany !== -1) {
        this.flowCompany$.next(companies[getCompany]);
        return true;
      } else {
        return false;
      }
  }

  updateflowCompany(updatedCompany: Company) {
    const companies = this.flowCompanies$.getValue();
    const indexOfUpdated = companies.findIndex(company => company.id === updatedCompany.id);
    if(indexOfUpdated != -1) {
      companies[indexOfUpdated] = Object.assign(companies[indexOfUpdated], updatedCompany);
      this.flowCompanies$.next([...companies]);
    }
  }

  updateflowCompanyId(companyToReplace: Company, addedCompanyWithId: Company) {
    const ccompanies = this.flowCompanies$.getValue();
    const updatedCategoryIndex = ccompanies.findIndex(category => category === companyToReplace);
    ccompanies[updatedCategoryIndex] = addedCompanyWithId;
    this.flowCompanies$.next([...ccompanies]);
  }

  deleteCampaign(campaignId: number) {
    const campaign = this.flowCompanies$.getValue();
    const indexOfUpdated = campaign.findIndex(result => result.id === campaignId);
    campaign.splice(indexOfUpdated, 1);
    this.flowCompanies$.next([...campaign]);
  }
}
