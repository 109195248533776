import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {faCheck, faArrowDown, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {SearchFacade} from '../../search.facade';
import {Observable} from 'rxjs';
import {SearchResultModel} from '../../model/searchResult.model';
import {CompanyFacade} from '../../../company/company.facade';
import {Company} from '../../../company/model/company.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchResultPeopleModel} from '../../model/SearchResultPeople.model';
import {SearchState} from '../../state/search.state';
import {ToastrService} from 'ngx-toastr';
import {SearchResultSingleModel} from '../../model/search-result-single.model';
import {LicenseState} from "../../../license/license.state";
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {
  faCheck = faCheck;
  disabled = false;
  enabled = 0;
  faArrowDown = faArrowDown;
  faExclamationTriangle = faExclamationTriangle;
  outputData: {place: string, place_name: string} = null;
  loadCompleate: Observable<boolean>;
  selectCampaign: true;
  resSwitcher$: Observable<string>;
  isSingleHaveMore: Observable<boolean>;
  public isPeopleHaveMore: Observable<boolean>;
  public companies: Observable<Company[]>;
  public tableResult: any;
  public localResult: Observable<SearchResultModel[]>;
  public peopleResult: Observable<SearchResultPeopleModel[]>;
  public singleResult: Observable<SearchResultSingleModel>;
  public singleItem: Observable<SearchResultSingleModel>;
  public saveForm: FormGroup;
  public submitted = false;
  public loadMore = false;
  public added = false;
  public update$: Observable<boolean>;
  public BCompanyId: number;
  public BCompanyType: String;
  public uns: any;
  public uns_people: any;
  public haveMoreResult: boolean = true;
  public canMakeRequests$: Observable<boolean>;
  public rateLimits : any;
  public selectedCompanyName: string;
  showMapCoord = false;
  lat: number;
  lng: number;
  public pager$: Observable<any>;

  constructor(private searchFacade: SearchFacade, private companiesFacade: CompanyFacade, private formBuilder: FormBuilder,
              private form: SearchState, private toastr: ToastrService, private licenseState: LicenseState) { }

  ngOnInit(): void {
    this.BCompanyId = this.BCompanyId ? this.BCompanyId : this.form.getCompanyId();
    const body = document.getElementsByTagName('body')[0];
    this.companies = this.companiesFacade.getflowCompanies$();
    this.loadCompleate = this.searchFacade.isLoadCompleate$();
    this.isPeopleHaveMore = this.searchFacade.isPeopleHaveMore();
    this.isSingleHaveMore = this.searchFacade.isSingleHasMore();
    this.localResult = this.searchFacade.getflowLocalResults$();
    this.peopleResult = this.searchFacade.getflowPeopleResults$();
    this.singleResult = this.searchFacade.getFlowSingleResultEmails();
    this.searchFacade.getFlowSingleResult().subscribe((res) => {
      this.singleItem = res;
    });
    this.selectCampaign = true;
    this.rateLimits = this.searchFacade.getRateLimits();
    this.canMakeRequests$ = this.licenseState.getCanMakeRequest();
    this.resSwitcher$ = this.searchFacade.showSwitcher$();
    this.update$ = this.searchFacade.isUpdatingRes$();
    this.loadCompleate.subscribe(res => {
      if (!res) {
        body.classList.add('search-page');
      } else {
        this.resSwitcher$.subscribe( resSwitcher => {
          this.companies.subscribe( res => {
            if (res.length > 0) {
              setTimeout(() => {
                res.forEach( company => {
                  if (+company.id === +this.BCompanyId) {
                    this.selectedCompanyName = company.name;
                    this.saveForm.patchValue({company_id: company.id});
                  }
                });
              }, 0);
            }
          });
          if (resSwitcher === 'location') {
            body.classList.add('location-results-page');
          } else {
            body.classList.add('people-results-page');
          }
        });
      }
    });

    this.saveForm = this.formBuilder.group({
      company_id: ['', [Validators.required]],
      company_data: ['', [Validators.required]]
    });
    this.update$.subscribe(() => {
      this.saveForm.reset();
    });
    this.uns = this.localResult.subscribe( res => {
      this.showMapCoord = true;
      if (res !== null) {
        this.showMapCoord = true;
        this.lat = res[0].main_location.lat;
        this.lng = res[0].main_location.lng;
      }
    });
    this.uns_people = this.peopleResult.subscribe( res => {
      this.showMapCoord = false;
      if (res !== null) {
        this.showMapCoord = true;
        this.lat = res[0].main_location.lat;
        this.lng = res[0].main_location.lng;
      }
    });
    this.pager$ = this.searchFacade.getPagerLocation();
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    this.uns.unsubscribe();
    this.uns_people.unsubscribe();
    this.loadCompleate.subscribe(res => {
      if (!res) {
        body.classList.remove('search-page');
      } else {
        this.resSwitcher$.subscribe( resSwitcher => {
          if (resSwitcher === 'location') {
            body.classList.remove('location-results-page');
          } else {
            body.classList.remove('people-results-page');
          }
        });
      }
    });
  }

  backToSearch() {
    this.form.resetSearchForms();
    this.saveForm.get("company_id").setValue(null);
    this.saveForm.reset();
    this.searchFacade.loadCompleate();
    this.form.setPeopleCurrentPage(0);
    this.form.setSingleCurrentPage(0);
    this.searchFacade.setSelectedLocation(null);
  }

  getBCompanyId(id) {
    this.saveForm.patchValue({company_id: id}, {
      onlySelf: true
    });
    this.BCompanyId = id;
  }
  getBCompanyType(type) {
    this.BCompanyType = type;
  }
  getEnabled(enabled) {
    this.enabled = enabled;
  }

  peopleLoaded(type) {
    this.saveForm.patchValue({company_id: this.BCompanyId});
    // this.save_results(type);
  }
  industryLoaded(type) {
    this.saveForm.patchValue({company_id: this.BCompanyId});
    // this.save_results(type);
  }
  singleLoaded(type) {
    this.saveForm.patchValue({company_id: this.BCompanyId});
    // this.save_results(type);
  }

  onOutputData($event) {
    this.saveForm.patchValue({company_data: $event}, {
      onlySelf: true
    });
  }



  changeCompany($event) {
    this.saveForm.patchValue({company_id: $event.target.value}, {
      onlySelf: true
    });
    this.BCompanyId = $event.target.value;
    this.BCompanyType = $event.target.selectedOptions[0].dataset.type;
  }

  get f() { return this.saveForm.controls; }

  save_results(type: string) {
    this.submitted = true;
    if (this.saveForm.invalid) {
      return;
    }
    let data;
    if (type === "single") {
      data = Object.assign({}, this.singleItem);
      data.emails = this.saveForm.value.company_data;
    } else {
      data = this.saveForm.value.company_data;
    }
    this.searchFacade.saveSearchResult(this.saveForm.value.company_id, data, type, this.searchFacade.getIsEU())
      .subscribe((res: { data: Company, message: string }) => {
        this.added = true;
        if(res.message){
          this.toastr.success("Complete!", res.message);
        } else {
          this.toastr.success('Complete!', 'Success');
        }
        this.companiesFacade.updateflowCompanyLocal(res.data);
      }, (res) => {
        const responseArray = Object.values(res);
        if (responseArray instanceof Array) {
          this.toastr.warning("Error", this.extractArrays(responseArray).join("\n"));
        }
      });
  }

  extractArrays(array: any): string[]{
    const data = [];
    for(let i = 0; i < array.length; i++){
      if(array[i] instanceof Array){
        data.push(...this.extractArrays(array[i]));
      } else {
        data.push(array[i]);
      }
    }
    return data;
  }

  loadMoreLocation(token) {
    this.loadMore = true;
    const form = this.form.getLocationForm();
    const newData = {
      companyId: this.BCompanyId,
      location: form.location,
      place_name: form.place_name,
      place_id: form.place_id,
      params: {
        pagetoken: token
      }
    };
    this.searchFacade.loadAddflowSearchResult(newData).subscribe( (successData) => {  this.loadMore = false; });
  }

  loadMorePeople(){
    const form = this.form.getPeopleForm();
    let currentPage = this.form.getPeopleCurrentPage();
    if(currentPage >= 8){
      this.form.setPeopleHasMore(false);
    }
    this.form.setPeopleCurrentPage(++currentPage);
    const newData = {
      location: form.location,
      role: form.role,
      company: form.company,
      companyId: this.BCompanyId,
      place_id: form.place_id,
      page: currentPage,
    };
    this.searchFacade.loadAddflowPeopleSearchResult(newData).subscribe();
  }

  loadMoreSingle(): void{
    const form = this.form.getSingleForm();
    let currentPage = this.form.getSingleCurrentPage();
    this.form.setSingleHasMore(false);
    this.form.setSingleCurrentPage(++currentPage);
    const newData = {
      companyId: this.BCompanyId,
      query: form.query,
      page: currentPage,
    };
    this.searchFacade.loadAddflowSingleSearchResult(newData).subscribe();
  }
}
