import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {JwtService} from '../../../services/jwt/jwt.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsFacade} from '../../../settings/settings.facade';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ForgotModalComponent} from '../../modal/forgot-modal/forgot-modal.component';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent implements OnInit, AfterViewInit {

  private id = null;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, public router: Router, private settings: SettingsFacade,
              private modalService: NgbModal) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const modalRef = this.modalService.open(ForgotModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.type = false;
    modalRef.componentInstance.code = this.route.snapshot.paramMap.get('id');
  }

}
