import { Injectable } from '@angular/core';
import {SettingsFacade} from '../settings/settings.facade';
import {ApiRelatedUserService} from './api/api.service';
import {RelatedUserState} from './state/related-user.state';
import {Observable} from 'rxjs';
import {PaginateUser} from './model/paginate-user';

@Injectable({
  providedIn: 'root'
})
export class RelatedUserFacade {

  constructor(private settings: SettingsFacade, private admin: ApiRelatedUserService, private  state: RelatedUserState) { }

  getStateUsers(): Observable<PaginateUser> {
    return this.state.getRelatedUsers();
  }

  isupdataing(): Observable<boolean> {
    return this.state.isUpdating();
  }

  setQuery(q: string) {
    this.state.setQuery(q);
  }

  getUsers(page = 1, count = 10) {
    this.state.setUdating(true);
    this.admin.getUsers(page, count, this.state.getQeury()).subscribe( res => {
      this.state.setRelatedUsers(res);
      this.state.setUdating(false);
    });
  }

  updateUser(value) {
    return this.admin.updateUser(value).subscribe( res => {
      this.getUsers();
    });
  }
}
