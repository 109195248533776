import {Component, OnDestroy, OnInit, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ResultsFacade} from '../../results.facade';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {SearchResultModel} from '../../../search/model/searchResult.model';
import {CompanyFacade} from '../../../company/company.facade';
import {Company} from '../../../company/model/company.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faCheck, faTimes, faSync, faChevronCircleRight, faDownload, faSearch} from '@fortawesome/free-solid-svg-icons';
import {SettingsFacade} from '../../../settings/settings.facade';
import {UserModel} from '../../../model/user.model';
import {SearchState} from '../../../search/state/search.state';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../../../main/tables/modal/modal.component';
import {ExportToCsv} from 'export-to-csv';
import {map} from "rxjs/operators";
import {SearchFacade} from "../../../search/search.facade";
import {ToastrService} from "ngx-toastr";
import {SearchResultSingleModel} from "../../../search/model/search-result-single.model";
import {CompanyState} from '../../../company/state/company.state';
import {ResultState} from "../../state/result.state";
import { ResultFilterModel } from '../../model/filter.model';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit, OnDestroy {

  faCheck = faCheck;
  faTimes = faTimes;
  faSearch = faSearch;
  faDownload = faDownload;
  faChevronCircleRight = faChevronCircleRight;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  public loading$: Observable<boolean>;
  public result$: Observable<SearchResultModel[]>;
  public resultPeople$: Observable<SearchResultModel[]>;
  public resultSingle: Observable<any>;
  public company$: Observable<Company>;
  public changeName = false;
  public updateForm: FormGroup;
  public user$: Observable<UserModel>;
  public integration$: Observable<any>;
  private campaignId: number = -1;
  private companySubscription: Subscription;
  private defaultSelectedConnection = "woodpecker";
  private resultSubscription: Subscription;
  private timeOutElement: number;
  private isSearching: boolean;
  public submitted = false;
  public added = false;
  public singleItem: Observable<SearchResultSingleModel>;
  public defFilter = 'all';
  public unsentFilter = '0';
  public emailFilter = 'all';
  public saveForm: FormGroup;
  public selectAllResults = false;
  public reSend = false;
  public withoutResults = [];
  currentPage = 1;
  public changePage = 1;
  private subscriptions: Subscription[] = [];
  company: Company;
  searchValue = this.resultFacade.getSearchValue();
  oldSearchValue = '';
  showPaginationLinks = false;
  selectedIntegration = "woodpecker";
  public totalResult = 0;
  public withEmailResult = 0;
  public ghlSelectedCnt = 0;
  public withoutEmailResult = 0;
  public isGHLConnect = false;
  pageValues = [25, 50, 100];
  itemsPerPage = this.resultFacade.getItemsPerPage();
  totalItems: number;
  companyId: number;
  companyForm: FormGroup;
  isLoading$: Observable<boolean>;
  autoSelectOption: null;
  public resultEmailsCount: Number;

  public searchFilter: ResultFilterModel;

  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private resultFacade: ResultsFacade,
              private companyFacade: CompanyFacade,
              private settings: SettingsFacade,
              private state: SearchState,
              private modalService: NgbModal,
              private searchFacade: SearchFacade,
              private toastr: ToastrService,
              private companiesFacade: CompanyFacade,
              private companyState: CompanyState,
              private resultState: ResultState,
              private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.isGHLConnect = this.settings.getUserModel().high_level_api_key ? true : false;
    this.resultEmailsCount = 1;
    this.route.paramMap.subscribe(params => {
      if(+params.get('id') !== this.companyId)
        this.searchValue = '';
      this.defFilter = 'all';
      if (this.resultSubscription) {
        this.resultSubscription.unsubscribe();
      }
      this.currentPage = 1;
      this.showPaginationLinks = false;

      this.searchFilter = {
        filter: this.defFilter,
        unsent: this.unsentFilter,
        emailFilter: this.emailFilter,
        export: false,
        selectAllResults: this.selectAllResults
      };
      this.resultSubscription = this.resultFacade.newLoadResults(+params.get('id'), this.searchValue, 0, this.itemsPerPage, this.searchFilter ).subscribe(() => {
        this.showPaginationLinks = true;
      });
      this.companyFacade.loadflowCompany(params.get('id'));
      this.companyId = +params.get("id");
      this.state.setCompanyId(parseInt(params.get('id'), 10));
    });
    this.resultState.getAllItems().subscribe((val) => {
      this.totalResult = val;
    });
    this.resultState.getWithEmailsItems().subscribe((val) => {
      this.withEmailResult = val;
    });
    this.resultState.getWithOutEmailsItems().subscribe((val) => {
      this.withoutEmailResult = val;
    });
    this.subscriptions.push(
      this.resultFacade.getTotalItems().subscribe((items: number) => {
        this.totalItems = items;
      })
    )
    this.company$ = this.companyFacade.getflowCompany$();
    this.loading$ = this.resultFacade.isLoading$();
    this.result$ = this.resultFacade.getResult$();
    this.resultPeople$ = this.resultFacade.getResultPeople$();
    this.resultSingle = this.resultFacade.getResultSingle();
    this.user$ = this.settings.getUser$();
    this.integration$ = this.resultFacade.getwoodpeckerCampaigns$();
    this.companySubscription = this.company$.subscribe(res => {
      if (typeof res !== 'undefined') {
        this.company = res;
        this.campaignId = res.id;
        this.updateForm = this.formBuilder.group({
          id: [res.id, [Validators.required]],
          name: [res.name, [Validators.required]],
          status: [res.status, [Validators.required]],
          result_count: [res.results_count, [Validators.required]],
          emails_count: [res.emails_count],
          type: [res.type, [Validators.required]],
          updated_at: [res.updated_at, [Validators.required]],
          user_id: [res.user_id, [Validators.required]],
          woodpecker: [ResultsComponent.getIntegrationId(res), [Validators.required]],
          lemlist: [res.lemlist],
          mailshake: [res.mailshake],
          pursue: [res.pursue],
          lemlist_variables: [res.lemlist_variables],
          woodpecker_variables: [res.woodpecker_variables],
          pursue_variables: [res.pursue_variables],
          autoselect_option: [res.autoselect_option],
        });
        if (res.woodpecker) {
          this.selectedIntegration = "woodpecker";
        } else if (res.lemlist) {
          this.selectedIntegration = "lemlist";
        } else if (res.high_level) {
          this.selectedIntegration = "highLevel";
        } else if (res.mailshake) {
          this.selectedIntegration = "mailshake";
        } else if (res.pursue) {
          this.selectedIntegration = 'pursue';
        } else {
          this.selectedIntegration = this.defaultSelectedConnection;
        }
        if (Boolean(res)) {
          this.getIntegration();
        }
      }
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('location-results-page');
    body.classList.add('campaign-details-page');
    this.searchFacade.getFlowSingleResult().subscribe((res) => {
      this.singleItem = res;
    });

    this.saveForm = this.formBuilder.group({
      company_data: ['', [Validators.required]]
    });

    this.companyForm = this.formBuilder.group({
      autoselect_option: [1, [Validators.required]],
      autoselect_custom: [1],
      only_verified: [this.company.only_verified]
    });
    this.isLoading$ = this.companyState.isUpdating$();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  changeOption($event) {
    this.autoSelectOption = $event.target.value;
    if(this.autoSelectOption !== 'custom')
      this.resultEmailsCount = this.autoSelectOption;
    else
      this.resultEmailsCount = this.companyForm.get("autoselect_custom").value;
  }
  changeCustomCount($event) {
    if(this.autoSelectOption === 'custom') {
      this.resultEmailsCount = $event.target.value;
    }
  }
  static getIntegrationId(res): string | null {
    if (res.lemlist) {
      return res.lemlist;
    } else if (res.mailshake) {
      return res.mailshake;
    } else if (res.pursue) {
      return res.pursue;
    } else {
      return res.woodpecker;
    }
  }

  search(needTimeOut = false, page: number = 0) {
    if (needTimeOut) {
      clearTimeout(this.timeOutElement);
      this.timeOutElement = setTimeout(this.searchRequest.bind(this), 500, page);
    } else {
      this.searchRequest(page);
    }
  }
  filterResults($event) {
    this.defFilter = $event.target.value;
    this.searchRequest(0);
  }
  emailFilterResults(val) {
    if(this.emailFilter !== val) {
      this.emailFilter = val;
      this.searchRequest(0);
    }
  }
  filterCRMResults($event) {
    this.unsentFilter = $event.target.value;
    this.searchRequest(0);
  }

  searchRequest(page: number = 0) {
    this.resultFacade.setItemsPerPage(this.itemsPerPage);
    this.currentPage = 1;
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
    const nowSearching = !!this.searchValue;
    if (nowSearching !== this.isSearching) {
      this.isSearching = nowSearching;
      this.resultFacade.setNextPage(1);
    }
    const offset = page * this.itemsPerPage;
    this.searchFilter = {
      filter: this.defFilter,
      unsent: this.unsentFilter,
      emailFilter: this.emailFilter,
      export: false,
      selectAllResults: this.selectAllResults
    };
    this.resultSubscription = this.resultFacade.newLoadResults(this.route.snapshot.params.id, this.searchValue, offset, this.itemsPerPage, this.searchFilter).subscribe(res => {
      if(this.unsentFilter === '1')
        this.reSend = true;
      else
        this.reSend = false;
    });

  }

  setSearchValue() {
    this.resultFacade.setSearchValue(this.searchValue);
    this.oldSearchValue = this.searchValue;
  }

  get f() {
    return this.updateForm.controls;
  }

  get mailshake() {
    return this.company.mailshake;
  }

  get lemlist() {
    return this.company.lemlist;
  }

  get woodpecker() {
    return this.company.woodpecker;
  }

  get pursue() {
    return this.company.pursue;
  }

  get highLevel() {
    return this.company.high_level;
  }

  updateMod() {
    this.changeName = !this.changeName;
    this.company$.subscribe(res => {
      this.updateForm.patchValue({
        name: res.name,
        status: res.status,
        id: res.id,
      }, {
        onlySelf: true
      });
    });
  }

  onPagination(page: number) {
    this.resultFacade.setCurrentPage(page);
    this.currentPage = page;
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
    this.searchFilter = {
      filter: this.defFilter,
      unsent: this.unsentFilter,
      emailFilter: this.emailFilter,
      export: false,
      selectAllResults: this.selectAllResults
    };
    this.resultSubscription = this.resultFacade.newLoadResults(+this.route.snapshot.params.id, this.searchValue, (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage, this.searchFilter).subscribe((res) => {
      this.changePage = page;
    });
  }

  getIntegration() {
    this.integration$ = this.companyFacade.getIntegrationCampaigns(this.selectedIntegration).pipe(
      map((res) => {
        let results;
        if (res.results) {
          results = res.results;
        } else {
          results = res.map((elem) => {
            if (!elem.id) {
              elem.id = elem._id;
            }
            return elem;
          });
        }
        if (this.updateForm && this.updateForm.controls[this.selectedIntegration] && this.updateForm.controls[this.selectedIntegration].value) {
          if (!results.find((integration) => integration.id == this.updateForm.controls[this.selectedIntegration].value)) {
            this.updateForm.controls[this.selectedIntegration].setValue("");
          }
        }
        return results;
      })
    );
  }

  getComponentFromAddress(neededComponent: string, component: any): string {
    return this.resultFacade.getComponentFromAddress(neededComponent, component);
  }

  update() {
    const formValues = this.createUpdateValues(this.updateForm.value);
    formValues.results_count = formValues.result_count;
    const company = new Company().deserialize(formValues);
    this.companyFacade.updateflowCompany(company);
    (this.changeName) ? this.changeName = false : this.changeName = false;
  }

  createUpdateValues(data: any) {
    switch (this.selectedIntegration) {
      case "woodpecker":
        data.mailshake = null;
        data.lemlist = null;
        data.pursue = null;
        data.high_level = null;
        break;
      case "lemlist":
        data.lemlist = data.woodpecker;
        data.woodpecker = null;
        data.mailshake = null;
        data.pursue = null;
        data.high_level = null;
        break;
      case "mailshake":
        data.mailshake = data.woodpecker;
        data.woodpecker = null;
        data.lemlist = null;
        data.pursue = null;
        data.high_level = null;
        break;
      case 'pursue':
        data.pursue = data.woodpecker;
        data.woodpecker = null;
        data.lemlist = null;
        data.mailshake = null;
        data.high_level = null;
        break;
      case 'highLevel':
        data.high_level = 'selected';
        data.woodpecker = null;
        data.pursue = null;
        data.mailshake = null;
        data.lemlist = null;
    }
    return data;
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
    this.destroy.next(null);
    this.destroy.complete();
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('location-results-page');
    body.classList.remove('campaign-details-page');
    this.companySubscription.unsubscribe();
    this.resultSubscription.unsubscribe();
  }

  deleteCampaign() {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Delete campaign';
    modalRef.componentInstance.message = 'Are you sure you want to delete the campaign?';
    modalRef.componentInstance.type = 'company';
    modalRef.componentInstance.id = this.campaignId;
  }

  exportToCSV(companyType) {
    const options = {
      useBom: true,
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useKeysAsHeaders: true,
    };
    switch (companyType) {
      case 'location':
        this.searchFilter = {
          filter: this.defFilter,
          unsent: this.unsentFilter,
          emailFilter: this.emailFilter,
          resultIds: this.saveForm.value.company_data.results,
          withoutResultIds: this.withoutResults,
          emailIds: this.saveForm.value.company_data.emails,
          emailsCount: this.resultEmailsCount,
          export: true,
          selectAllResults: this.selectAllResults
        };
        break;
      case 'people':
        this.searchFilter = {
          filter: this.defFilter,
          unsent: this.unsentFilter,
          emailFilter: this.emailFilter,
          resultIds: this.saveForm.value.company_data,
          withoutResultIds: this.withoutResults,
          emailsCount: this.resultEmailsCount,
          export: true,
          selectAllResults: this.selectAllResults
        };
        break;
      case 'single':
        this.searchFilter = {
          filter: this.defFilter,
          unsent: this.unsentFilter,
          emailFilter: this.emailFilter,
          emailIds: this.saveForm.value.company_data,
          withoutResultIds: this.withoutResults,
          emailsCount: this.resultEmailsCount,
          export: true,
          selectAllResults: this.selectAllResults
        };
        break;
    }

    switch (companyType) {
      case 'location':
        this.resultFacade.newLoadResults(this.route.snapshot.params.id, this.searchValue, 0, this.itemsPerPage,  this.searchFilter).subscribe(res => {
          if (res.length > 0) {
            const data = [];
            res.forEach(value => {
              const result = {
                name: value.name,
                rating: value.rating,
                phone_number: value.phone_number,
                location: value.location.formatted_address,
                country: this.getComponentFromAddress("country", value.location.components),
                state: this.getComponentFromAddress("state", value.location.components),
                city: this.getComponentFromAddress("city", value.location.components),
                street: this.getComponentFromAddress("street", value.location.components),
                postal_code: this.getComponentFromAddress("postal_code", value.location.components),
                website: value.website,
                email: '',
                phone: ''
              };
              if (value.contact_person.hasOwnProperty('phones')) {
                if (value.contact_person.emails.length > 0) {
                  let phones = '';
                  value.contact_person.phones.forEach(phone => {
                    phones += phone.value + ', ';
                  });
                  result.phone_number = value.contact_person.phones[0].value;
                  phones.substring(0, phones.length - 2);
                  result.phone = phones;
                }
              }
              if (value.contact_person.hasOwnProperty('emails')) {
                if (value.contact_person.emails.length > 0) {
                  value.contact_person.emails.map(item => {
                    const res = {
                      name: value.name,
                      rating: value.rating,
                      phone_number: value.phone_number,
                      location: value.location.formatted_address,
                      country: this.getComponentFromAddress("country", value.location.components),
                      state: this.getComponentFromAddress("state", value.location.components),
                      city: this.getComponentFromAddress("city", value.location.components),
                      street: this.getComponentFromAddress("street", value.location.components),
                      postal_code: this.getComponentFromAddress("postal_code", value.location.components),
                      website: value.website,
                      email: '',
                      phone: ''
                    };
                    const email = item.detail && item.detail.first_name && item.detail.last_name
                      ? `${item.detail.first_name} ${item.detail.last_name} ${item.email}`
                      : `${item.email}`;
                    res.email = email;
                    data.push(res);
                  });
                } else {
                  data.push(result);
                }
              } else {
                data.push(result);
              }
            });
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(data);
          }
        });
        break;
      case 'people':
        this.resultFacade.newLoadResults(this.route.snapshot.params.id, this.searchValue, 0, this.itemsPerPage,  this.searchFilter).subscribe(res => {
          if (res.length > 0) {
            const data = [];
            res.forEach(value => {
              const results = {
                name: value.name,
                company: value.company_name,
                role: value.role,
                email: value.email,
                website: value.website,
                linkedin: value.linkedin_url,
                phone_number: "",
                position: "",
                twitter: "",
              };
              if (value.contact_person.emails.length && value.contact_person.emails[0].detail) {
                const details = value.contact_person.emails[0].detail;
                results.phone_number = details.phone_number ? details.phone_number : "";
                results.twitter = details.twitter ? `https://twitter.com/${details.twitter}` : "";
                results.position = details.position ? details.position : "";
              }
              data.push(results);
            });
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(data);
          }
        });
        break;
      case "single":
        this.resultFacade.newLoadResults(this.route.snapshot.params.id, this.searchValue, 0, this.itemsPerPage,  this.searchFilter).subscribe((result) => {
          if (result.length) {
            const exportData = [];
            for (let res of result) {
              const details = res.detail;
              const data = {
                name: details.first_name ? `${details.first_name} ${details.last_name}` : "",
                email: res.email ? res.email : "",
                phone_number: details.phone_number ? details.phone_number : "",
                department: details.department ? details.department : "",
                position: details.position ? details.position : "",
                seniority: details.seniority ? details.seniority : "",
                type: details.type ? details.type : "",
                linkedin: details.linkedin ? details.linkedin : "",
                twitter: details.twitter ? `https://twitter.com/${details.twitter}` : "",
              };
              exportData.push(data);
            }
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(exportData);
          }
        });
    }

  }
  selectAll($event) {
    this.selectAllResults = $event;
  }
  outputDataGHL($event) {
    if($event.results)
      this.ghlSelectedCnt = $event.results.length;
    else
      this.ghlSelectedCnt = $event.length;
    this.saveForm.patchValue({company_data: $event}, {
      onlySelf: true
    });
  }
  withoutResultsGhl($event) {
    this.withoutResults = $event;
  }

  extractArrays(array: any): string[]{
    const data = [];
    for(let i = 0; i < array.length; i++){
      if(array[i] instanceof Array){
        data.push(...this.extractArrays(array[i]));
      } else {
        data.push(array[i]);
      }
    }
    return data;
  }

  saveIsGHL() {
    if(!this.ghlSelectedCnt || !this.isGHLConnect)
      return;
    this.submitted = true;
    if (this.saveForm.invalid) {
      return;
    }
    let data;
    if (this.company.type === "single") {
      data = Object.assign({}, this.singleItem);
      data.emails = this.saveForm.value.company_data;
    } else {
      data = this.saveForm.value.company_data;
    }
    const options = (this.autoSelectOption === 'custom') ? this.companyForm.get("autoselect_custom").value : (this.companyForm.get("autoselect_option").value ? this.companyForm.get("autoselect_option").value : 0);
    this.searchFilter = {
      filter: this.defFilter,
      unsent: this.unsentFilter,
      emailFilter: this.emailFilter,
      export: false,
      withoutResultIds: this.withoutResults,
      selectAllResults: this.selectAllResults
    };
    this.searchFacade.saveIsGHL(data, this.company.type, options, this.selectAllResults, this.searchFilter, this.reSend)
      .subscribe((res: { data: Company, message: string }) => {
        if (this.resultSubscription) {
          this.resultSubscription.unsubscribe();
        }
        this.resultSubscription = this.resultFacade.newLoadResults(this.route.snapshot.params.id, this.searchValue, 0, this.itemsPerPage,  this.searchFilter).subscribe();
        this.added = true;
        if (res.message) {
          this.toastr.success("Complete!", res.message);
        } else {
          this.toastr.success('Complete!', 'Success');
        }
        this.saveForm.reset();
        this.selectAllResults = false;
        this.companyForm = this.formBuilder.group({
          autoselect_option: [options, [Validators.required]],
          autoselect_custom: [0],
          only_verified: [this.company.only_verified]
        });
        this.autoSelectOption = null;
        this.submitted = false;
        this.searchFacade.runCrmJob();
        this.ghlSelectedCnt = 0;
      }, (res) => {
        const responseArray = Object.values(res);
        this.submitted = false;
        if (responseArray instanceof Array) {
          this.toastr.warning("Error", this.extractArrays(responseArray).join("\n"));
        }
      });
  }
}
