import {Component, OnInit} from "@angular/core";

@Component({
  templateUrl: "./blocked.component.html",
  styleUrls: ["./blocked.component.css"]
})

export class BlockedComponent implements OnInit{
  ngOnInit() {

  }
}
