import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { EmailDetailsState } from './state/EmailDetails.state';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ResultsFacade } from '../../../../results/results.facade';
import {faArrowDown, faCheck, faEllipsisV, faPhone, faStar, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {map} from 'rxjs/operators';
import {EmailsDetailModel} from './emailsDetail.model';
import {environment} from '../../../../../environments/environment';
@Component({
  selector: 'app-emails-modal',
  templateUrl: './emails-modal.component.html',
  styleUrls: ['./emails-modal.component.css']
})
export class EmailsModalComponent implements OnInit {
  @Input() tableData: Observable<any>;
  @Input() emailDetails: Observable<any>;
  @Input() checks = true;
  @Output() outputData = new EventEmitter();
  public dCheck = faCheck;
  public dCheckCircle = faCheckCircle;
  public checkBxes = [];
  public select = false;
  public checkedList: any[];
  public noResult = false;
  private subscriber;
  public open_table: number;

  constructor(
    private resultFacade: ResultsFacade,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private httpClient: HttpClient,
    private emailDetailsState: EmailDetailsState
  ) { }

  ngOnInit() {
    this.checkBxes = [];
    this.emailDetails = this.emailDetailsState.getEemailDetails$();
    this.subscriber = this.emailDetails.subscribe( res => {
      this.noResult = false;
      if (res !== null && res.length !== 0) {
        this.open_table = 0;
        res.map((item, index) => {
          if (!this.checkBxes.find(box => box.value === item.id)) {
            this.checkBxes.push({
              id: index,
              value: item.id,
              isChecked: false
            });
          }
        });
      } else {
        this.noResult = true;
      }
    });
  }
  show(i) {
    if (this.open_table === i) {
      this.open_table = 0;
    } else {
      this.open_table = i;
    }
  }

  checkUncheckAll() {
    this.checkBxes.forEach((item, index) => {
      this.checkBxes[index].isChecked = this.select;
    });
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.select = this.checkBxes.every((item: any) => {
      return item.isChecked === true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    this.checkBxes.forEach((item, index) => {
      if (this.checkBxes[index].isChecked) {
        const res = this.emailDetails
          .pipe(
              map((result: EmailsDetailModel[]) => {
                return result.find((res: EmailsDetailModel) => {
                  return res.id === this.checkBxes[index].value;
              });
          }))
          .subscribe(result => {
            this.checkedList.push(result);
          });
      }
    });
    this.collectTableData();
  }

  collectTableData() {
    this.outputData.emit(this.checkedList);
  }

  collectCheckedDetailIds() {
    const result = [];
    for (const item of this.checkedList) {
      result.push({
        id:          item.email_id,
        user_id:     item.user_id,
        result_id:   item.result_id,
        company_id:  item.company_id,
        first_name:  item.first_name,
        last_name:   item.last_name,
        email:       item.email,
        location_name:   item.location_name,
        website:     item.website,
        linkedin_url:     item.linkedin_url,
        phone:       item.phone_number,
        position:    item.position,
        is_verified: item.is_verified
      });
    }
    return result;
  }

  saveEmails() {
    try {
      if (this.checkedList) {
        const collectDetails = this.collectCheckedDetailIds();
        this.httpClient.post(`${environment.api_url}/company/email-details-save`, {details: collectDetails}).pipe(
          map( (response: any) => {
            if (response.success) {
              this.resultFacade.loadResult(8).subscribe();
            }
            this.activeModal.close(EmailsModalComponent);
          })).subscribe();
      }
    } catch (e) {}
  }
}
