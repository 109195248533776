import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { JwtService } from './../jwt/jwt.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { CookieService } from "../cookie.service";
import { Router } from '@angular/router';
@Injectable()
export class JwtInterceptorService {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private auth: JwtService, private route: Router, private cookie: CookieService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //if (!request.headers.has('Content-Type')) {
    const authtoken = this.cookie.getCookie('auth');
    if (authtoken) {
      const authToken = JSON.parse(authtoken).access_token;
      if (authToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
          }
        });
      }
    } else {
      request = request.clone();
    }
    //}

    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401 && err.error.error === 'TOKEN_EXPIRED') {

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(request))
          );
        } else {
          return this.auth.refreshToken().pipe(switchMap((res: { access_token: string, token_type: string, expires_in: string }) => {
            if (res) {
              this.refreshTokenSubject.next(res.access_token);
              this.cookie.setCookie('auth', JSON.stringify(res), 1);
              return next.handle(this.addTokenToRequest(request, res.access_token));
            }
          }));
        }

      } else if (err.status === 401 || (err.status === 500 && err.error.message === "Token has expired and can no longer be refreshed")) {
        this.auth.logout();
        this.route.navigate(['/login']);
      } else if (err.status === 404) {
        this.route.navigate(['/404']);
      }
      return throwError(err.error);
    }));
  }

  public addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
