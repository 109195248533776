import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LocationTableComponent} from './location.table.component';
import {NgbModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {ModalComponent} from '../modal/modal.component';



@NgModule({
  declarations: [LocationTableComponent],
  imports: [
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    NgbRatingModule,
  ],
  entryComponents: [
    ModalComponent
  ],
  exports: [LocationTableComponent]
})
export class TableModule { }
