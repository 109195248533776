import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {Observable, of, from} from 'rxjs';
import {UserModel} from '../../model/user.model';
import {Router} from '@angular/router';
import { CookieService } from '../cookie.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private httpClient: HttpClient, private route: Router, private cookie: CookieService) { }

  login(email: string, password: string): Observable<UserModel> {
    return this.httpClient.post<{access_token: string, token_type: string, expires_in: string, user: {}}>(environment.api_url + '/login',
      {email, password}).pipe(tap(res => {
        const store = {access_token: res.access_token, token_type: res.token_type, expires_in: res.expires_in};
      this.cookie.setCookie('auth', JSON.stringify(store), 1);
    }), map((res: any) => new UserModel().deserialize(res.user)));
  }

  refreshToken(): Observable<{access_token: string, token_type: string, expires_in: string}> {
    return this.httpClient.post<{access_token: string, token_type: string, expires_in: string}>(environment.api_url + '/refresh',
      {});
  }

  logout() {
    this.cookie.setCookie('auth', false, 0);
    this.cookie.setCookie('user', false, 0);
    // window.location.reload();
    this.route.navigate(['/login']);
  }

  public get loggedIn(): boolean {
    const store = this.cookie.getCookie('auth');
    if(store)
      return JSON.parse(store) !==  null;
    else
      return false
  }

  public get loggedInUser(): boolean {
    const store = this.cookie.getCookie('authAsUser');
    return JSON.parse(store) !==  null;
  }

}
