import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PolicyService} from "./policy.service";
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {SettingsFacade} from "../../../settings/settings.facade";
import {UserModel} from "../../../model/user.model";
import { CookieService } from "../../../services/cookie.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss'],
})

export class PolicyModalComponent {

  faCheck = faCheck;
  policy_accepted = false;

  constructor(public activeModal: NgbActiveModal, private policyService: PolicyService, private settingsFacade: SettingsFacade, private cookie: CookieService) {
  }


  acceptPolicy(){
    if(this.policy_accepted){
      this.policyService.acceptPolicy().subscribe((res: UserModel) => {
        this.setUserAndCloseModal(res)
      }, (error: any) => {
        console.log(error);
        this.activeModal.close();
      })
    }
  }

  sendRejectRequest(){
    this.policyService.acceptPolicy("0").subscribe((res: UserModel) => {
      this.setUserAndCloseModal(res);
    }, (error) => {
      console.log(error);
      this.activeModal.close();
    })
  }

  private setUserAndCloseModal(res: UserModel): void{
    if(res){
      this.settingsFacade.setUser(res);
      this.cookie.setCookie('user', JSON.stringify(res), 1)
      this.activeModal.close();
    }
  }
}
