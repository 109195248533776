import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})

export class PrivacyModalComponent {
  constructor(public activeModal: NgbActiveModal) {
  }
  closeModal() {
    this.activeModal.close();
  }
}
