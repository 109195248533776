import {Deserializable} from "../../model/deserializable";

export class LemlistLabelModel implements Deserializable {

  public email: string;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public picture: string;
  public phone: string;
  public signature: string;
  public snippet1: string;
  public snippet2: string;
  public snippet3: string;
  public snippet4: string;
  public snippet5: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
