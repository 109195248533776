import {Component, Input, OnInit} from "@angular/core";
import {Company} from "../../model/company.model";
import {combineLatest, forkJoin, Observable} from "rxjs";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AutoresponderLabelsModel } from "../../model/autoresponder-labels.model";
import {AutoresponderVariableModel} from "../../model/autoresponder-variable.model";
import {SettingsState} from "../../../settings/state/settings.state";
import {CompanyState} from "../../state/company.state";
import {SettingsFacade} from "../../../settings/settings.facade";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CompanyFacade} from "../../company.facade";
import {map, take} from "rxjs/operators";

@Component({
  selector: 'app-high-level-tab',
  templateUrl: './high-level-tab.component.html',
  styleUrls: ['high-level-tab.component.css'],
})

export class HighLevelTabComponent implements OnInit {
  @Input() company: Company;
  variables: any;
  highLevelVariables$: Observable<{ name: string, label: string, type: string[], defaultValue: string }[]>;
  highLevelVariables: any; //ToDo need to define type
  highLevelVariablesForm: FormGroup;
  isLoading$: Observable<boolean>;
  highLevelLabels$: Observable<AutoresponderLabelsModel>;
  resultVariables$: Observable<{name: string, value: string, type: string[]}[]>;
  checks = {};
  faCheck = faCheck;

  constructor(
    private settingsState: SettingsState,
    private companyState: CompanyState,
    private settingsFacade: SettingsFacade,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private companyFacade: CompanyFacade,
  ) {
  }

  ngOnInit() {
    const companyId = this.company.id;
    const companyHighLevelVariables = this.companyFacade.getFlowHighLevelVariables(companyId);
    this.highLevelVariables$ = this.companyState.getFlowHighLevelVariables();
    combineLatest(this.highLevelVariables$, companyHighLevelVariables).subscribe(([highLevelVariables, companyHighLevelVariables]) => {
      this.variables = companyHighLevelVariables;
      this.highLevelVariables = highLevelVariables;
      this.initHighLevelForm(highLevelVariables)
    });
    this.isLoading$ = this.companyState.isUpdating$();
    this.highLevelLabels$ = this.settingsFacade.getHighLevel();
    this.resultVariables$ = this.companyState.getResultVariables();
  }

  getControl(name: string): AbstractControl{
    return this.highLevelVariablesForm.get(name);
  }

  getMaxLengthMessage(controlName: string): string {
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

  initHighLevelForm(res){
    const controls = {};
    for(let i = 0; i < res.length; i++){
      if(this.needToAddControl(res[i])){
        const variableValue = res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name];
        if(variableValue && variableValue.indexOf("{{") !== -1 && variableValue.indexOf("}}") !== -1){
          this.checks[res[i].name] = true;
        }
        controls[res[i].name] = [{value: res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name], disabled: !!res[i].defaultValue}, res[i].validators];
      }
    }
    this.highLevelVariablesForm = this.formBuilder.group(controls);
  }

  saveHighLevel(){
    if(this.highLevelVariablesForm.valid){
      this.companyState.setUpdating(true);
      this.companyFacade.updateFlowHighLevelVariables( this.company.id, this.highLevelVariablesForm.value).subscribe((res) => {
        this.companyState.setUpdating(false);
        res.type = "highLevel";
        this.activeModal.close(res);
      }, (res) => {
        this.companyState.setUpdating(false);
      });
    }
  }

  change(item: string){
    this.highLevelVariablesForm.get(item).setValue("");
  }

  needToAddControl(data: any): boolean {
    return data.type.indexOf("all") !== -1 || data.type.indexOf(this.company.type) !== -1;
  }
}
