import {Component, OnChanges, HostListener, OnInit, Output, EventEmitter} from '@angular/core';
import { faPlusCircle, faCog } from '@fortawesome/free-solid-svg-icons';
import { CompanyFacade } from '../../company.facade';
import { ModalComponent } from '../../containers/modal/modal.component';
import { Company } from '../../model/company.model';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivationEnd, Router} from '@angular/router';
import { ResultsFacade } from '../../../results/results.facade';
import { SettingModalComponent } from '../../containers/setting-modal/setting-modal.component';
import { WoodpeckerVariablesModel } from '../../model/woodpecker-variables.model';
import { ToastrService } from 'ngx-toastr';
import { LemlistVariablesModel } from "../../model/lemlist-variables.model";
import { MailshakeVariablesModel } from "../../model/mailshake-variables.model";
import {PursueVariablesModel} from "../../model/pursue-variables.model";
import {SearchState} from "../../../search/state/search.state";
import {SearchFacade} from "../../../search/search.facade";
import {SettingsFacade} from '../../../settings/settings.facade';
import { CookieService } from "../../../services/cookie.service";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  faPlusCircle = faPlusCircle;
  faCog = faCog;
  public routId: number;
  public companies$: Observable<Company[]>;
  public scrollbarOptions = { axis: 'y', theme: 'dark' };
  leadsReceived: number;
  leadsReceiveLimitation: number;
  peopleLocationReceived: number;
  peopleLocationReceiveLimitation: number;
  public adminAuth: Boolean;
  constructor(private companyFacade: CompanyFacade,
              private modalService: NgbModal,
              private route: Router,
              private resultFacade: ResultsFacade,
              private toastrService: ToastrService,
              private searchState: SearchState,
              private searchFacade: SearchFacade,
              private settings: SettingsFacade,
              private cookie: CookieService
  ) { }

  ngOnInit() {
    this.adminAuth = this.cookie.getCookie('adminAuth') ? true : false;
    this.route.events.subscribe(event => {
      let cityDepart = null;
      if ( event instanceof ActivationEnd) {
        cityDepart = event.snapshot.params['id'];
        this.routId = cityDepart;
      }
    });
    this.settings.getLicenseData().subscribe((res) => {
      this.leadsReceived = res.leadsReceived;
      this.leadsReceiveLimitation = res.leadsLocationReceiveLimitation;
      this.peopleLocationReceived = res.peopleLocationReceived;
      this.peopleLocationReceiveLimitation = res.peopleReceiveLimitation;
    });
    this.companies$ = this.companyFacade.getflowCompanies$();
    this.companyFacade.loadflowCompanies().subscribe();
  }

  backToSearch(): void{ //ToDo need to create one function in facade and we don't need to inject state instance in this component
    this.searchState.resetSearchForms();
    this.searchFacade.loadCompleate();
    this.searchState.setPeopleCurrentPage(0);
    this.searchState.setSingleCurrentPage(0);
    this.searchFacade.setSelectedLocation(null);
  }


  openSettingModal(company: Company): void{
    const modalRef = this.modalService.open(SettingModalComponent, { size: 'lg' });
    modalRef.componentInstance.company = company;
    modalRef.componentInstance.title = "Campaign autoselect option";
    modalRef.result.then((res) => {
      if(typeof res === "string"){
        const itemsPerPage = this.resultFacade.getItemsPerPage();
        const searchFilter = {
          filter: 'all',
          export: false
        }
        this.resultFacade.newLoadResults(company.id, this.resultFacade.getSearchValue(), (this.resultFacade.getCurrentPage() - 1) * itemsPerPage, itemsPerPage, searchFilter).subscribe();
        if(res === "Updated"){
          this.toastrService.success(res, "Success")
        } else {
          this.toastrService.error("Something Went Wrong", "Error");
        }
      } else {
        if(res.status === "success"){
          if(res.type === "woodpecker"){
            company.woodpecker_variables = new WoodpeckerVariablesModel().deserialize(res.content);
          } else if(res.type === "lemlist"){
            company.lemlist_variables = new LemlistVariablesModel().deserialize(res.content);
          } else if(res.type === "mailshake"){
            company.mailshake_variables = new MailshakeVariablesModel().deserialize(res.content);
          } else if(res.type === 'pursue'){
            company.pursue_variables = new PursueVariablesModel().deserialize(res.content);
          }
          this.companyFacade.updateflowCompanyLocal(company);
          this.toastrService.success("Saved", "Success");
        }
      }
    }).catch((res) => {
    });
  }

  create() {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Create Company';
    modalRef.componentInstance.modalType = 'create';
  }

  update(companyId) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Update Company';
    modalRef.componentInstance.modalType = 'update';
    modalRef.componentInstance.companyId = companyId;
  }

}
