import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingDirective } from './pending.directive';
import { PendingComponent } from './pending.component';

@NgModule({
  declarations: [PendingDirective, PendingComponent],
  imports: [
    CommonModule
  ],
  exports: [PendingDirective],
  entryComponents: [PendingComponent],
})
export class PendingModule { }
