import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable} from "rxjs";
import {Company} from "../../../company/model/company.model";
import {CompanyFacade} from "../../../company/company.facade";
import {SearchState} from "../../../search/state/search.state";
import {ModalComponent} from "../../../company/containers/modal/modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'select-campaign',
  templateUrl: 'selectCampaign.component.html',
  styleUrls: ['selectCampaign.component.css']
})
export class SelectCampaignComponent implements OnInit, OnDestroy {
  name: 'selectCampaign';
  public companies: Observable<Company[]>;
  public locationCompanies: Company[] = [];
  public peopleCompanies: Company[] = [];
  public singleCompanies: Company[] = [];
  public BCompanyId: number;
  public BCompanyType: String;
  public enabled: number;
  @Output() getBCompanyId = new EventEmitter();
  @Output() getBCompanyType = new EventEmitter();
  @Output() getEnabled = new EventEmitter();
  @Input() disabled: boolean;
  @Input() currentId: number;
  @Input() currentType: string;
  @Input() selectCampaignTitle: string;
  @Input() selectCampaignType: string;

  constructor(private companiesFacade: CompanyFacade, private form: SearchState, private modalService: NgbModal) { }
  ngOnInit() {
    this.BCompanyId = this.currentId ? this.currentId : this.form.getCompanyId();
    this.BCompanyType = this.currentType ? this.currentType : '';
    this.companies = this.companiesFacade.getflowCompanies$();
    this.separateCompanies();
  }
  separateCompanies() {
    this.companies.subscribe(companies$ => {
      this.locationCompanies = [];
      this.peopleCompanies = [];
      this.singleCompanies = [];
      for(const company of companies$) {
        switch (company.type) {
          case 'location':
            this.locationCompanies.push(company);
            break;
          case 'people':
            this.peopleCompanies.push(company);
            break;
          case 'single':
            this.singleCompanies.push(company);
            break;
        }
      }
    });
  }
  changeCompany($event) {
    if($event.target.value == 'new') {
      this.createCampaign()
      $event.target.value = ''
    } else {
      this.BCompanyId = $event.target.value
      this.BCompanyType = $event.target.selectedOptions[0].dataset.type
      this.getBCompanyId.emit(this.BCompanyId)
      this.getBCompanyType.emit(this.BCompanyType)
      switch (this.BCompanyType) {
        case 'location':
          this.enabled = 0;
          break;
        case 'people':
          this.enabled = 1;
          break;
        case 'single':
          this.enabled = 2;
          break;
      }
      this.getEnabled.emit(this.enabled)
    }
  }
  createCampaign() {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Create Company';
    modalRef.componentInstance.modalType = 'create';
  }
  ngOnDestroy() {}
}
