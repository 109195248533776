import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {GeoState} from "./geo.state";

@Injectable({
  providedIn: "root"
})

export class GeoGuard implements CanActivate{

  constructor(private router: Router, private geoState: GeoState) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.geoState.getBlockedStatus().subscribe((isBlocked: boolean) => {
      if(isBlocked){
        this.router.navigate(["/blocked"])
      } else {
        return true;
      }
    })
    return true;
  }
}
