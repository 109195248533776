import {Deserializable} from './../../model/deserializable';
import {WoodpeckerVariablesModel} from './woodpecker-variables.model';
import {LemlistVariablesModel} from "./lemlist-variables.model";
import {MailshakeVariablesModel} from "./mailshake-variables.model";
import {PursueVariablesModel} from "./pursue-variables.model";

export class Company implements Deserializable {
  public id: number;
  public user_id: number;
  public name: string;
  public type: null;
  public status: number;
  public created_at: string;
  public updated_at: number;
  public results_count: number;
  public emails_count: number;
  public woodpecker: number;
  public pursue: string;
  public lemlist: string;
  public high_level: string;
  public mailshake: string;
  public autoselect_option: string;
  public woodpecker_variables: WoodpeckerVariablesModel;
  public lemlist_variables: LemlistVariablesModel;
  public mailshake_variables: MailshakeVariablesModel;
  public pursue_variables: PursueVariablesModel;
  public only_verified: boolean

  deserialize(input: any): this {
    Object.assign( this, input);
    this.woodpecker_variables = new WoodpeckerVariablesModel().deserialize(input.woodpecker_variables);
    this.lemlist_variables = new LemlistVariablesModel().deserialize(input.lemlist_variables);
    this.mailshake_variables = new MailshakeVariablesModel().deserialize(input.mailshake_variables);
    this.pursue_variables = new PursueVariablesModel().deserialize(input.pursue_variables);
    return this;
  }
}
