import {Deserializable} from '../../model/deserializable';


export class PaginateUser implements Deserializable {
  public current_page: number;
  public data: [
      {
        id: number,
        name: string,
        email: string,
        created_at: any,
        updated_at: any,
        woodpecker: any,
        linkedin: string,
        avatar: string,
        license: any,
        isAdmin: number
      }
    ];
  public first_page_url: string;
  public from: number;
  public last_page: 94;
  public last_page_url: string;
  public next_page_url: string;
  public path: string;
  public per_page: 10;
  public prev_page_url: string;
  public to: 10;
  public total: number;

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
