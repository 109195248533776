export const environment = {
  production: false,
  api_uri: 'https://leadsbackend.server1.purelogics.net/api',
  api_url: 'https://leadsbackend.server1.purelogics.net/api',
  api_image: 'https://leadsbackend.server1.purelogics.net/',
  linkedIn: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=866ffed018mh7b&client_secret' +
    '=Dbz9HVYoaLggw2qm&scope=r_liteprofile%20r_emailaddress%20w_member_social&redirect_uri=https://discover-dev.gojeo.com/connect/linkedin',
  linkedInToken: 'https://www.linkedin.com/oauth/v2/accessToken?' +
    'grant_type=authorization_code&redirect_uri=https://discover-dev.gojeo.com/connect/linkedin&client_id=866ffed018mh7b&client_secret' +
    '=Dbz9HVYoaLggw2qm',
  facebookAppId: '886482335051250',
};
