import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {faCheck, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {ModalComponent} from '../modal/modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyFacade} from '../../../company/company.facade';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Company} from "../../../company/model/company.model";
import {ResultsFacade} from "../../../results/results.facade";
import {SearchResultModel} from "../../../search/model/searchResult.model";

@Component({
  selector: "app-single-table",
  templateUrl: "single.table.component.html",
  styleUrls: ["single.table.component.css"],
})

export class SingleTableComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private modalService: NgbModal,
    private companyFacade: CompanyFacade,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private resultsFacade: ResultsFacade,
  ) {
  }
  @Input() changePage: any;
  @Input() isCheckboxes: boolean = false;
  @Input() tableData: Observable<any>;
  @Input() totalItems: number;
  @Input() checkboxes: boolean;
  @Input() company: Company;
  @Input() ghlSelectedCnt: any;
  @Input() searchValue: string;
  @Output() outputData = new EventEmitter();
  @Output() selectAll = new EventEmitter();
  @Output() withoutResultsGhl = new EventEmitter();
  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  noResult: boolean;
  checkBoxes: any = [];
  allCheckBoxes: any = [];
  select = false;
  public selectAllResults = false;
  emails = [];
  checkedList = [];
  nextPage: number | null;
  nextPageSubscription: Subscription;
  tableDataSubscription: Subscription;

  public withoutResults = [];
  public ghlCheckBoxes = [];
  public checkedListGHL: any;
  @Output() outputDataGHL = new EventEmitter();


  @Output() singleLoaded = new EventEmitter();

  ngOnInit(): void {
    this.collectCheckboxData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(typeof changes.company !== "undefined" && !changes.company.firstChange){
      this.select = false;
      this.selectAllResults = false;
      this.collectCheckboxData();
    }
    if(typeof changes.changePage !== "undefined" && !changes.changePage.firstChange) {
      if(this.selectAllResults) {
        this.select = true;
        this.selectAll.emit(this.selectAllResults);
        this.ghlCheckBoxes.map((item: any) => {
          return item.isChecked = true;
        });
      }
    }
  }

  collectCheckboxData(){
    this.nextPageSubscription = this.resultsFacade.getNextPage().subscribe((res) => {
      this.nextPage = res;
    })
    this.tableDataSubscription = this.tableData.subscribe((res: any) => {
      this.select = false;
      this.checkBoxes = [];
      this.ghlCheckBoxes = [];
      if(res && res.length){
        this.noResult = false;
        res.map((item, index) => {
          let isAdded = false;
          this.allCheckBoxes.map((checkbox, index) => {
            if(checkbox.value === item.value) {
              isAdded = true;
              return;
            }
          });
          if(!isAdded) {
            this.checkBoxes.push({
              id: index,
              isChecked: true,
              value: item.value,
            });
          }

          this.allCheckBoxes.push({
            id: index,
            value: item.value,
            isChecked: true
          });
          this.ghlCheckBoxes.push({
            id: index,
            value: item.id,
            to_be_sent_ghl: item.to_be_sent_ghl,
            isChecked: false
          });
        });
        this.emails = res.emails;
      } else {
        this.noResult = true;
      }
      this.getCheckedItemList();
    })
  }

  collectTableData(){
    this.outputData.emit(this.checkedList);
    this.singleLoaded.emit('single');
  }

  get defaultImage(): string {
    return environment.api_image + 'img/avatar.png';
  }

  isAllSelected(changedItem) {
    this.select = changedItem.isChecked;
    if(!this.select && this.selectAllResults) {
      this.withoutResults.push(changedItem.value)
    } else {
      this.withoutResults = this.withoutResults.filter((item, i)=> this.withoutResults[i] !== changedItem.value)
    }

    this.withoutResultsGhl.emit(this.withoutResults);
    this.getCheckedItemListGHL();
  }

  getZapierAccessAll(checked: boolean){
    this.companyFacade.zapierAccessAll(this.route.snapshot.params.id, checked, this.searchValue).subscribe((res) => {
      for(let key in this.checkBoxes){
        this.checkBoxes[key].isChecked = checked;
      }
      if(checked){
        this.toasterService.success('Record Sent', '')
      } else {
        this.toasterService.warning("Access Denied", "Zapier");
      }
    });
  }

  checkUncheckAll() {
    this.ghlCheckBoxes.forEach((item, index) => {
      this.ghlCheckBoxes[index].isChecked = this.select;
    });
    if(!this.select) {
      this.selectAllResults = false;
    }
    this.getCheckedItemListGHL();
  }
  checkUncheckAllResults() {
    this.selectAllResults = !this.selectAllResults;
    this.selectAll.emit(this.selectAllResults);
    if(!this.selectAllResults) {
      this.select = false;
      this.checkUncheckAll();
    }
  }

  getCheckedItemListGHL() {
    this.checkedListGHL = [];
    this.ghlCheckBoxes.forEach((item, index) => {
      if (this.ghlCheckBoxes[index].isChecked) {
        this.tableData
          .pipe(
            map((result: SearchResultModel[]) => {
              return result.find((res: SearchResultModel) => {
                if(this.ghlCheckBoxes[index])
                  return res.id === this.ghlCheckBoxes[index].value;
                else
                  return false;
              });
            }))
          .subscribe(result => {
            if(result)
              this.checkedListGHL.push(result.id);
          });
      }
    });
    this.collectGHLTableData();
  }
  collectGHLTableData() {
    this.outputDataGHL.emit(this.checkedListGHL);
  }

  getCheckedItemList() {
    this.checkedList = [];
    this.checkBoxes.forEach((item, index) => {
      if (this.checkBoxes[index].isChecked) {
        this.tableData
          .pipe(
            map((result: any) => {
              return result.find((res: any) => {
                return item.value === res.value;
              });
            }))
          .subscribe(result => {
            this.checkedList.push(result);
          });
      }
    });
    this.collectTableData();
  }

  deleteResult(id) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Delete Email';
    modalRef.componentInstance.message = 'Are you sure you want to delete the email?';
    modalRef.componentInstance.type = 'single';
    modalRef.componentInstance.id = id;
  }
  deleteSelectedResults() {
    if(this.checkedListGHL && this.ghlSelectedCnt) {
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.modalTitle = 'Delete results';
      modalRef.componentInstance.message = 'Are you sure you want to delete the selected results?';
      modalRef.componentInstance.type = 'emails';
      modalRef.componentInstance.ids = this.checkedListGHL;
    }
  }

  loadMoreSingle(){
    this.resultsFacade.loadResult(this.company.id, this.nextPage).subscribe();
  }

  ngOnDestroy() {
    this.tableDataSubscription.unsubscribe();
    this.nextPageSubscription.unsubscribe();
    this.resultsFacade.setNextPage(0);
  }

}
