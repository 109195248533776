import {Injectable, ɵQueryBindingType} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {SearchResultModel} from '../../search/model/searchResult.model';
import {Observable, throwError} from 'rxjs';
import {UserModel} from '../../model/user.model';
import {LinkedinModel} from '../model/linkedin.model';
import {WoodpeckerLabelsModel} from '../model/woodpecker-labels.model';
import {ProspectLabelModel} from '../model/prospect-label.model';
import {LemlistLabelModel} from "../model/lemlist-label.model";
import {MailshakeLabelModel} from "../model/mailshake-label.model";
import {PursueLabelModel} from "../model/pursue-label.model";
import {ConnectSourceResponseModel} from "../model/connect-source-response.model";
import {HighLevelVariablesModel} from "../../company/model/high-level-variables.model";

@Injectable({
  providedIn: 'root'
})
export class SettingsApi {

  constructor(private httpClient: HttpClient) { }

  connectSource(source): Observable<ConnectSourceResponseModel> {
    return this.httpClient.post
    (environment.api_url + `/settings/connect`, source).pipe(map((res: any) => {
      return {
        user: new UserModel().deserialize(res.content),
        message: res.message
      };
    }));
  }

  disconnect(source): Observable<UserModel> {
    return this.httpClient.post(environment.api_url + `/settings/disconnect`, source).pipe(map((res: any) => new UserModel().deserialize(res.content)));
  }

  getIntegrations(){
    return this.httpClient.get(`${environment.api_url}/settings/integrations`).pipe(
      map((res: {content: {high_level: HighLevelVariablesModel, woodpecker: WoodpeckerLabelsModel, prospect: ProspectLabelModel, lemlist: LemlistLabelModel, mailshake: MailshakeLabelModel, pursue: PursueLabelModel}}) => {
        return {
          woodpecker: new WoodpeckerLabelsModel().deserialize(res.content.woodpecker),
          // prospect: new ProspectLabelModel().deserialize(res.content.prospect),
          lemlist: new LemlistLabelModel().deserialize(res.content.lemlist),
          mailshake: new MailshakeLabelModel().deserialize(res.content.mailshake),
          pursue: new PursueLabelModel().deserialize(res.content.pursue),
          high_level_variables: new HighLevelVariablesModel().deserialize(res.content.high_level),
        }
      })
    );
  }

  updateWoodpecker(data: any): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/settings/woodpecker`, data);
  }

  updateProspect(data: any): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/settings/prospects`, data);
  }

  updateLemlist(data: any): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/settings/lemlist`, data);
  }

  updateMailshake(data: any): Observable<any>{
    return this.httpClient.put(`${environment.api_url}/settings/mailshake`, data);
  }

  updatePursue(data: any): Observable<any>{ //ToDo need to define models for this methods
    return this.httpClient.put(`${environment.api_url}/settings/pursue`, data);
  }

  updateHighLevel(data: any): Observable<any>{ //ToDo need to define models for this methods
    return this.httpClient.put(`${environment.api_url}/settings/high_level`, data);
  }

  updateUser(userData): Observable<UserModel> {
    return this.httpClient.post(environment.api_url + '/settings/user', userData).pipe(map((res: any) =>
      new UserModel().deserialize(res.content)));
  }

  checkLicense(): Observable<{license: boolean}> {
    return this.httpClient.post<{license: boolean}>(environment.api_url + `/settings/license`, {});
  }

  resetPassword(email): Observable<{status: boolean}> {
    return this.httpClient.post<{status: boolean}>(environment.api_url + `/settings/reset_password`, email);
  }

  checkResetCode(code): Observable<{status: boolean}> {
    return this.httpClient.post<{status: boolean}>(environment.api_url + `/settings/reset_password_update`, code);
  }

  getLicenseData(): Observable<any>{//ToDo need to define response type
    return this.httpClient.get(`${environment.api_url}/settings/get_license_data`);
  }

  authWithJWT(token: string): Observable<any>{
    return this.httpClient.post(`${environment.api_url}/login_user`, {token});
  }
  getUser(): Observable<any>{
    return this.httpClient.get(`${environment.api_url}/me`);
  }
}
