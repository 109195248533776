import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Company} from '../../model/company.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyState} from '../../state/company.state';
import {CompanyFacade} from '../../company.facade';
import {Observable} from 'rxjs';
import {WoodpeckerLabelsModel} from '../../../settings/model/woodpecker-labels.model';
import {SettingsFacade} from '../../../settings/settings.facade';
import {tap} from 'rxjs/operators';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "app-settings-modal",
  templateUrl: "setting-modal.component.html",
  styleUrls: ["setting-modal.component.css"],
})

export class SettingModalComponent implements OnInit {
  @Input() title: string;
  @Input() company: Company;

  autoSelectOptions: any;
  woodpeckerLabels: Observable<WoodpeckerLabelsModel>;
  resultVariables$: Observable<{name: string, value: string, type: string[]}[]>;
  woodpeckerVariables$: Observable<{name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[] | []}[]>;
  isLoading$: Observable<boolean>;
  checks = {};
  woodpeckerVariableForm: FormGroup;
  companyVariables: any;
  variableLength = 0;
  faCheck = faCheck;

  constructor(private formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              private companyState: CompanyState,
              private companyFacade: CompanyFacade,
              private settingsFacade: SettingsFacade,
  ) {
  }

  companyForm: FormGroup;

  ngOnInit(): void {
    this.companyVariables = this.company.woodpecker_variables;
    this.woodpeckerVariables$ = this.companyState.getFlowWoodpeckerVariables().pipe(
      tap((res: {name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[] | []}[]) => {
        this.variableLength = res.length;
        if(!this.woodpeckerVariableForm){
          this.initWoodpeckerForm(res);
        }
      }),
    );
    this.isLoading$ = this.companyState.isUpdating$();
    this.woodpeckerLabels = this.settingsFacade.getWoodpecker();
    this.autoSelectOptions = this.companyState.getAutoselectOptions();
    this.resultVariables$ = this.companyState.getResultVariables();
    this.companyForm = this.formBuilder.group({
      autoselect_option: [this.company.autoselect_option, [Validators.required]],
      only_verified: [this.company.only_verified]
    });
  }

  initWoodpeckerForm(data: {name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[]}[]){
    const controls = {};
    for(let i = 0; i < data.length; i++){
      if(this.needToAddControl(data[i])){
        const variableValue = data[i].defaultValue ? data[i].defaultValue : this.companyVariables[data[i].name];
        if(variableValue && variableValue.indexOf("{{") !== -1 && variableValue.indexOf("}}") !== -1){
          this.checks[data[i].name] = true;
        }
        controls[data[i].name] = [{value: data[i].defaultValue ? data[i].defaultValue : this.companyVariables[data[i].name], disabled: !!data[i].defaultValue}, data[i].validators];
      }
    }
    this.woodpeckerVariableForm = this.formBuilder.group(controls);
  }

  needToAddControl(data: any): boolean {
    return data.type.indexOf("all") !== -1 || data.type.indexOf(this.company.type) !== -1
  }

  change(item: string){
    this.woodpeckerVariableForm.get(item).setValue("");
  }

  saveWoodpecker(){
    this.companyState.setUpdating(true);
    this.companyFacade.updateFlowWoodpeckerVariables( this.company.id, this.woodpeckerVariableForm.value).subscribe((res) => {
      this.companyState.setUpdating(false);
      res.type = "woodpecker";
      this.activeModal.close(res);
    }, (res) => {
      this.companyState.setUpdating(false);
    });
  }

  getControl(name: string): AbstractControl{
    return this.woodpeckerVariableForm.get(name);
  }


  getInputName(data){
    if(typeof data === "string"){
      return data.replace("_", " ");
    }
    return data;
  }

  saveOption(){
    this.companyState.setUpdating(true);
    this.company.autoselect_option = this.companyForm.get("autoselect_option").value;
    this.company.only_verified = this.companyForm.get('only_verified').value;
    this.companyFacade.updateCompany(this.company).subscribe((res) => {
      this.companyState.setUpdating(false);
      this.activeModal.close("Updated");
    }, (res) => {
      this.companyState.setUpdating(false);
    })
  }

  getMaxLengthMessage(controlName: string){
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

}
