import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faSearch, faCircle} from '@fortawesome/free-solid-svg-icons';
import {Observable} from 'rxjs';
import {SearchFacade} from '../../search.facade';
import {SearchState} from '../../state/search.state';
import {LicenseState} from "../../../license/license.state";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: "app-single-search",
  templateUrl: "./single-search.component.html",
  styleUrls: ["single-search.component.css"],
})

export class SingleSearchComponent implements OnInit{
  @Input() disabled: boolean;
  @Input() companyId: any;
  form: FormGroup;
  submitted = false;
  faSearch = faSearch;
  faCircle = faCircle;
  loadComplete: Observable<boolean>;
  update$: Observable<boolean>;
  singleFormState: any;
  canMakeRequests$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private searchFacade: SearchFacade,
    private licenseState: LicenseState,
    private searchState: SearchState,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.singleFormState = this.searchState.getSingleForm();
    this.initForm();
    this.loadComplete = this.searchFacade.isLoadCompleate$();
    this.update$ = this.searchFacade.getFlowSingleUpdating();
    this.canMakeRequests$ = this.licenseState.getCanMakeRequest();
  }

  singleSearch(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.searchFacade.setSingleCurrentPage(0);
    this.searchState.setSingleForm(this.form.value);
    this.searchFacade.setSingleHasMore(false);
    this.searchFacade.setIsEU(false);
    this.form.value.companyId = this.companyId;
    this.searchFacade.loadFlowSingleResult(this.form.value).subscribe((res) => {
    });
  }

  initForm(){
    this.form = this.formBuilder.group({
      query: [this.singleFormState.query, Validators.required],
    });
  }

}
