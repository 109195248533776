import {Deserializable} from '../../model/deserializable';


export class SearchResultPeopleModel implements Deserializable {
  public company: string;
  public id: number;
  public website: string;
  public name: string;
  public photo: string;
  public role: string;
  public email: string;
  public locale: string;
  public location: string;
  public main_location: any;

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
