import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {faCircle, faSearch} from '@fortawesome/free-solid-svg-icons';
import {SettingsFacade} from '../../../settings/settings.facade';
import {Observable, Subscription} from 'rxjs';
import {UserModel} from '../../../model/user.model';
import {SearchFacade} from '../../search.facade';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {SearchState} from '../../state/search.state';
import {SearchModel} from '../../model/search.model';
import {LicenseState} from "../../../license/license.state";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() companyId: any;
  faSearch = faSearch;
  faCircle = faCircle;
  user$: Observable<UserModel>;
  loadCompleate: Observable<boolean>;
  updatePeople$: Observable<boolean>;
  peopleForm: FormGroup;
  submitted = false;
  autocompleate$: Observable<SearchModel[]>;
  update$: Observable<boolean>;
  canMakeRequests$: Observable<boolean>;
  private locationFormState: any;
  locationValidationError = false;
  private user: UserModel;
  private userSubscription: Subscription;

  constructor(public settingsFacade: SettingsFacade,
              private formBuilder: FormBuilder,
              public searchFacade: SearchFacade,
              private form: SearchState,
              private licenseState: LicenseState,
              private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.locationFormState = this.form.getPeopleForm();
    this.canMakeRequests$ = this.licenseState.getCanMakeRequest();
    this.updatePeople$ = this.searchFacade.isUpdatingPeople$();
    this.loadCompleate = this.searchFacade.isLoadCompleate$();
    this.user$ = this.settingsFacade.getUser$();
    this.userSubscription = this.user$.subscribe((user: UserModel) => {
      this.user = user;
    })
    this.peopleForm = this.formBuilder.group({
      location: [this.locationFormState.location, []],
      role: [this.locationFormState.role, [Validators.required]],
      company: [this.locationFormState.company],
      place_id:  [this.locationFormState.place_id, [Validators.required]],
    });

    this.peopleForm.get('location').valueChanges.pipe(debounceTime(500)).subscribe( value => {
      if (value !== '') {
        this.searchFacade.loadflowAutocompleate(value).subscribe();
      } else {
        this.searchFacade.flowAutonull();
      }
    });
    this.autocompleate$ = this.searchFacade.getflowAutocompleate$();
    this.update$ = this.searchFacade.isUpdating$();
  }

  get f() { return this.peopleForm.controls; }

  people_search() {
    this.submitted = true;
    if (this.peopleForm.invalid) {
      return;
    }
    this.searchFacade.setPeopleCurrentPage(0);
    const formValues = this.peopleForm.value;
    const selectedLocation = this.searchFacade.getSelectedLocation();
    if(selectedLocation && selectedLocation.description !== formValues.location){
      this.locationValidationError = true;
      return;
    }

    this.form.setPeopleForm(formValues);
    this.searchFacade.setPeopleHaveMore(false);
    formValues.companyId = this.companyId;
    this.searchFacade.loadflowPeopleResult(formValues).subscribe((res) => {
      this.searchFacade.setPeopleHaveMore(res.length >= 10);
    });
  }

  setLocation(location: SearchModel) {
    const isEU = !this.searchFacade.checkGeo(location);
    this.searchFacade.setIsEU(isEU);
    if(!isEU || (this.user.accept_policy && this.user.accept_policy.accepted === 1)){
      this.locationValidationError = false;
      this.searchFacade.setSelectedLocation(location);
      this.peopleForm.patchValue({
        location: location.description,
        place_id: location.place_id
      });
    } else {
      this.toastrService.warning("Blocked", this.form.blockedMessage)
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
