import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {TermsModalComponent} from "../modal/terms-modal/terms-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PrivacyModalComponent} from "../modal/privacy-modal/privacy-modal.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  date = new Date();
  public className: string;
  constructor(public router: Router,
              private modalService: NgbModal,) {}

  ngOnInit() {
    if(this.router.url === '/login'){
      this.className = 'full-page';
    }
  }

  public openTermsModal(){
    this.modalService.open(TermsModalComponent, {size: "xl", backdrop: 'static', centered: true});
  }

  public openPrivacyModal(){
    this.modalService.open(PrivacyModalComponent, {size: "xl", backdrop: 'static', centered: true});
  }

}
