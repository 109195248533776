import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PaginateUser} from '../model/paginate-user';

@Injectable({
  providedIn: 'root'
})
export class RelatedUserState {
  private isUpdating$ = new BehaviorSubject<boolean>(false);
  private relatedUsers$ = new BehaviorSubject<PaginateUser>(null);
  private query: string = '';

  constructor() { }

  isUpdating(): Observable<boolean> {
    return this.isUpdating$.asObservable();
  }

  setUdating(trigger: boolean) {
    this.isUpdating$.next(trigger);
  }

  setQuery(query: string) {
    this.query = query;
  }

  getQeury(): string {
    return this.query;
  }

  getRelatedUsers(): Observable<PaginateUser> {
    return this.relatedUsers$.asObservable();
  }

  setRelatedUsers(users: PaginateUser) {
    this.relatedUsers$.next(users);
  }
}
