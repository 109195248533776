import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {RelatedUserFacade} from '../../related-user.facade';
import {Observable} from 'rxjs';
import {PaginateUser} from '../../model/paginate-user';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, map, tap} from 'rxjs/operators';
import {UserComponent} from '../../containers/modal/user/user.component';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import {UserModel} from "../../../model/user.model";
import { CookieService } from "../../../services/cookie.service";

@Component({
  selector: 'app-related-user',
  templateUrl: './related-user.component.html',
  styleUrls: ['./related-user.component.css']
})
export class RelatedUserComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['id', 'name', 'email', 'username', 'license', 'transaction_id', 'actions'];
  public scrollbarOptions = { axis: 'y', theme: 'dark' };
  public users$: Observable<PaginateUser>;
  public update$: Observable<boolean>;
  searchForm: FormGroup;

  constructor(private relatedUser: RelatedUserFacade,
              private formBuilder: FormBuilder,
              private httpClient: HttpClient,
              private cookie: CookieService,
              public dialog: MatDialog
            ) { }

  ngOnInit() {
    this.relatedUser.getUsers();
    this.update$ = this.relatedUser.isupdataing();
    this.users$ = this.relatedUser.getStateUsers();
    this.searchForm = this.formBuilder.group({
      q: [''],
    });
    this.searchForm.get('q').valueChanges.pipe(debounceTime(500)).subscribe( res => {
      this.relatedUser.setQuery(res);
      this.relatedUser.getUsers();
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('location-results-page');
    body.classList.add('overflow_admin');
  }

  updateUser(user): void {
    this.dialog.open(UserComponent, {
      width: '500px',
      data: { userData: user, type: 'update' }
    });
  }

  loginAsUser(userEmail): void {
    this.httpClient.post<{access_token: string, token_type: string, expires_in: string, user: {}}>(environment.api_url + '/loginAs',
      {userEmail}).pipe(tap(res => {
      const store = {access_token: res.access_token, token_type: res.token_type, expires_in: res.expires_in};
      this.cookie.setCookie('adminAuth', this.cookie.getCookie('auth'), 1);
      this.cookie.setCookie('adminUser', this.cookie.getCookie('user'), 1);
      this.cookie.setCookie('auth', JSON.stringify(store), 1);
      this.cookie.setCookie('user', JSON.stringify(res.user), 1);
    }), map((res: any) => {
      new UserModel().deserialize(res.user);
      location.href = '/';
    })).subscribe();
  }

  public prevNextPage(event) {
    const validPage = event.pageIndex + 1;
    this.relatedUser.getUsers(validPage, event.pageSize);
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('location-results-page');
    body.classList.remove('overflow_admin');
  }

}
