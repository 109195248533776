import { Injectable } from '@angular/core';
import {SettingsFacade} from '../settings/settings.facade';
import {ApiAdminService} from './api/api.service';
import {AdminState} from './state/admin.state';
import {Observable} from 'rxjs';
import {PaginateUser} from './model/paginate-user';
import {ProductsModel} from "./model/products-model";
import {SubscriptionTypeModel} from "./model/subscription-type-model";

@Injectable({
  providedIn: 'root'
})
export class AdminFacade {

  constructor(private settings: SettingsFacade, private admin: ApiAdminService, private  state: AdminState) { }

  get isAdmin() {
    const user = this.settings.getUserModel();
    if(user)
      return user.isAdmin;
    else
      return false;
  }

  getStateUsers(): Observable<PaginateUser> {
    return this.state.getadminUsers();
  }

  getStateAllUsers(): Observable<PaginateUser> {
    return this.state.getAdminAllUsers();
  }

  isupdataing(): Observable<boolean> {
    return this.state.isUpdating();
  }

  setQuery(q: string) {
    this.state.setQuery(q);
  }

  getUsers(page = 1, count = 10, deleted = false) {
    this.state.setUdating(true);
    this.admin.getUsers(page, count, this.state.getQeury(), deleted).subscribe( res => {
      this.state.setAdminUsers(res);
      this.state.setUdating(false);
    });
    if(!this.state.getQeury()) {
      this.admin.getUsers(page, count, this.state.getQeury(), deleted, true).subscribe( res => {
        this.state.setAdminAllUsers(res);
        this.state.setUdating(false);
      });
    }
  }

  createUser(value) {
    return this.admin.createUser(value).subscribe( res => {
      this.getUsers();
    }, error => {
      return error;
    });
  }

  updateUser(value) {
    return this.admin.updateUser(value).subscribe( res => {
      this.getUsers();
    }, error => {
      return error;
    });
  }

  activeInactiveUser(id, active) {
    this.admin.activeInactiveUser(id, active).subscribe( res => {
      if(active) {
        this.getUsers(1, 10, true)
      } else {
        this.getUsers();
      }
    });
  }

  getProducts(): Observable<ProductsModel[]>{
    return this.state.getProductIds();
  }

  getSubscriptionTypes(): Observable<SubscriptionTypeModel[]>{
    return this.state.getSubscriptionTypes();
  }
}
