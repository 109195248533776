import { Injectable } from '@angular/core';
import {CompanyState} from './state/company.state';
import {CompanyService} from './api/company.service';
import {Observable} from 'rxjs';
import {Company} from './model/company.model';
import {map, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class CompanyFacade {
  constructor(private companyState: CompanyState, private companyApi: CompanyService, private toastr: ToastrService, private route: ActivatedRoute) {}

  isUpdating$(): Observable<boolean> {
    return this.companyState.isUpdating$();
  }

  isUpdatingFilter$(): Observable<boolean> {
    return this.companyState.isUpdatingFilter$();
  }

  getflowCompanies$(): Observable<Company[]> {
    return this.companyState.getflowCompanies$();
  }

  getflowCompaniesFilter$(): Observable<Company[]> {
    return this.companyState.getflowCompaniesFilter$();
  }

  getFlowHighLevelVariables(companyId: number): Observable<any>{ //ToDo need to define type
    return this.companyState.getFlowCompanyHighLevelVariables(companyId);
  }

  getIntegrationCampaigns(source: string): Observable<any>{
    return this.companyApi.getIntegrationCampaigns(source).pipe(
      map((res) => {
        return (res && res.content) ? res.content : res;
      })
    );
  }

  getflowCompany$(): Observable<Company> {
    return this.companyState.getflowCompany$();
  }

  updateFlowWoodpeckerVariables(companyId: number, data: any): Observable<any>{
    return this.companyApi.updateWoodpeckerVariables(companyId, data);
  }

  updateFlowLemlistVariables(companyId: number, data: any): Observable<any>{
    return this.companyApi.updateLemlistVariables(companyId, data);
  }

  updateFlowHighLevelVariables(companyId: number, data: any): Observable<any>{
    return this.companyApi.updateHighLevelVariables(companyId, data).pipe(tap(() => {
      this.companyState.setFlowCompanyHighLevelVariables(data, companyId)
    }));
  }

  updateFlowMailshakeVariables(companyId: number, data: any): Observable<any>{
    return this.companyApi.updateMailshakeVariables(companyId, data);
  }

  updateFlowPursueVariables(companyId: number, data: any): Observable<any>{
    return this.companyApi.updatePursueVariables(companyId, data);
  }

  loadflowCompany(companyId) {
    this.companyState.getflowCompanies$().subscribe( res => {
      if (res !== null) {
        this.companyState.loadflowCompanyLocal$(companyId);
      }
    });
  }

  loadflowCompanies(filter = 'all') {
    if (filter === 'all') {
      this.companyState.setUpdating(true);
    } else {
      this.companyState.setUpdatingFilter(true);
    }
    return this.companyApi.show(filter)
      .pipe(tap(companies => {
        if (filter === 'all') {
          this.companyState.setUpdating(false);
          if (companies.length !== 0) {
            this.companyState.setflowCompanies(companies);
          }
        } else {
          this.companyState.setUpdatingFilter(false);
          if (companies.length === 0) {
            this.companyState.setflowCompaniesFilter(null);
          } else {
            this.companyState.setflowCompaniesFilter(companies);
          }
        }
      }));
  }

  createflowCompanies(companyName) {
    this.companyState.setUpdating(true);
    return this.companyApi.create_company(companyName).pipe(tap(company => {
      this.companyState.setUpdating(false);
      if (Boolean(company)) {
        this.companyState.addflowCompanies(company);
        return company;
      }
    }));
  }

  setUpdating(state: boolean): void{
    this.companyState.setUpdating(state);
  }

  updateflowCompany(updatedCompany: Company) {
    this.companyState.updateflowCompany(updatedCompany);
    this.companyApi.update_company(updatedCompany).subscribe(res => {
      this.toastr.success('Complete!', 'Success');
    }, error => console.log(error)); /* сделать откат изменений при неудаче обновления. */
  }

  updateCompany(updatedCompany: Company): Observable<any>{
    this.companyState.updateflowCompany(updatedCompany);
    return this.companyApi.update_company(updatedCompany);
  }

  updateflowCompanyLocal(updatedCompany: Company) {
    this.companyState.updateflowCompany(updatedCompany);
  }

  deleteCampaign(campaignId: number, type: string) {
    this.companyState.deleteCampaign(campaignId);
    this.companyApi.deleteCampaign(campaignId).subscribe(() => {
      this.toastr.success('Complete!', 'Success');
      this.loadflowCompanies(type).subscribe();
    });
  }

  decrementCurrentCompanyResults(count){
    this.companyState.decrementCurrentCompanyResults(count);
  }

  zapierAccessAll(companyId, checked, searchValue: string = ""){
    return this.companyApi.zapierAccessAll(companyId, checked, searchValue);
  }

  getResultVariables(): Observable<any> {
    return this.companyState.getResultVariables();
  }

  getFlowPursueVariables() {
    return this.companyState.getFlowPursueVariables();
  }
}
