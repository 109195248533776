import { Component, OnDestroy, OnInit } from '@angular/core';
import {faLock, faEyeSlash, faEye, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SettingsFacade} from '../../settings.facade';
import {Observable} from 'rxjs';
import {UserModel} from '../../../model/user.model';
import {Toast, ToastrService} from "ngx-toastr";
import {CookieService} from "../../../services/cookie.service";

@Component({
  selector: 'app-settings',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.css']
})
export class IntegrationsComponent implements OnInit, OnDestroy {

  faLock = faLock;
  faEyeSlash = faEyeSlash;
  faTimes = faTimes;
  faEye = faEye;
  public user$: Observable<UserModel>;
  selectedApi: string;
  public integrations$: Observable<any>;

  public show:boolean = false;
  prospectShow = false;
  lemlistShow = false;
  highLevelShow = false;
  mailshakeShow = false;
  pursueShow = false;
  public submit:boolean = false;
  submitLemlist = false;
  submitHighLevel = false;
  submitMailshake = false;
  submitPursue = false;
  public tokenForm: FormGroup;
  // prospectApiForm: FormGroup;
  lemlistApiForm: FormGroup;
  mailshakeApiForm: FormGroup;
  highLevelApiForm: FormGroup;
  pursueApiForm: FormGroup;
  isConnecting$: Observable<boolean>;

  constructor(private formBuilder: FormBuilder, private settings: SettingsFacade, private toastrService: ToastrService, private cookie: CookieService) { }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('settings-page');
    this.integrations$ = this.settings.getIntegrations();
    this.tokenForm = this.formBuilder.group({
      token: ['', [Validators.required]],
    });
    this.isConnecting$ = this.settings.isConnecting$();
    this.lemlistApiForm = this.formBuilder.group({
      lemlist_api_key: ["", [Validators.required]],
    });
    this.mailshakeApiForm = this.formBuilder.group({
      mailshake_api_key: ["", [Validators.required]],
    });
    this.pursueApiForm = this.formBuilder.group({
      pursue_api_key: ["", [Validators.required]],
    });
    this.highLevelApiForm = this.formBuilder.group({
      high_level_api_key: ["", [Validators.required]],
    });
    // this.prospectApiForm = this.formBuilder.group({
    //   prospect_api_token: ["", [Validators.required]],
    // });
    this.user$ = this.settings.getUser$();
  }

  showPassword() {
    this.show = !this.show;
  }

  get f() { return this.tokenForm.controls; }
  // get prospect() { return this.prospectApiForm.controls; }
  get lemlist() { return this.lemlistApiForm.controls}
  get mailshake(){ return this.mailshakeApiForm.controls }
  get pursue(){ return this.pursueApiForm.controls }
  get highLevel(){ return this.highLevelApiForm.controls }

  connect(type) {
    if (type === 'linkedin') {
      this.settings.linkedinConnect();
      return;
    }
    if(type === "mailshake"){
      this.submitMailshake = true;
      this.settings.setConnecting(true)
      this.settings.connecting$({
        source: type,
        token: this.mailshakeApiForm.value.mailshake_api_key,
      }).subscribe((res) => {
        this.settings.setConnecting(false);
      }, (res) => {
        this.settings.setConnecting(false);
      })
    } else if(type === "lemlist"){
      this.submitLemlist = true;
      this.settings.setConnecting(true);
      this.settings.connecting$({
        source: type,
        token: this.lemlistApiForm.value.lemlist_api_key,
      }).subscribe((res) => {
        this.settings.setConnecting(false);
      }, (err) => {
        this.settings.setConnecting(false);
      })
    } else if(type === "pursue"){
      this.submitPursue = true;
      this.settings.setConnecting(true);
      this.settings.connecting$({
        source: type,
        token: this.pursueApiForm.value.pursue_api_key,
      }).subscribe((res) => {
        this.settings.setConnecting(false);
      }, (err) => {
        this.settings.setConnecting(false);
        if(err.message){
          this.toastrService.error(err.message, 'Error!');
        }
      })
    } else if(type === "highLevel"){
      this.submitHighLevel = true;
      this.settings.setConnecting(true);
      this.settings.connecting$({
        source: type,
        token: this.highLevelApiForm.value.high_level_api_key,
      }).subscribe((res) => {
        this.settings.setConnecting(false);
        this.settings.getUser().subscribe((res: any) => {
          this.settings.setUser(res);
          this.cookie.setCookie('user', JSON.stringify(res), 1);
        });
      }, (err) => {
        this.settings.setConnecting(false);
        if(err.message){
          this.toastrService.error(err.message, 'Error!');
        }
      })
    } else {
      this.submit = true;
      const connectedData = {
        source: type,
        token: this.tokenForm.value.token
      };
      this.settings.connecting$(connectedData).subscribe();
    }
  }

  disconnect(type) {
    this.settings.disconnectSource$(type).subscribe((res) => {
      this.settings.getUser().subscribe((res: any) => {
        this.settings.setUser(res);
        this.cookie.setCookie('user', JSON.stringify(res), 1);
      });
    });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('settings-page');
  }

}
