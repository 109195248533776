import {Component, Input, OnInit} from "@angular/core";
import {SettingsState} from "../../../settings/state/settings.state";
import {tap} from "rxjs/operators";
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {LemlistVariablesModel} from "../../model/lemlist-variables.model";
import {Company} from "../../model/company.model";
import {CompanyState} from "../../state/company.state";
import {Observable} from "rxjs";
import {LemlistLabelModel} from "../../../settings/model/lemlist-label.model";
import {SettingsFacade} from "../../../settings/settings.facade";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CompanyFacade} from "../../company.facade";


@Component({
  selector: "app-lemlist-tab",
  templateUrl: "lemlist-tab.component.html",
  styleUrls: ["lemlist-tab.component.css"]
})

export class LemlistTabComponent implements OnInit {

  variables: LemlistVariablesModel;
  lemlistVariables$: Observable<{ name: string, label: string, type: string[], defaultValue: string }[]>;
  lemlistVariablesForm: FormGroup;
  isLoading$: Observable<boolean>;
  lemlistLabels$: Observable<LemlistLabelModel>;
  resultVariables$: Observable<{name: string, value: string, type: string[]}[]>;
  checks = {};
  faCheck = faCheck;

  @Input() company: Company;

  constructor(
    private settingsState: SettingsState,
    private companyState: CompanyState,
    private settingsFacade: SettingsFacade,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private companyFacade: CompanyFacade,
  ) {
  }

  ngOnInit() {
    this.variables = this.company.lemlist_variables;
    this.lemlistVariables$ = this.companyState.getFlowLemlistVariables().pipe(
      tap((res: {name: string, label: string, type: string[], defaultValue: string, validators: ValidatorFn[]}[]) => {
        if(!this.lemlistVariablesForm){
          this.initLemlistForm(res);
        }
      }),
    );
    this.isLoading$ = this.companyState.isUpdating$();
    this.lemlistLabels$ = this.settingsFacade.getLemlist();
    this.resultVariables$ = this.companyState.getResultVariables();
  }

  initLemlistForm(res){
    const controls = {};
    for(let i = 0; i < res.length; i++){
      if(this.needToAddControl(res[i])){
        const variableValue = res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name];
        if(variableValue && variableValue.indexOf("{{") !== -1 && variableValue.indexOf("}}") !== -1){
          this.checks[res[i].name] = true;
        }
        controls[res[i].name] = [{value: res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name], disabled: !!res[i].defaultValue}, res[i].validators];
      }
    }
    this.lemlistVariablesForm = this.formBuilder.group(controls);
  }

  change(item: string){
    this.lemlistVariablesForm.get(item).setValue("");
  }

  needToAddControl(data: any): boolean {
    return data.type.indexOf("all") !== -1 || data.type.indexOf(this.company.type) !== -1;
  }

  saveLemlist(){
    if(this.lemlistVariablesForm.valid){
      this.companyState.setUpdating(true);
      this.companyFacade.updateFlowLemlistVariables( this.company.id, this.lemlistVariablesForm.value).subscribe((res) => {
        this.companyState.setUpdating(false);
        res.type = "lemlist";
        this.activeModal.close(res);
      }, (res) => {
        this.companyState.setUpdating(false);
      });
    }
  }

  getControl(name: string): AbstractControl{
    return this.lemlistVariablesForm.get(name);
  }

  getMaxLengthMessage(controlName: string){
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

}
