import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {PeopleTableComponent} from './people.table.component';
import {ModalComponent} from '../modal/modal.component';
import {ImgFallbackModule} from 'ngx-img-fallback';
import { EmailsModalComponent } from './emails-modal/emails-modal.component';
import { EmailDetailsState } from './emails-modal/state/EmailDetails.state';

@NgModule({
  declarations: [PeopleTableComponent],
  imports: [
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    NgbRatingModule,
    ImgFallbackModule
  ],
  entryComponents: [
    ModalComponent,
    EmailsModalComponent
  ],
  exports: [PeopleTableComponent],
  providers: [EmailDetailsState]
})
export class PeopleTableModule { }
