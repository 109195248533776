import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ResultState} from './state/result.state';
import {SearchResultModel} from '../search/model/searchResult.model';
import {ResultsApi} from './api/results.api';
import {map, tap} from 'rxjs/operators';
import {WoodpeckerModel} from '../settings/model/woodpecker.model';
import {ToastrService} from 'ngx-toastr';
import {CompanyFacade} from "../company/company.facade";
import {ActivatedRoute} from "@angular/router";
import {ResultFilterModel} from "./model/filter.model";

@Injectable()
export class ResultsFacade {

  constructor(private resultState: ResultState,
              private resultApi: ResultsApi,
              private toastr: ToastrService,
              private companyFacade: CompanyFacade,
              private route: ActivatedRoute) { }

  isLoading$(): Observable<boolean> {
    return this.resultState.isLoading$();
  }

  setLoading(loading: boolean) {
    this.resultState.setLoading(loading);
  }

  getResult$(): Observable<SearchResultModel[]> {
    return this.resultState.getResult$();
  }

  getResultPeople$(): Observable<SearchResultModel[]> {
    return this.resultState.getResultPeople$();
  }

  getNextPage(): Observable<number | null>{
    return this.resultState.getNextPage();
  }

  setNextPage(page: number | null): void{
    this.resultState.setNextPage(page);
  }

  search(keyword: string, companyId: number): Observable<SearchResultModel[]>{
    return this.resultApi.search(keyword, companyId, 0);
  }

  getResultSingle(): Observable<any>{
    return this.resultState.getResultSingle();
  }

  getItemsPerPage(): number{
    return this.resultState.getItemsPerPage();
  }

  setItemsPerPage(perPage: number){
    this.resultState.setItemsPerPage(perPage);
  }

  getSearchValue(): string{
    return this.resultState.getSearchValue();
  }

  setSearchValue(value: string){
    this.resultState.setSearchValue(value);
  }

  setCurrentPage(page: number): void{
    this.resultState.setCurrentPage(page);
  }

  getCurrentPage(): number{
    return this.resultState.getCurrentPage();
  }

  setTotalItems(items: number){
    this.resultState.setTotalItems(items);
  }

  getTotalItems(): Observable<number>{
    return this.resultState.getTotalItems();
  }

  newLoadResults(companyId: number, searchValue: string, offset: number, limit: number, filter: ResultFilterModel): Observable<SearchResultModel[]>{
    this.setLoading(true);
    return this.resultApi.loadCompanyResultsNew(companyId, searchValue, offset, limit, filter).pipe(
      tap((res) => {
        if(!filter.export) {
          if(res.length){
            switch (res[0].type) {
              case "location":
                this.resultState.setResult(res);
                break;
              case "people":
                this.resultState.setResultPeople(res);
                break;
              case undefined:
                this.resultState.setResultSingle(res);
                break;
            }
          } else {
            this.resultState.setResultSingle([])
            this.resultState.setResult([])
            this.resultState.setResultPeople([])
          }
        }
      })
    )
  }

  loadResult(company_id, page = null, searchValue = null) {
    this.setLoading(true);
    return this.resultApi.loadCompanyResults(company_id, page, searchValue).pipe(tap((res) => {
      this.setLoading(false);
      if(page === "all"){
        return;
      }
      let type = '';
      const location: SearchResultModel[] = [];
      const people = [];
      const single = [];
      res.forEach((item) => {
        if (item.type === 'location') {
          type = "location";
          location.push(item);
        } else if(item.type === "people") {
          people.push(item);
          type = "people";
        } else if(item.type === undefined){
          single.push(item);
          type = "single";
        }
      });
      if(page){
        switch (type) {
          case "location":
            this.resultState.addFlowResult(location);
            break;
          case "people":
            this.resultState.addFlowResultPeople(people);
            break;
          case "single":
            this.resultState.addFlowResultSingle(single);
            break;
        }
      } else {
        this.resultState.setResult(location);
        this.resultState.setResultPeople(people);
        this.resultState.setResultSingle(single);
      }
    }));
  }

  getwoodpeckerCampaigns$(): Observable<WoodpeckerModel[]> {
    return this.resultState.getwoodpeckerCampaigns$();
  }

  getComponentFromAddress(neededComponent: string, component: any): string|undefined{
    if(neededComponent && component){
      const componentName = this.resultState.getAddressComponents()[neededComponent];
      let addressElement = component.find((item) => {
        return item.types.indexOf(componentName) !== -1;
      });
      return addressElement ? addressElement.long_name : "";
    }
    return "";
  }

  private decrementCurrentResultCount(count){
    this.companyFacade.decrementCurrentCompanyResults(count);
  }

  loadWoodpeckerCampaigns() {
    return this.resultApi.woodpecker().pipe(tap( res => {
      this.resultState.setwoodpeckerCampaigns(res);
    }));
  }

  deleteResult(resultId: number) {
    this.resultState.deleteResult(resultId);
    return this.resultApi.deleteResult(resultId).subscribe(() => {
      this.decrementCurrentResultCount(1);
      this.toastr.success('Complete!', 'Success');
    });
  }

  deleteResults(ids) {
    return this.resultApi.deleteResults(ids).subscribe(() => {
      this.decrementCurrentResultCount(ids.length);
      ids.map((resultId) => {
        this.resultState.deleteResult(resultId);
      });
      this.toastr.success('Complete!', 'Success');
    });
  }
  deleteEmails(ids) {
    return this.resultApi.deleteEmails(ids).subscribe(() => {
      this.decrementCurrentResultCount(ids.length);
      ids.map((id) => {
        this.resultState.deleteEmail(id);
      });
      this.toastr.success('Complete!', 'Success');
    });
  }

  deleteEmail(id: number){
    this.resultState.deleteEmail(id);
    return this.resultApi.deleteEmail(id).subscribe(() => {
      this.decrementCurrentResultCount(1);
      this.toastr.success("Complete", "Success");
    });
  }
}
