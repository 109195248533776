import {Deserializable} from '../../model/deserializable';


export class SearchResultModel implements Deserializable {
  public name: string;
  public id: number;
  public place_id: string;
  public company_icon: string;
  public company_name: string;
  public statusClass: string;
  public statusMessage: string;
  public email: string;
  public role: string;
  public error: string;
  public main_location: any;
  public detail: any;
  public created_at: string;
  public location: {
    formatted_address: string,
    geometry: {
      location: {
        lat: number,
        lng: number
      }
    },
    components: {
      long_name: string,
      short_name: string,
      types: string[],
    }
  };
  public rating: number;
  public website: string;
  public linkedin_url: string;
  public phone_number: string;
  public contact_person: any;
  public type: string;
  public to_be_sent_ghl: boolean

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
