import { Directive, Input, ViewContainerRef, ComponentFactoryResolver, ElementRef, Renderer2 } from '@angular/core';
import { PendingComponent } from './pending.component';

@Directive({
  selector: '[appPending]'
})
export class PendingDirective {

  @Input() set appPending(condition: boolean) {
    this.updateView(condition);
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef,
    private componentFactory: ComponentFactoryResolver,
  ) { }

  updateView(condition: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', condition);
    if (condition) {
      const factory = this.componentFactory.resolveComponentFactory(PendingComponent);
      const componentRef = this.viewContainer.createComponent(factory);
      this.renderer.insertBefore(this.elementRef.nativeElement, componentRef.location.nativeElement, this.elementRef.nativeElement.firstChild);
    } else {
      this.viewContainer.clear();
    }
  }
}
