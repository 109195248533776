import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { SearchModel } from '../model/search.model';
import {SearchResultModel} from '../model/searchResult.model';
import {SearchResultPeopleModel} from '../model/SearchResultPeople.model';
import {SearchResultSingleModel} from '../model/search-result-single.model';

@Injectable()
export class SearchState {
  private isEU = false;
  private updating$ = new BehaviorSubject<boolean>(false);
  private updatingRes$ = new BehaviorSubject<boolean>(false);
  private updatingPeople$ = new BehaviorSubject<boolean>(false);
  private loadCompleate$ = new BehaviorSubject<boolean>(false);
  private flowAutocompleate$ = new BehaviorSubject<SearchModel[]>(null);
  private flowLocalResult$ = new BehaviorSubject<SearchResultModel[]>(null);
  private flowPeopleResult$ = new BehaviorSubject<SearchResultPeopleModel[]>(null);
  private peopleSearchPage = new BehaviorSubject<number>(0);
  private singleSearchPage = new BehaviorSubject<number>(0);
  private flowPeopleHasNext = new BehaviorSubject<boolean>(false);
  private flowSingleHasNext = new BehaviorSubject<boolean>(false);
  private locationForm = new BehaviorSubject<{location: string, place_id: string, place_name: string}>({location: '', place_id: '', place_name: ''});
  private peopleForm = new BehaviorSubject<{location: string, role: string, company: string, place_id: string}>({location: '', role: '', company: '', place_id: ""});
  private singleForm = new BehaviorSubject<{query: string}>({query: ""});
  private companyId = new BehaviorSubject<number>(null);
  private updatingSingle$ = new BehaviorSubject<boolean>(false);
  private flowSingleResult = new BehaviorSubject<any>(null);
  private flowSingleResultEmails = new BehaviorSubject<any>(null);
  private europeCountries = [
    "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czechia", "Denmark", "Estonia", "Finland", "France", "Germany",
    "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland",
    "Portugal", "Romania", "Slovakia", "Slovenia", "Spain", "Sweden", "UK",
  ];
  private locationBlockedMessage = "The Europe Countries are Blocked";

  private resSwitcher$ = new BehaviorSubject<string>(null);
  private pagerLocation$ = new BehaviorSubject<any>(false);
  private selectedLocation: any;

  get blockedCountries(){return this.europeCountries}
  get blockedMessage(){return this.locationBlockedMessage}

  constructor() { }

  isUpdating$() {
    return this.updating$.asObservable();
  }

  getPeopleCurrentPage(): number{
    return this.peopleSearchPage.getValue();
  }

  setSelectedLocation(location: any){
    this.selectedLocation = location;
  }

  getSelectedLocation(): any{
    return this.selectedLocation;
  }

  getSingleCurrentPage(): number{
    return this.singleSearchPage.getValue();
  }

  setPeopleCurrentPage(page: number) {
    this.peopleSearchPage.next(page);
  }

  setSingleCurrentPage(page: number){
    this.singleSearchPage.next(page);
  }

  setIsEU(state: boolean): void{
    this.isEU = state;
  }

  getIsEU(): boolean{
    return this.isEU;
  }

  peopleHasMore(){
    return this.flowPeopleHasNext.asObservable();
  }

  singleHasMore(){
    return this.flowSingleHasNext.asObservable();
  }

  setPeopleHasMore(hasMore: boolean){
    return this.flowPeopleHasNext.next(hasMore);
  }

  setSingleHasMore(hasMore: boolean){
    return this.flowSingleHasNext.next(hasMore);
  }

  isLoadCompleate() {
    return this.loadCompleate$.asObservable();
  }

  isUpdatingRes$() {
    return this.updatingRes$.asObservable();
  }

  isUpdatingPeople$() {
    return this.updatingPeople$.asObservable();
  }

  showSwitcher$() {
    return this.resSwitcher$.asObservable();
  }

  setSwitcher(resType: string) {
    this.resSwitcher$.next(resType);
  }

  setPagerLocation(token: any) {
    this.pagerLocation$.next(token);
  }

  getPagerLocation() {
    return this.pagerLocation$.asObservable();
  }

  setCompanyId(id: number) {
    this.companyId.next(id);
  }

  getCompanyId() {
    return this.companyId.getValue();
  }

  setLocationForm(data) {
    this.locationForm.next(data);
  }

  getLocationForm() {
    return this.locationForm.getValue();
  }

  setPeopleForm(data) {
    this.peopleForm.next(data);
  }

  setSingleForm(data){
    this.singleForm.next(data);
  }

  getPeopleForm() {
    return this.peopleForm.getValue();
  }

  getSingleForm(){
    return this.singleForm.getValue();
  }

  resetSearchForms() {
    this.setLocationForm({place: '', place_name: ''});
    this.setPeopleForm({location: '', role: '', company: ''});
    this.setSingleForm({query: ""});
  }

  setLoadCompleate(isLoadCompleate: boolean) {
    this.loadCompleate$.next(isLoadCompleate);
  }

  setUpdating(isUpdating: boolean) {
    this.updating$.next(isUpdating);
  }

  setUpdatingPeople(isUpdating: boolean) {
    this.updatingPeople$.next(isUpdating);
  }

  setUpdatingSingle(isUpdating: boolean){
    this.updatingSingle$.next(isUpdating);
  }

  getUpdatingSingle(): Observable<boolean>{
    return this.updatingSingle$.asObservable();
  }

  setUpdatingRes(isUpdatingRes: boolean) {
    return this.updatingRes$.next(isUpdatingRes);
  }

  getflowAutocompleate$() {
    return this.flowAutocompleate$.asObservable();
  }

  setflowAutocompleate$(location: SearchModel[]) {
    this.flowAutocompleate$.next(location);
  }

  setflowLocalResult$(result: SearchResultModel[]) {
    this.flowLocalResult$.next(result);
  }

  setflowPeopleResult$(result: SearchResultPeopleModel[]) {
    this.flowPeopleResult$.next(result);
  }

  setFlowSingleResult(res: SearchResultSingleModel){
    this.flowSingleResult.next(res);
    this.flowSingleResultEmails.next(res.emails)
  }

  getflowLocalResult$() {
    return this.flowLocalResult$.asObservable();
  }

  getFlowSingleResult(){
    return this.flowSingleResult.asObservable();
  }
  getFlowSingleResultEmails(){
    return this.flowSingleResultEmails.asObservable();
  }

  addflowPeopleResult$(result){
    this.setPeopleHasMore(result.length >= 10 && this.getPeopleCurrentPage() <= 8);
    const currentValue = this.flowPeopleResult$.getValue();
    if(!currentValue){
      this.flowPeopleResult$.next([result]);
    } else {
      let data = currentValue.concat(result);
      this.flowPeopleResult$.next(data);
    }
    return this.flowPeopleResult$.asObservable();
  }

  addflowLocalResult$(result) {
    const currentValue = this.flowLocalResult$.getValue();
    if (currentValue === null) {
      this.flowLocalResult$.next([result]);
    } else {
      let new_data  = currentValue.concat(result);
      this.flowLocalResult$.next(new_data);
    }
    return this.flowLocalResult$.asObservable();
  }

  addflowSingleResult(result){
    const currentValue = this.flowSingleResultEmails.getValue();
    if(currentValue === null){
      this.flowSingleResultEmails.next(result.emails)
    } else {
      const newData = currentValue.concat(result.emails);
      this.flowSingleResultEmails.next(newData);
    }
    return this.flowSingleResultEmails.asObservable();
  }

  getflowPeopleResult$() {
    return this.flowPeopleResult$.asObservable();
  }

  flowAutonull() {
    this.flowAutocompleate$.next(null);
  }
}
