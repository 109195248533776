import {Component, OnInit} from "@angular/core";
import {combineLatest, Observable} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SettingsState} from "../../state/settings.state";
import {CompanyState} from "../../../company/state/company.state";
import {SettingsFacade} from "../../settings.facade";
import {map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: "app-mailshake",
  templateUrl: "mailshake.component.html",
  styleUrls: ["mailshake.component.css"]
})

export class MailshakeComponent implements OnInit{


  isUpdating$: Observable<boolean>;
  mailshakeLabelsForm: FormGroup;
  mailshakeLabels$: Observable<any>; //ToDo need to create model for this type
  mailshakeVariables$: Observable<any>; //ToDo need to create model for this type

  constructor(
    private formBuilder: FormBuilder,
    private settingsState: SettingsState,
    private companyState: CompanyState,
    private settingsFacade: SettingsFacade,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit() {
    this.isUpdating$ = this.settingsState.isUpdating$();
    this.mailshakeLabels$ = this.companyState.getFlowMailshakeLabels();
    this.mailshakeVariables$ = this.settingsFacade.getMailshake();
    combineLatest([this.mailshakeVariables$, this.mailshakeLabels$]).pipe(
      map(([variables, labels]) => ({variables, labels}))
    ).subscribe((res) => {
      if(!this.mailshakeLabelsForm){
        this.mailshakeLabelsForm = this.formBuilder.group(this.settingsFacade.getFormLabels(res));
      }
      if(res.variables){
        this.addValues(res.variables);
      }
    })
  }

  private addValues(variables: any){
    this.mailshakeLabelsForm.patchValue(variables);
  }

  getControl(name: string){
    return this.mailshakeLabelsForm.get(name);
  }

  getMaxLengthMessage(controlName: string){
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

  save(){
    if(this.mailshakeLabelsForm.valid){
      this.settingsState.setUpdating(true);
      this.settingsFacade.updateFlowMailshake(this.mailshakeLabelsForm.value).subscribe((res) => {
        this.toastrService.success("Success", "Saved");
        this.settingsState.setUpdating(false);
      }, (res) => {
        this.settingsState.setUpdating(false);
      });
    } else {
      this.toastrService.error("Form is Invalid", "Error");
    }
  }

}
