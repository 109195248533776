import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {ResponseModel} from "../../model/response.model";

@Injectable({
  providedIn: 'root'
})

export class RemovalService {

  constructor(private httpClient: HttpClient) {
  }

  acceptEmail(token: string): Observable<ResponseModel>{
    return this.httpClient.post<ResponseModel>(`${environment.api_url}/accept`, {token: token})
  }

  submitRemovalRequest(data: {email: string}): Observable<ResponseModel>{
    return this.httpClient.post<ResponseModel>(`${environment.api_url}/removal`, data);
  }
}
