import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { EmailsDetailModel } from '../emailsDetail.model';

@Injectable()
export class EmailDetailsState {
  private loadResult$ = new BehaviorSubject<boolean>(false);
  private emailDetails$ = new BehaviorSubject<any>([]);
  private totalItems$ = new BehaviorSubject<number>(0);

  constructor() { }

  isLoading$(): Observable<boolean> {
    return this.loadResult$.asObservable();
  }

  setLoading(loading: boolean) {
    this.loadResult$.next(loading);
  }

  setTotalItems(items: number): void {
    this.totalItems$.next(items);
  }

  getTotalItems(): Observable<number> {
    return this.totalItems$.asObservable();
  }

  getEemailDetails$(): Observable<any> {
    return this.emailDetails$.asObservable();
  }

  setEemailDetails$(result: Observable<any>) {
    this.emailDetails$.next(result);
  }

}
