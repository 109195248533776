import {Component, OnInit} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {SettingsFacade} from "../../settings.facade";
import {CompanyState} from "../../../company/state/company.state";
import {PursueLabelModel} from "../../model/pursue-label.model";
import {map} from "rxjs/operators";
import {SettingsState} from "../../state/settings.state";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-pursue',
  templateUrl: './pursue.component.html',
  styleUrls: ['./pursue.component.scss']
})

export class PursueComponent implements OnInit{

  pursueLabelsForm: FormGroup;
  isUpdating$: Observable<boolean>;
  pursueLabels$: Observable<any>;
  pursueVariables$: Observable<PursueLabelModel>;

  constructor(private formBuilder: FormBuilder,
              private settingsFacade: SettingsFacade,
              private companyState: CompanyState,
              private settingsState: SettingsState,
              private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.isUpdating$ = this.settingsFacade.isUpdating$();
    this.pursueLabels$ = this.companyState.getFlowPursueLabels();
    this.pursueVariables$ = this.settingsFacade.getPursue();
    combineLatest([ this.pursueVariables$, this.pursueLabels$]).pipe(
      map(([variables, labels]) => ({variables, labels}))
    ).subscribe((res) => {
      if(!this.pursueLabelsForm){
        this.pursueLabelsForm = this.formBuilder.group(this.settingsFacade.getFormLabels(res));
      }
      if(res.variables){
        this.addValues(res.variables);
      }
    })
  }

  addValues(variables: any): void{
    this.pursueLabelsForm.patchValue(variables)
  }

  getControl(name: string): AbstractControl{
    return this.pursueLabelsForm.get(name);
  }

  getMaxLengthMessage(controlName: string){
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

  save(){
    if(this.pursueLabelsForm.valid){
      this.settingsState.setUpdating(true);
      this.settingsFacade.updateFlowPursue(this.pursueLabelsForm.value).subscribe((res) => {
        this.toastrService.success("Success", "Saved");
        this.settingsState.setUpdating(false);
      }, (res) => {
        this.settingsState.setUpdating(false);
      });
    } else {
      this.toastrService.error("Form is Invalid", "Error");
    }
  }
}
