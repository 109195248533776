import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { SearchResultModel } from '../../search/model/searchResult.model';
import { Observable } from 'rxjs';
import { WoodpeckerModel } from '../../settings/model/woodpecker.model';
import { ResultState } from "../state/result.state";
import { ResultsFacade } from "../results.facade";
import {ResultFilterModel} from "../model/filter.model";

@Injectable({
  providedIn: 'root'
})
export class ResultsApi {

  constructor(private httpClient: HttpClient, private resultState: ResultState) { }

  loadCompanyResults(company_id, page: number | null, searchValue: string | null): Observable<SearchResultModel[]> {
    return this.httpClient.post<SearchResultModel[]>
    (environment.api_url + `/company/results`, {company_id: company_id, page: page, keyword: searchValue}).pipe(
      map(
        (response: any) => {
          this.resultState.setNextPage(response.next_page);
          if(response.content.results)
            return response.content.results.map(company => new SearchResultModel().deserialize(company));
          else
            return response.content.map(company => new SearchResultModel().deserialize(company));
        }
      )
    );
  }

  loadCompanyResultsNew(companyId: number, searchValue: string, offset: number, limit: number, filter: ResultFilterModel): Observable<SearchResultModel[]>{
    return this.httpClient.post<SearchResultModel[]>(`${environment.api_url}/company/results`,
      {keyword: searchValue, company_id: companyId, offset: offset, limit: limit, filter: filter}).pipe(map((res: any) => {
      let results = res.content;
      this.resultState.setTotalItems(results.length);
      if(res.elements_count)
        this.resultState.setTotalItems(res.elements_count);
      if(res.content.results) {
        const counts = res.content.resultsCount;
        results = res.content.results;
        if (!filter.export) {
          this.resultState.setAllItems(counts.all);
          this.resultState.setWithEmailsItems(counts.withEmail);
          this.resultState.setWithOutEmailsItems(counts.withoutEmail);
          this.resultState.setTotalItems(results.length);
        }
        if (res.content.resultsCount) {
          if (filter['emailFilter'] === 'withEmails') {
            this.resultState.setTotalItems(res.content.resultsCount.withEmail)
          } else if (filter['emailFilter'] === 'withoutEmails') {
            this.resultState.setTotalItems(res.content.resultsCount.withoutEmail)
          } else {
            this.resultState.setTotalItems(res.elements_count)
          }
        } else {
          this.resultState.setTotalItems(res.elements_count);
        }
      }
      return results.map(company => new SearchResultModel().deserialize(company))
    }));
  }

  search(keyword: string, companyId: number, page: number): Observable<SearchResultModel[]>{
    return this.httpClient.post(`${environment.api_url}/company/results`, {keyword: keyword, company_id: companyId, page: page})
      .pipe(map((res: any) => {
        this.resultState.setSearchHasNexPage(res.next_page);
        return res.content.map(company => new SearchResultModel().deserialize(company));
      }));
  }

  woodpecker(filter = 'all'): Observable<WoodpeckerModel[]> {
    return this.httpClient.get<WoodpeckerModel[]>
    (environment.api_url + `/company/woodpecker`).pipe(
      map( (response: any) => response.content.map(company => new WoodpeckerModel().deserialize(company))));
  }

  deleteResult(resultId: number) {
    return this.httpClient.post(environment.api_url + '/result/delete', {id: resultId});
  }

  deleteResults(ids) {
    return this.httpClient.post(environment.api_url + '/result/delete_multiple_results', {ids: ids});
  }
  deleteEmails(ids) {
    return this.httpClient.post(environment.api_url + '/result/delete_multiple_emails', {ids: ids});
  }

  deleteEmail(id: number){
    return this.httpClient.delete(`${environment.api_url}/email/${id}`);
  }
}
