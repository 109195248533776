import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {PaginateUser} from '../model/paginate-user';

@Injectable({
  providedIn: 'root'
})
export class ApiRelatedUserService {

  constructor(private httpClient: HttpClient) { }

  getUsers(page = 1, count = 10, q = '') {
    let query;
    if (q === '') {
      query = `/related-users?page=${page}&per_page=${count}`;
    } else {
      query = `/related-users?page=${page}&per_page=${count}&q=${q}`;
    }
    return this.httpClient.get<PaginateUser>
    (environment.api_url + query, {}).pipe(
      map( (response: any) => new PaginateUser().deserialize(response)));
  }

  updateUser(value) {
    return this.httpClient.post(environment.api_url + `/related-users/user/update`, value);
  }
}
