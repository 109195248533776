import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})

export class GeoState {
  private isBlocked$ = new BehaviorSubject<boolean>(false);

  getBlockedStatus(): Observable<boolean>{
    return this.isBlocked$.asObservable();
  }

  setBlockedStatus(status: boolean){
    this.isBlocked$.next(status);
  }

}
