import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import {JwtService} from '../../services/jwt/jwt.service';
import {SettingsFacade} from '../settings.facade';

@Injectable({
  providedIn: 'root'
})
export class LinkedinGuard implements CanActivate {
  constructor(private router: Router, private settings: SettingsFacade) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code') && urlParams.get('code') !== '') {
      const user = this.settings.getUserModel();
      const connectData = {
        source: 'linkedin',
        token: urlParams.get('code'),
      };
      this.settings.setSource(true);
      this.settings.connecting$(connectData).subscribe(() => {
        window.opener.location.reload();
        window.close();
        return false;
      },
        () => {
        window.opener.location.reload();
        window.close();
        return false;
      });
      return false;
    }
    return false;
  }
}
