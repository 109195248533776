import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import {SearchComponent} from './components/search/search.component';
import {LicenseGuard} from '../license/license.guard';
import {GeoGuard} from "../geo/geo.guard";


const routes: Routes = [
  {
    path: '',
    component: SearchComponent,
    canActivate: [AuthGuard, LicenseGuard, GeoGuard]
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
