import { Component, Input, OnInit } from "@angular/core";
import { Company } from "../../model/company.model";
import { tap } from "rxjs/operators";
import { MailshakeVariablesModel } from "../../model/mailshake-variables.model";
import { Observable } from "rxjs";
import { CompanyState } from "../../state/company.state";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import { SettingsFacade } from "../../../settings/settings.facade";
import { MailshakeLabelModel } from "../../../settings/model/mailshake-label.model";
import { CompanyFacade } from "../../company.facade";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: "app-mailshake-tab",
  templateUrl: "mailshake-tab.component.html",
  styleUrls: ["mailshake-tab.component.css"]
})

export class MailshakeTabComponent implements OnInit {

  variables: MailshakeVariablesModel;
  mailshakeVariables$: Observable<{ name: string, label: string, type: string[], defaultValue: string }[]>;
  mailshakeVariablesForm: FormGroup;
  isLoading$: Observable<boolean>;
  mailshakeLabels$: Observable<MailshakeLabelModel>;
  resultVariables$: Observable<{name: string, value: string, type: string[]}[]>;
  checks = {};
  faCheck = faCheck;

  @Input() company: Company;

  constructor(
    private companyState: CompanyState,
    private settingsFacade: SettingsFacade,
    private formBuilder: FormBuilder,
    private companyFacade: CompanyFacade,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.variables = this.company.mailshake_variables;
    this.mailshakeVariables$ = this.companyState.getFlowMailshakeVariables().pipe(
      tap((res: {name: string, label: string, type: string[], defaultValue: string}[]) => {
        if(!this.mailshakeVariablesForm){
          this.initMailshakeForm(res);
        }
      }),
    );
    this.isLoading$ = this.companyState.isUpdating$();
    this.mailshakeLabels$ = this.settingsFacade.getMailshake();
    this.resultVariables$ = this.companyState.getResultVariables();
  }

  initMailshakeForm(res){
    const controls = {};
    for(let i = 0; i < res.length; i++){
      if(this.needToAddControl(res[i])){
        const variableValue = res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name];
        if(variableValue && variableValue.indexOf("{{") !== -1 && variableValue.indexOf("}}") !== -1){
          this.checks[res[i].name] = true;
        }
        controls[res[i].name] = [{value: res[i].defaultValue ? res[i].defaultValue : this.variables[res[i].name], disabled: !!res[i].defaultValue}, res[i].validators];
      }
    }
    this.mailshakeVariablesForm = this.formBuilder.group(controls);
  }

  needToAddControl(data: any): boolean {
    return data.type.indexOf("all") !== -1 || data.type.indexOf(this.company.type) !== -1;
  }

  change(item: string){
    this.mailshakeVariablesForm.get(item).setValue("");
  }

  saveMailshake(){
    this.companyState.setUpdating(true);
    this.companyFacade.updateFlowMailshakeVariables( this.company.id, this.mailshakeVariablesForm.value).subscribe((res) => {
      this.companyState.setUpdating(false);
      res.type = "mailshake";
      this.activeModal.close(res);
    }, (res) => {
      this.companyState.setUpdating(false);
    });
  }

  getControl(name: string): AbstractControl{ //ToDO this function need to create globally to prevent code repeat
    return this.mailshakeVariablesForm.get(name);
  }

  getMaxLengthMessage(controlName: string){ //ToDO this function need to create globally to prevent code repeat
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }

}
