import {Deserializable} from './deserializable';

export class UserModel implements Deserializable {
  public created_at: string;
  public email: string;
  public id: number;
  public name: string;
  public username: string;
  public woodpecker: string;
  public linkedin: string;
  public avatar: string;
  public isAdmin: number;
  public prospect_api_key: string;
  public mailshake_api_key: string;
  public high_level_api_key: string;
  public lemlist_api_key: string;
  public pursue_api_key: string;
  public antiquity: number;
  public made_requests: number;
  public requests_zeroed_at: string;
  public fb_oauth: string;
  public accept_policy: {
    ip_address: string,
    accepted: number
  }

  deserialize(input: any): this {
    Object.assign( this, input);
    return this;
  }
}
