import { Component, OnInit } from '@angular/core';
import { JwtService } from '../../services/jwt/jwt.service';
import { Observable } from 'rxjs';
import { UserModel } from '../../model/user.model';
import { SettingsFacade } from '../../settings/settings.facade';
import { environment } from '../../../environments/environment';
import {AdminFacade} from '../../admin/admin.facade';
import {SettingsApi} from '../../settings/api/settings.api';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PolicyModalComponent} from "../modal/policy-modal/policy-modal.component";
import { CookieService } from "../../services/cookie.service";

import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public user$: Observable<UserModel>;
  public adminAuth: Boolean;
  constructor(public jwtService: JwtService,
              private settings: SettingsFacade,
              public admin: AdminFacade,
              private settingsApi: SettingsApi,
              private modalService: NgbModal,
              private route: Router,
              private cookie: CookieService
              ) { }

  public environment = environment;

  ngOnInit() {
    this.adminAuth = this.cookie.getCookie('adminAuth') ? true : false;
    this.user$ = this.settings.getUser$();
    const user = JSON.parse(this.cookie.getCookie('user'));
    if (user === null) {
      this.jwtService.logout();
    }
    if(!user.accept_policy){
      this.openAcceptPolicyModal();
    }
    this.settings.setUser(user);
    this.settings.checkLicense();
    this.settingsApi.getIntegrations().subscribe((res: any) => {
      this.settings.setIntegrations(res);
    })
  }

  returnAdmin() {
    this.cookie.setCookie('auth', this.cookie.getCookie('adminAuth'), 1);
    this.cookie.setCookie('user', this.cookie.getCookie('adminUser'), 1);

    this.cookie.setCookie('adminAuth', false, 0);
    this.cookie.setCookie('adminUser', false, 0);
    location.reload();
  }

  public openAcceptPolicyModal(){
    this.modalService.open(PolicyModalComponent, {size: "xl", backdrop: 'static', centered: true});
  }

}
