import { Injectable } from '@angular/core';
import { SearchApi} from './api/search.api';
import { SearchState } from './state/search.state';
import {Observable, throwError} from 'rxjs';
import { SearchModel } from './model/search.model';
import {catchError, count, map, tap} from 'rxjs/operators';
import {SearchResultModel} from './model/searchResult.model';
import {SearchResultPeopleModel} from './model/SearchResultPeople.model';

const RATE_LIMITS = {
  2000: 'https://app.paykickstart.com/checkout/VXQq3DLZY0pNOzpdl7z9wo7g8Keld5ab',
  3500: 'https://app.paykickstart.com/checkout/MgZmXl56d4Ro2Wd3LEzpLvbq9VJwnO3D'
}

@Injectable({
  providedIn: 'root'
})
export class SearchFacade {

  constructor(private searchApi: SearchApi, private searchState: SearchState) { }

  isUpdating$(): Observable<boolean> {
    return this.searchState.isUpdating$();
  }

  isUpdatingRes$(): Observable<boolean> {
    return this.searchState.isUpdatingRes$();
  }

  isUpdatingPeople$(): Observable<boolean> {
    return this.searchState.isUpdatingPeople$();
  }

  showSwitcher$(): Observable<string> {
    return this.searchState.showSwitcher$();
  }

  isPeopleHaveMore(): Observable<boolean>{
    return this.searchState.peopleHasMore();
  }

  isSingleHasMore(): Observable<boolean>{
    return this.searchState.singleHasMore();
  }

  setSingleHasMore(more: boolean): void{
    return this.searchState.setSingleHasMore(more);
  }

  setPeopleHaveMore(more: boolean){
    return this.searchState.setPeopleHasMore(more);
  }

  isLoadCompleate$(): Observable<boolean> {
    return this.searchState.isLoadCompleate();
  }

  loadCompleate() {
    this.searchState.setLoadCompleate(false);
  }

  getflowAutocompleate$(): Observable<SearchModel[]> {
    return this.searchState.getflowAutocompleate$();
  }

  getflowLocalResults$(): Observable<SearchResultModel[]> {
    return this.searchState.getflowLocalResult$();
  }

  getflowPeopleResults$(): Observable<SearchResultPeopleModel[]> {
    return this.searchState.getflowPeopleResult$();
  }

  getFlowSingleUpdating(): Observable<boolean>{
    return this.searchState.getUpdatingSingle();
  }

  getFlowSingleResult(){
    return this.searchState.getFlowSingleResult();
  }
  getFlowSingleResultEmails(){
    return this.searchState.getFlowSingleResultEmails();
  }

  getSelectedLocation(): any{
    return this.searchState.getSelectedLocation();
  }

  setSelectedLocation(location: any){
    this.searchState.setSelectedLocation(location);
  }

  setIsEU(state: boolean): void{
    this.searchState.setIsEU(state);
  }

  getIsEU(): boolean{
    return this.searchState.getIsEU();
  }

  loadflowAutocompleate(place) {
    this.searchState.setUpdating(true);
    return this.searchApi.autocompleate(place).pipe(tap( auto => {
      this.searchState.setUpdating(false);
      this.searchState.setflowAutocompleate$(auto);
      return auto;
    }));
  }

  flowAutonull() {
    this.searchState.flowAutonull();
  }

  getRateLimits(){
    return RATE_LIMITS;
  }

  loadflowSearchResult(placeData, loadMore = false) {
    this.searchState.setUpdating(true);
    this.searchState.setUpdatingRes(true);
    return this.searchApi.loadResult(placeData).pipe(tap( result => {
      this.searchState.setUpdating(false);
      this.searchState.setSwitcher('location');
      this.searchState.setLoadCompleate(true);
      this.searchState.setUpdatingRes(false);
      if (loadMore) {
        this.searchState.addflowLocalResult$(result);
      } else {
        this.searchState.setflowLocalResult$(result);
      }
      return result;
    }), catchError((res) => {
      this.searchState.setUpdating(false);
      this.searchState.setUpdatingRes(false);
      return throwError(res);
    }));
  }

  public setUpdating(state: boolean): void{
    this.searchState.setUpdating(state);
  }

  public setUpdatingRes(state: boolean): void{
    this.searchState.setUpdating(state);
  }

  checkGeo(location: any): boolean{
    const country = location.terms[location.terms.length - 1].value;
    return !this.searchState.blockedCountries.find(element => element === country)
  }

  isEU(location: any): boolean{
    const country = location.terms[location.terms.length - 1].value;
    return this.searchState.blockedCountries.includes(country);
  }

  loadAddflowSearchResult(placeData) {
    return this.searchApi.loadResult(placeData).pipe(tap( result => {
      this.searchState.addflowLocalResult$(result);
      return result;
    }));
  }

  loadAddflowPeopleSearchResult(placeData) {
    return this.searchApi.loadPeopleResult(placeData).pipe(tap( result => {
      this.searchState.addflowPeopleResult$(result);
      return result;
    }));
  }

  loadAddflowSingleSearchResult(searchData): Observable<any>{
    return this.searchApi.loadSingleResult(searchData).pipe(
      map((res) => {
        this.searchState.addflowSingleResult(res.data);
        this.setSingleHasMore(this.singleHasMore(res));
        return this.adjustResult(res.data);
      })
    );
  }

  getPagerLocation(): Observable<any> {
    return this.searchState.getPagerLocation();
  }

  setPeopleCurrentPage(page: number): void{
    this.searchState.setPeopleCurrentPage(page);
  }

  setSingleCurrentPage(page: number): void {
    this.searchState.setSingleCurrentPage(page);
  }

  loadflowPeopleResult(placeData) {
    this.searchState.setUpdatingPeople(true);
    this.searchState.setUpdatingRes(true);
    return this.searchApi.loadPeopleResult(placeData).pipe(tap( result => {
      this.searchState.setUpdatingPeople(false);
      this.searchState.setSwitcher('people');
      this.searchState.setLoadCompleate(true);
      this.searchState.setUpdatingRes(false);
      this.searchState.setflowPeopleResult$(result);
      return result;
    }), catchError((error) => {
      this.searchState.setUpdatingPeople(false);
      this.searchState.setUpdatingRes(false);
      return throwError(error)
    }));
  }

  loadFlowSingleResult(searchData){
    this.searchState.setUpdatingSingle(true);
    this.searchState.setUpdatingRes(true);
    return this.searchApi.loadSingleResult(searchData).pipe(
      map(res => {
        const responseData = this.adjustResult(res.data);
        this.searchState.setUpdatingSingle(false);
        this.searchState.setUpdatingRes(false);
        this.searchState.setSwitcher("single");
        this.searchState.setLoadCompleate(true);
        this.searchState.setFlowSingleResult(responseData);
        this.setSingleHasMore(this.singleHasMore(res));
        return res.data;
      }),
      catchError((error) => {
        this.searchState.setUpdatingSingle(false);
        this.searchState.setUpdatingRes(false);
        return throwError(error);
      })
    );
  }

  private singleHasMore(res): boolean{
    return res.meta && res.meta.offset + res.meta.limit < res.meta.results;
  }

  adjustResult(res) {
    if (res.emails) {
      for (const key in res.emails) {
        if(!res.emails[key]['company']){
          res.emails[key]["company"] = res["company"];
        }
        res.emails[key]['is_verified'] = res.emails[key].verification && res.emails[key].verification.status === 'valid';
      }
    }
    return res;
  }

  generateLicenseErrorMessage(message: string): string{
    return `<p>${message}</p>`;
  }

  saveSearchResult(companyId, companyData, type, isEu: boolean) {
    return this.searchApi.saveResult(companyId, companyData, type, isEu);
  }
  saveIsGHL(companyData: boolean, type: string, options: any, all: boolean, searchFilter: Object, resend: boolean) {
    return this.searchApi.saveResultGHL(companyData, type, options, all, searchFilter, resend);
  }
  runCrmJob() {
    this.searchApi.runCrmJob();
  }
}
