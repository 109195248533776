import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SettingsFacade} from '../../../settings/settings.facade';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.css']
})
export class ForgotModalComponent implements OnInit {

  @Input() type = true;
  @Input() code = null;
  public forgotForm: FormGroup;
  public passwordForm: FormGroup;
  public submit = false;
  public complete = false;
  public update = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private toastr: ToastrService,
              private settings: SettingsFacade, private router: Router) {}

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.passwordForm = this.formBuilder.group({
      code: [this.code, [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]],
    }, {
      validators: this.compare_pass('password', 'password_confirmation')
    });
  }

  initial() {
    this.submit = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.update = true;
    this.settings.resetPassword(this.forgotForm.value).subscribe( res => {
      this.complete = true;
      this.update = false;
      this.toastr.success('Success', 'Message Sent');
    },
error1 => {
      this.update = false;
      this.toastr.warning('Email not exist', 'Warning');
    });
  }

  get f() { return this.forgotForm.controls; }

  get fUpdate() { return this.passwordForm.controls; }

  update_pass() {
    this.submit = true;
    if (this.passwordForm.invalid) {
      return;
    }

    this.settings.checkResetCode(this.passwordForm.value).subscribe( res => {
      if ( res.hasOwnProperty('error')) {
        this.toastr.error(res.error, 'Error!');
        this.router.navigate(['/']);
      }
      if (res.hasOwnProperty('status')) {
        if (!res.status) {
          this.router.navigate(['/']);
        }
      }
      if (res.hasOwnProperty('password')) {
        this.toastr.success(res.password, 'Status');
        this.router.navigate(['/']);
      }
    });
  }

  private compare_pass(password: string, passwordConfirmation: string) {
    return (formGroup: FormGroup) => {
      const pass = formGroup.controls[password];
      const confirm = formGroup.controls[passwordConfirmation];

      if (pass.errors && !pass.errors.confirm) {
        return;
      }
      if (pass.value === confirm.value) {
        pass.setErrors(null);
        confirm.setErrors(null);
      } else {
        pass.setErrors({ confirm: true });
        confirm.setErrors({ confirm: true });
      }
    };
  }
}
