import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import { LocationComponent } from './containers/location/location.component';
import { PeopleComponent } from './containers/people/people.component';
import { SearchRoutingModule } from './search-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchState } from './state/search.state';
import { SearchApi } from './api/search.api';
import { SearchFacade } from './search.facade';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { PendingModule } from '../modules/pending/pending.module';
import { TableModule } from '../main/tables/location/table.module';
import { PeopleTableModule } from '../main/tables/people/peopleTable.module';
import { AgmCoreModule } from '@agm/core';
import { LicenseState } from '../license/license.state';
import { SingleSearchComponent } from './containers/single-search/single-search.component';
import { SingleTableModule } from '../main/tables/single/single.table.module';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RequestCountInterceptorService } from "../services/interceptor/request-count-interceptor.service";
import { SharedModule } from "../shared/shared.module";


@NgModule({
  declarations: [
    SearchComponent,
    LocationComponent,
    PeopleComponent,
    SingleSearchComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SearchRoutingModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    MalihuScrollbarModule,
    PendingModule,
    TableModule,
    PeopleTableModule,
    SingleTableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAH91uzIBo8gNWrWCSrDz_n3A3xUBMa3jg'
    }),
    SharedModule
  ],
  exports: [SharedModule],
  providers: [
    SearchState,
    SearchApi,
    SearchFacade,
    LicenseState,
    { provide: HTTP_INTERCEPTORS, useClass: RequestCountInterceptorService, multi: true }
  ]
})
export class SearchModule { }
