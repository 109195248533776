import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SettingsFacade} from '../../../settings/settings.facade';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RestorePasswordGuard implements CanActivate {

  public constructor(private router: Router, private settings: SettingsFacade, public toastr: ToastrService, private httpClient: HttpClient) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.httpClient.post<any>(environment.api_url + `/settings/reset_password_update`, {code: next.paramMap.get('id')}).pipe(
      map(res => {
        if ( res.hasOwnProperty('error')) {
          this.toastr.error(res.error, 'Error!');
          this.router.navigate(['/']);
        } else if (res.hasOwnProperty('status')) {
          if (!res.status) {
            this.router.navigate(['/']);
          } else {
            return true;
          }
        } else {
          return true;
        }
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }
}
