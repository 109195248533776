import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SearchResultModel} from '../../../search/model/searchResult.model';
import {Observable} from 'rxjs';
import {faCheck, faStar, faPhone, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../modal/modal.component';
import {CompanyFacade} from '../../../company/company.facade';
import {ResultsFacade} from '../../../results/results.facade';
import {Company} from "../../../company/model/company.model";

@Component({
  selector: 'app-location-table',
  templateUrl: './location.table.component.html',
  styleUrls: ['./location.table.component.css']
})
export class LocationTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tableData: Observable<SearchResultModel[]>;
  @Input() totalItems: number;
  @Input() isCheckboxes: boolean = false;
  @Input() company: Company;
  @Input() changePage: any;
  @Input() ghlSelectedCnt: any;
  @Input() searchValue: string;
  @Input() resultEmailsCount = 1;
  @Output() outputData = new EventEmitter();
  @Output() outputDataGHL = new EventEmitter();
  @Output() withoutResultsGhl = new EventEmitter();
  @Output() selectAll = new EventEmitter();
  @Output() industryLoaded = new EventEmitter();
  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  faStar = faStar;
  results: SearchResultModel[];
  faPhone = faPhone;
  public select = false;
  public selectAllResults = false;
  public checkBxes = [];
  public allCheckBoxes = [];
  public checkedList: any;
  public open_table: number;
  public noResult = false;
  nextPage: number | null;
  private nextPageSubscription: any;
  private subscriber;
  public withoutResults = [];
  public ghlCheckBoxes = [];
  public emailCheckboxes = [];
  public checkedListGHL: any;



  constructor(private modalService: NgbModal,
              private companyFacade: CompanyFacade,
              private resultsFacade: ResultsFacade,
  ) { }

  ngOnInit() {
    this.collectCheckboxData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.company !== "undefined" && !changes.company.firstChange){
      this.select = false;
      this.selectAllResults = false;
      this.collectCheckboxData();
    }
    if(typeof changes.changePage !== "undefined" && !changes.changePage.firstChange) {
      if(this.selectAllResults) {
        this.select = true;
        this.selectAll.emit(this.selectAllResults);
        this.ghlCheckBoxes.map((item: any) => {
          return item.isChecked = true;
        });
      }
    }
  }
  collectCheckboxData() {
    this.nextPageSubscription = this.resultsFacade.getNextPage().subscribe((res) => {
      this.nextPage = res;
    });
    this.subscriber = this.tableData.subscribe( res => {
      this.select = false;
      this.checkBxes = [];
      this.ghlCheckBoxes = [];
      this.noResult = false;
      if (res !== null && res.length !== 0) {
        this.open_table = 0;
        res.map((item, index) => {
          let isAdded = false;
          this.allCheckBoxes.map((checkbox, index) => {
            if(checkbox.value === item.place_id) {
              isAdded = true;
              return;
            }
          });
          if(!isAdded) {
            this.checkBxes.push({
              id: index,
              value: item.place_id,
              isChecked: true
            });
          }
          this.allCheckBoxes.push({
            id: index,
            value: item.place_id,
            isChecked: true
          });
          this.ghlCheckBoxes.push({
            id: index,
            value: item.id,
            to_be_sent_ghl: item.to_be_sent_ghl,
            isChecked: false
          });
        });
      } else {
        this.noResult = true;
      }
      this.getCheckedItemList();
    });
  }

  collectTableData() {
    this.outputData.emit(this.checkedList);
    this.industryLoaded.emit('location');
  }

  checkUncheckAll() {
    this.ghlCheckBoxes.forEach((item, index) => {
      this.ghlCheckBoxes[index].isChecked = this.select;
    });
    if(!this.select) {
      this.selectAllResults = false;
    }
    this.getCheckedItemListGHL();
  }
  checkUncheckAllResults() {
    this.selectAllResults = !this.selectAllResults;
    this.selectAll.emit(this.selectAllResults);
    if(!this.selectAllResults) {
      this.select = false;
      this.checkUncheckAll();
    }
  }
  checkLocationEmail(emailId) {
    if (this.emailCheckboxes.length > 0) {
      this.emailCheckboxes.forEach((item, index) => {
        if (item === emailId) {
          this.emailCheckboxes.splice(index, 1);
        } else {
          this.emailCheckboxes.push(emailId);
        }
      });
    } else {
      this.emailCheckboxes.push(emailId);
    }
    this.collectGHLTableData();
  }
  isAllSelected(changedItem) {
    this.select = changedItem.isChecked;
    if(!this.select && this.selectAllResults) {
      this.withoutResults.push(changedItem.value)
    } else {
      this.withoutResults = this.withoutResults.filter((item, i)=> this.withoutResults[i] !== changedItem.value)
    }

    this.withoutResultsGhl.emit(this.withoutResults);
    this.getCheckedItemListGHL();
  }
  getCheckedItemListGHL() {
    this.checkedListGHL = [];
    if(this.ghlCheckBoxes) {
      this.ghlCheckBoxes.forEach((item, index) => {
        if (this.ghlCheckBoxes[index].isChecked) {
          this.tableData
            .pipe(
              map((result: SearchResultModel[]) => {
                return result.find((res: SearchResultModel) => {
                  if(this.ghlCheckBoxes[index])
                    return res.id === this.ghlCheckBoxes[index].value;
                  else
                    return false
                });
              }))
            .subscribe(result => {
              if(result)
                this.checkedListGHL.push(result.id);
            });
        }
      });
    }
    this.collectGHLTableData();
  }
  collectGHLTableData() {
    const outputData = {
      'results': this.checkedListGHL,
      'emails': this.emailCheckboxes
    };
    this.outputDataGHL.emit(outputData);
  }

  getCheckedItemList() {
    this.checkedList = [];
    this.checkBxes.forEach((item, index) => {
      if (this.checkBxes[index].isChecked) {
        this.tableData.pipe().subscribe(results => {
          results.map( ( result ) => {
            if(item.value === result.place_id) {
              this.checkedList.push(result);
              return;
            }
          })
        });
      }
    });
    this.collectTableData();
  }

  show(i) {
    if (this.open_table === i) {
      this.open_table = 0;
    } else {
      this.open_table = i;
    }
  }

  getAddressComponent(componentName: string, component: any): string{
    return this.resultsFacade.getComponentFromAddress(componentName, component);
  }

  deleteResult(id) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.modalTitle = 'Delete result';
    modalRef.componentInstance.message = 'Are you sure you want to delete the result?';
    modalRef.componentInstance.type = 'result';
    modalRef.componentInstance.id = id;
  }
  deleteSelectedResults() {
    if(this.checkedListGHL && this.ghlSelectedCnt) {
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.modalTitle = 'Delete results';
      modalRef.componentInstance.message = 'Are you sure you want to delete the selected results?';
      modalRef.componentInstance.type = 'results';
      modalRef.componentInstance.ids = this.checkedListGHL;
    }
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.nextPageSubscription.unsubscribe();
    this.resultsFacade.setNextPage(0);
  }

  loadMoreLocation(){
    if(this.nextPage){
      this.resultsFacade.loadResult(this.company.id, this.nextPage, this.searchValue).subscribe();
    }
  }

}
