import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';


import { AppComponent } from './app.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { LoginComponent } from './main/login/login.component';

import { JwtService } from './services/jwt/jwt.service';
import { JwtInterceptorService } from './services/interceptor/jwt-interceptor.service';
import { CompanyModule } from './company/company.module';
import { TermsAndPrivacyModule } from './terms-and-privacy/terms-and-privacy.module';
import { SearchModule } from './search/search.module';
import { NotFoundComponent } from './main/not-found/not-found.component';
import { ResultsModule } from './results/results.module';
import { SettingsModule } from './settings/settings.module';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ModalComponent } from './main/tables/modal/modal.component';
import { EmailsModalComponent } from './main/tables/people/emails-modal/emails-modal.component';
import {LicenseInterceptorService} from './services/interceptor/license-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import { ForgotModalComponent } from './main/modal/forgot-modal/forgot-modal.component';
import {PendingModule} from './modules/pending/pending.module';
import { RestorePasswordComponent } from './main/login/restore-password/restore-password.component';
import {AdminModule} from './admin/admin.module';
import {RelatedUserModule} from './related-users/related-user.module';
import {RequestCountInterceptorService} from "./services/interceptor/request-count-interceptor.service";
import {GeoInterceptorService} from "./services/interceptor/geo-interceptor.service";
import {BlockedComponent} from "./main/blocked/blocked.component";
import {RemovalModule} from "./main/removal/removal.module";
import {PolicyModalComponent} from "./main/modal/policy-modal/policy-modal.component";
import {TermsModalComponent} from "./main/modal/terms-modal/terms-modal.component";
import {PrivacyModalComponent} from "./main/modal/privacy-modal/privacy-modal.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "./shared/shared.module";
import { PrivacyComponent } from './terms-and-privacy/components/privacy/privacy.component';
import { TermsComponent } from './terms-and-privacy/components/terms/terms.component';
import { CookieService } from "./services/cookie.service";

export function jwtTokenGetter() {
  let authCookie = null;
  let nameEQ = "auth=";
  let ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) authCookie = c.substring(nameEQ.length,c.length);
  }
  let token = null;
  if(authCookie)
    token = JSON.parse(authCookie);
  if (token !== null) {
    return token.access_token;
  }
  return null;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    NotFoundComponent,
    ModalComponent,
    EmailsModalComponent,
    ForgotModalComponent,
    RestorePasswordComponent,
    BlockedComponent,
    PolicyModalComponent,
    TermsModalComponent,
    PrivacyModalComponent,
    PrivacyComponent,
    TermsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MalihuScrollbarModule.forRoot(),
    CompanyModule,
    TermsAndPrivacyModule,
    SearchModule,
    ResultsModule,
    SettingsModule,
    AdminModule,
    RelatedUserModule,
    AppRoutingModule,
    PendingModule,
    NgbModule,
    LoadingBarHttpClientModule,
    BrowserAnimationsModule,
    RemovalModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 3000,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: [environment.api_uri],
        blacklistedRoutes: [environment.api_url + '/login', environment.api_url + '/blocked']
      }
    })
  ],
  exports: [SharedModule],
  providers: [
    JwtService,
    {provide: HTTP_INTERCEPTORS, useClass: GeoInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: LicenseInterceptorService, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: RequestCountInterceptorService, multi: true},
    LoadingBarService
  ],
  entryComponents: [
    ForgotModalComponent,
    PolicyModalComponent,
    TermsModalComponent,
    PrivacyModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }
  constructor(cookie: CookieService) { }
}
