import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {SettingsState} from "../../state/settings.state";
import {SettingsFacade} from "../../settings.facade";
import {combineLatest, Observable} from "rxjs";
import {CompanyState} from "../../../company/state/company.state";
import {LemlistLabelModel} from "../../model/lemlist-label.model";
import {map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: "app-lemlist",
  templateUrl: "lemlist.component.html",
  styleUrls: ["lemlist.component.css"],
})

export class LemlistComponent implements OnInit {

  lemlistLabelsForm: FormGroup;
  isUpdating$: Observable<boolean>
  lemlistLabels$: Observable<{name: string, label: string, defaultValue: string, validators: ValidatorFn[]}[]>;
  lemlistVariables$: Observable<LemlistLabelModel>;

  constructor(
    private settingsState: SettingsState,
    private settingsFacade: SettingsFacade,
    private companyState: CompanyState,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit() {
    this.isUpdating$ = this.settingsState.isUpdating$();
    this.lemlistLabels$ = this.companyState.getFlowLemlistLabels();
    this.lemlistVariables$ = this.settingsFacade.getLemlist();
    combineLatest([this.lemlistVariables$, this.lemlistLabels$]).pipe(
      map(([variables, labels]) => ({variables, labels}))
    ).subscribe((res) => {
      if(!this.lemlistLabelsForm){
        this.initLabelsForm(res);
      }
      if(res.variables){
        this.addValues(res.variables);
      }
    })
  }

  private addValues(variables: any){
    this.lemlistLabelsForm.patchValue(variables);
  }

  initLabelsForm(res: {labels: {name: string, label: string, defaultValue: string, validators: Validators[]}[], variables: any}){
    const formArray = {};
    for(let label of res.labels){
      if(!label.defaultValue){
        formArray[label.name] = [(res.variables && res.variables[label.name]) ? res.variables[label.name] : "", label.validators]
      }
    }
    this.lemlistLabelsForm = this.formBuilder.group(formArray);
  }

  save(){
    if(this.lemlistLabelsForm.valid){
      this.settingsState.setUpdating(true);
      this.settingsFacade.updateFlowLemlist(this.lemlistLabelsForm.value).subscribe((res) => {
        this.toastrService.success("Success", "Saved");
        this.settingsState.setUpdating(false);
      }, (res) => {
        this.settingsState.setUpdating(false);

      });
    } else {
      this.toastrService.error("Form is Invalid", "Error");
    }
  }

  getControl(name: string){
    return this.lemlistLabelsForm.get(name);
  }

  getMaxLengthMessage(controlName: string){
    return `The Characters Count For This Input Can't Be Greater Than ${this.getControl(controlName).errors.maxlength.requiredLength}`;
  }
}
