import {Deserializable} from "../../model/deserializable";

export class HighLevelVariablesModel implements Deserializable {

  public user_id: number;
  public company_id: number;
  public firstName: string;
  public lastName: string;
  public name: string;
  public email: string;
  public phone: string;
  public address1: string;
  public city: string;
  public state: string;
  public postalCode: string;
  public website: string;
  public linkedin: string;
  public timezone: string;
  public dnd: boolean;
  public tags: string[];
  public source: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
