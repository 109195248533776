import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {AdminFacade} from '../../admin.facade';
import {Observable} from 'rxjs';
import {PaginateUser} from '../../model/paginate-user';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, map, tap} from 'rxjs/operators';
import {UserComponent} from '../../containers/modal/user/user.component';
import {DeleteComponent} from '../../containers/modal/delete/delete.component';
import {environment} from "../../../../environments/environment";
import {UserModel} from "../../../model/user.model";
import { HttpClient } from '@angular/common/http';
import {SettingsFacade} from "../../../settings/settings.facade";
import { CookieService } from "../../../services/cookie.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['id', 'name', 'parentName', 'email', 'username', 'license', 'transaction_id', 'admin', 'actions'];
  public scrollbarOptions = { axis: 'y', theme: 'dark' };
  public users$: Observable<PaginateUser>;
  public user$: Observable<UserModel>;
  public update$: Observable<boolean>;
  deleted: false;
  searchForm: FormGroup;

  constructor(private admin: AdminFacade,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private httpClient: HttpClient,
              private settings: SettingsFacade,
              private cookie: CookieService
            ) { }

  ngOnInit() {
    this.user$ = this.settings.getUser$();
    this.deleted = false;
    this.admin.getUsers(1, 10, this.deleted);
    this.update$ = this.admin.isupdataing();
    this.users$ = this.admin.getStateUsers();
    this.searchForm = this.formBuilder.group({
      q: [''],
      deleted: [this.deleted],
    });
    this.searchForm.get('q').valueChanges.pipe(debounceTime(500)).subscribe( res => {
      this.admin.setQuery(res);
      this.admin.getUsers(1, 10, this.deleted);
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('location-results-page');
    body.classList.add('overflow_admin');
  }

  changeActiveUsers(val) {
    this.deleted = val;
    this.admin.getUsers(1, 10, this.deleted);
  }

  updateUser(user): void {
    this.dialog.open(UserComponent, {
      width: '500px',
      data: { userData: user, type: 'update' }
    });
  }

  loginAsUser(userEmail): void {
    this.httpClient.post<{access_token: string, token_type: string, expires_in: string, user: {}}>(environment.api_url + '/loginAs',
      {userEmail}).pipe(tap(res => {
      const store = {access_token: res.access_token, token_type: res.token_type, expires_in: res.expires_in};
      this.cookie.setCookie('adminAuth', this.cookie.getCookie('auth'), 1);
      this.cookie.setCookie('adminUser', this.cookie.getCookie('user'), 1);
      this.cookie.setCookie('auth', JSON.stringify(store), 1);
      this.cookie.setCookie('user', JSON.stringify(res.user), 1);
    }), map((res: any) => {
      new UserModel().deserialize(res.user);
      location.href = '/';
    })).subscribe();
  }

  craateUser(): void {
    this.dialog.open(UserComponent, {
      width: '500px',
      data: { type: 'create' }
    });
  }

  deleteUser(id): void {
    this.dialog.open(DeleteComponent, {
      width: '500px',
      data: { user: id, active: false }
    });
  }
  activeUser(id): void {
    this.dialog.open(DeleteComponent, {
      width: '500px',
      data: { user: id, active: true }
    });
  }

  public prevNextPage(event) {
    const validPage = event.pageIndex + 1;
    this.admin.getUsers(validPage, event.pageSize, this.deleted);
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('location-results-page');
    body.classList.remove('overflow_admin');
  }

}
