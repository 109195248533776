import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminFacade} from '../../../admin.facade';
import {Observable, Subscription} from "rxjs";
import {ProductsModel} from "../../../model/products-model";
import {SubscriptionTypeModel} from "../../../model/subscription-type-model";
import {take} from "rxjs/operators";
import {PaginateUser} from "../../../model/paginate-user";
import {ApiAdminService} from "../../../api/api.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {
  selectedValue: any;
  public users$: Observable<PaginateUser>;
  public adminUsers$: Observable<PaginateUser>;
  userCreateForm: FormGroup;
  admin: false;
  subscription: Subscription;
  readonly defaultRequestCount = 4000;
  products: ProductsModel[] = [];
  subscriptionTypes$: Observable<SubscriptionTypeModel[]>;
  public formErrors: {
    email: [''],
    license: [''],
    name: [''],
    password: [''],
    username: ['']
  };

  constructor(public dialogRef: MatDialogRef<UserComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              public formBuilder: FormBuilder,
              private facade: AdminFacade,
              private adminService: ApiAdminService
              ) {}

  ngOnInit() {
    this.users$ = this.facade.getStateUsers();
    this.adminUsers$ = this.facade.getStateAllUsers();
    if (this.data.type === 'create') {
      this.userCreateForm = this.formBuilder.group({
        searchTxt: '',
        selectedValue: '',
        parent_id: [''],
        name: ['', [Validators.required]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        license: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        isAdmin: [false, [Validators.required]],
        people_additional: ['', [Validators.pattern("^[0-9]*$")]],
        industry_additional: ['', [Validators.pattern("^[0-9]*$")]],
        expire_date: [''],
        every_month: [false],
        subscriptionType: [''],
        subscriptionProductId: [''],
      });
    } else if (this.data.type === 'update') {
      const date = new Date(this.data.userData.license);
      const everyMonthValue = this.data.userData.additional_request ? this.data.userData.additional_request.every_month : false;
      this.userCreateForm = this.formBuilder.group({
        searchTxt: '',
        selectedValue: '',
        id: [this.data.userData.id, [Validators.required]],
        parent_id: [this.data.userData.parent_id],
        name: [this.data.userData.name, [Validators.required]],
        username: [this.data.userData.username, [Validators.required]],
        password: [''],
        license: [date, [Validators.required]],
        email: [this.data.userData.email, [Validators.required, Validators.email]],
        isAdmin: [this.data.userData.isAdmin, [Validators.required]],
        people_additional: [this.data.userData.additional_request
            ? this.data.userData.additional_request.people_count : '', [Validators.pattern("^[0-9]*$")]],
        industry_additional: [this.data.userData.additional_request
            ? this.data.userData.additional_request.industry_count : '', [Validators.pattern("^[0-9]*$")]],
        expire_date: [{
          value: this.data.userData.additional_request ? this.data.userData.additional_request.expire_date : '',
          disabled: everyMonthValue
        }],
        every_month: [everyMonthValue],
        subscriptionType: [(this.data.userData.actual_license && this.data.userData.actual_license.realIpnData) ? this.data.userData.actual_license.realIpnData.plan : ''],
        subscriptionProductId: [this.data.userData.actual_license && this.data.userData.actual_license.realIpnData ? this.data.userData.actual_license.realIpnData.product_id : '']
      });
    }
    this.facade.getProducts().pipe(take(1)).subscribe((products: ProductsModel[]) => {
      this.products = products;
    });
    this.subscriptionTypes$ = this.facade.getSubscriptionTypes();
    const expireDateControl = this.userCreateForm.get('expire_date');
    this.subscription = this.userCreateForm.get('every_month').valueChanges.subscribe((value) => {
      if(value){
        expireDateControl.disable();
      } else {
        expireDateControl.enable();
      }
    });
  }
  get f() { return this.userCreateForm.controls; }

  getErrorMessage() {
    for(let errField in this.formErrors) {
      this.userCreateForm.get(errField).markAsTouched();
    }
  }

  isAdmin() {
    this.userCreateForm.patchValue({
      isAdmin: this.userCreateForm.value.isAdmin = !this.userCreateForm.value.isAdmin
    });
  }

  action() {
    if (this.data.type === 'create') {
      this.adminService.createUser(this.userCreateForm.value).subscribe( res => {
        this.dialogRef.close();
        this.facade.getUsers();
      }, error => {
        this.formErrors = error;
        this.getErrorMessage();
      });
    }

    if (this.data.type === 'update') {
      this.adminService.updateUser(this.userCreateForm.value).subscribe( res => {
        this.dialogRef.close();
        this.facade.getUsers();
      }, error => {
        this.formErrors = error;
        this.getErrorMessage();
      });
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
