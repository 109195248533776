import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TermsAndPrivacyRoutingModule } from './terms-and-privacy-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TermsAndPrivacyRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [],
  entryComponents: [],
  providers: []
})
export class TermsAndPrivacyModule { }
