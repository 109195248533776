import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCampaignComponent } from "./common-components/select-campaign/selectCampaign.component";
@NgModule({
  declarations: [SelectCampaignComponent],
  imports: [
    CommonModule,
  ],
  exports: [SelectCampaignComponent],
})
export class SharedModule {

}
