import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RelatedUserComponent} from './componenst/related-user/related-user.component';
import {RelatedUserGuard} from './guard/related-user.guard';


const routes: Routes = [
  {
    path: 'related-accounts',
    component: RelatedUserComponent,
    canActivate: [RelatedUserGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatedUserRoutingModule { }
