import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { JwtService } from './services/jwt/jwt.service';
import {SettingsFacade} from './settings/settings.facade';
import {Observable, pipe, Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {UserModel} from "./model/user.model";
import {take} from "rxjs/operators";
import { CookieService } from "./services/cookie.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'discover-client';
  source$: Observable<boolean>;
  userSubscription: Subscription;
  constructor(public jwt: JwtService, private settings: SettingsFacade, public router: Router, private cookie: CookieService) {}

  ngOnInit() {
    this.source$ = this.settings.getSource();
    window.addEventListener('message', (event) => {
      const origin = event.origin;
      if (!origin.includes('.cometsuite.com'))
        return;
      if (typeof event.data === 'object' && event.data.call === 'authenticate') {
        this.settings.authWithJWT(event.data.token).pipe(take(1)).subscribe((res) => {
          if(res.token){
            this.cookie.setCookie('auth', JSON.stringify({access_token: res.token, expires_in: 60, token_type: "bearer"}), 1);
          }
          if(res.user){
            this.cookie.setCookie('user', JSON.stringify(res.user), 1);
          }
          window.location.reload();
        });
      }
    }, false);
  }

  ngAfterViewInit() {
  }

  isPrivacyOrTermsPages(): boolean {
    return window.location.href.includes("privacy") || window.location.href.includes("terms");
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
