import {Deserializable} from "../../model/deserializable";

export class MailshakeVariablesModel implements Deserializable{

  public company_id: number;
  public user_id: number;
  public email: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public snippet1: string;
  public snippet2: string;
  public snippet3: string;
  public snippet4: string;
  public snippet5: string;
  public snippet6: string;
  public snippet7: string;
  public snippet8: string;
  public snippet9: string;
  public snippet10: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
