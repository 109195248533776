import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './main/login/login.component';
import { LoginGuard } from './main/login/login.guard';
import { NotFoundComponent } from './main/not-found/not-found.component';
import {RestorePasswordComponent} from './main/login/restore-password/restore-password.component';
import {RestorePasswordGuard} from './main/login/restore-password/restore-password.guard';
import {GeoInitGuard} from "./geo/geo-init.guard";
import {BlockedComponent} from "./main/blocked/blocked.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'login/:id',
    component: RestorePasswordComponent,
    canActivate: [RestorePasswordGuard],
  },
  {
    path: "blocked",
    component: BlockedComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'removal',
    loadChildren: () => import('./main/removal/removal.module').then(m => m.RemovalModule)
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
