import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import { SearchModel } from '../model/search.model';
import {SearchResultModel} from '../model/searchResult.model';
import {Company} from '../../company/model/company.model';
import {SearchResultPeopleModel} from '../model/SearchResultPeople.model';
import {SearchState} from '../state/search.state';
import {SearchResultSingleModel} from '../model/search-result-single.model';
import {ToastrService} from 'ngx-toastr';
import {CompanyFacade} from '../../company/company.facade';

@Injectable({
  providedIn: 'root'
})
export class SearchApi {

  constructor(private httpClient: HttpClient, private searchState: SearchState, private toastr: ToastrService,  private companiesFacade: CompanyFacade) { }

  autocompleate(place): Observable<SearchModel[]> {
    return this.httpClient.post<SearchModel[]>
    (environment.api_url + `/search/google_autocomplete`, {place}, {headers: { ignoreLoadingBar: '' }}).pipe(
      map( (response: any) => response.content.predictions.map(autocompleate => new SearchModel().deserialize(autocompleate))));
  }

  loadResult(location): Observable<SearchResultModel[]> {
    return this.httpClient.post<SearchResultModel[]>(environment.api_url + `/search/google_search`, location)
      .pipe(tap( (res: any) => {
          if(res.message){
            this.toastr.success("Complete!", res.message);
          } else {
            this.toastr.success('Complete!', 'Success');
          }
        this.searchState.setPagerLocation(res.next_page);
        this.companiesFacade.updateflowCompanyLocal(res.company);
      }),
        map( (response: any) => response.content.map(result => new SearchResultModel().deserialize(result))));
  }

  saveResult(company_id, company_data: any, type, isEU: boolean): Observable<{ data: any, message: string | undefined }> {
    return this.httpClient.post<any>(environment.api_url + `/search/save`, {company_id, company_data, type, isEU})
      .pipe(map((res: any) => {
        const returnData = {data: res.content, message: ""};
        if(res.message){
          returnData.message = res.message;
        }
        return returnData;
      }));
  }

  saveResultGHL(company_data: any, type: string, options: any, all: boolean, searchFilter: Object, resend: boolean): Observable<{ data: any, message: string | undefined }> {
    return this.httpClient.post<any>(environment.api_url + `/search/send_to_crm`, {company_data, type, options, all, searchFilter, resend})
      .pipe(map((res: any) => {
        const returnData = {data: res.content, message: ""};
        if(res.message){
          returnData.message = res.message;
        }
        return returnData;
      }));
  }
  runCrmJob() {
    setTimeout(() => {
      return this.httpClient.post<any>(environment.api_url + `/search/run_crm_job_for_user`, {}).subscribe();
    }, 500);

  }

  loadPeopleResult(searchData): Observable<SearchResultPeopleModel[]> {
    return this.httpClient.post<SearchResultPeopleModel[]>(environment.api_url + `/search/people_search`, searchData)
      .pipe(map( (response: any) => {
        if(response.message){
          this.toastr.success("Complete!", response.message);
        } else {
          this.toastr.success('Complete!', 'Success');
        }
        this.companiesFacade.updateflowCompanyLocal(response.company);
        return response.content.map( (result, index) => {
          result.location = response.location;
          return new SearchResultPeopleModel().deserialize(result);
        });
      }));
  }
  loadSingleResult(searchData: any): Observable<any>{
    return this.httpClient.post(`${environment.api_url}/search/single_search`, searchData)
      .pipe(map((res: any) => {
        if(res.message){
          this.toastr.success("Complete!", res.message);
        } else {
          this.toastr.success('Complete!', 'Success');
        }
        // this.companiesFacade.updateflowCompanyLocal(res.company);
        return new SearchResultSingleModel().deserialize(res.content);
      }));
  }
}
