import {Component, OnDestroy, OnInit} from "@angular/core";
import {SettingsFacade} from "../../settings/settings.facade";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RemovalService} from "./api/removal.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-removal',
  templateUrl: './removal.component.html',
  styleUrls: ['removal.component.scss'],
})

export class RemovalComponent implements OnInit, OnDestroy{

  subsections = [
    {
      title: "Protection",
      description: "Website upholds strict data protection standards and is committed to protect your privacy and your business information.",
      logo: '/assets/images/lock.png',
    },
    {
      title: "New opportunities",
      description: "By having your business information available on our service, your data can be seen by professionals and recruiters that would want to connect and present new opportunities.",
      logo: '/assets/images/schedule.png',
    },
    {
      title: "Automatical update",
      description: "Your business profile is automatically and regularly updated based on the data of publicly available sources.",
      logo: '/assets/images/user.png',
    }
  ]
  removalForm: FormGroup;
  token: string;
  isLoading = false;
  message: string;

  constructor(private settingsFacade: SettingsFacade,
              private formBuilder: FormBuilder,
              private removalService: RemovalService,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('id');
    this.settingsFacade.setSource(true);
    if(this.token){
      this.isLoading = true;
      this.removalService.acceptEmail(this.token).subscribe((res) => {
        this.isLoading = false;
        this.message = res.message;
      }, (error) => {
        this.isLoading = false;
        this.message = error.message;
      });
    } else {
      this.removalForm = this.formBuilder.group({
        email: ["", [Validators.required, Validators.email]],
      })
    }
  }

  get email(){
    return this.removalForm.get('email');
  }

  submitEmail(){
    if(this.removalForm.invalid){
      this.email.markAsTouched();
      return;
    }
    this.removalService.submitRemovalRequest(this.removalForm.value).subscribe((res) => {
      if(res.status === 'success'){
        this.toastrService.success(res.message)
      }
    }, (error) => {
      console.log(error);
    });
  }

  ngOnDestroy() {
    this.settingsFacade.setSource(false);
  }
}
