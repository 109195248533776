import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faCheck, faCircle, faSearch} from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { SearchFacade } from '../../search.facade';
import {Observable, Subscription} from 'rxjs';
import { SearchModel } from '../../model/search.model';
import {SearchState} from '../../state/search.state';
import {LicenseState} from "../../../license/license.state";
import {ToastrService} from "ngx-toastr";
import {SettingsFacade} from "../../../settings/settings.facade";
import {UserModel} from "../../../model/user.model";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() companyId: any;
  faSearch = faSearch;
  faCircle = faCircle;
  faCheck = faCheck;
  locationForm: FormGroup;
  submitted: boolean;
  public autocompleate$: Observable<SearchModel[]>;
  public update$: Observable<boolean>;
  canMakeRequests$: Observable<boolean>;
  public scrollbarOptions = { axis: 'x', theme: '' };
  loadCompleate: Observable<boolean>;
  private locationFormState: any;
  locationValidationError = false;
  private user: UserModel;
  private userSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              public searchFacade: SearchFacade,
              private form: SearchState,
              private licenseState: LicenseState,
              private settingsFacade: SettingsFacade,
              private toastrService: ToastrService) { }

  ngOnInit() {
    this.userSubscription = this.settingsFacade.getUser$().subscribe((user) => {
      this.user = user;
    })
    this.locationFormState = this.form.getLocationForm();
    this.canMakeRequests$ = this.licenseState.getCanMakeRequest();
    this.loadCompleate = this.searchFacade.isLoadCompleate$();
    this.locationForm = this.formBuilder.group({
      location: [this.locationFormState.location, [Validators.required]],
      place_name: [this.locationFormState.place_name, [Validators.required]],
      place_id:  [this.locationFormState.place_id, [Validators.required]],
    });
    this.locationForm.get('location').valueChanges.pipe(debounceTime(500)).subscribe( value => {
      if (value !== '') {
        this.searchFacade.loadflowAutocompleate(value).subscribe();
      } else {
        this.searchFacade.flowAutonull();
      }
    });
    this.autocompleate$ = this.searchFacade.getflowAutocompleate$();
    this.update$ = this.searchFacade.isUpdating$();
  }

  get f() { return this.locationForm.controls; }

  search_location(): void {
    this.submitted = true;
    if (this.locationForm.invalid) {
      return;
    }
    const formValues = this.locationForm.value;
    const selectedLocation = this.searchFacade.getSelectedLocation();
    if(selectedLocation && selectedLocation.description !== formValues.location){
      this.locationValidationError = true;
      return;
    }
    this.locationValidationError = false;
    this.form.setLocationForm(formValues);
    formValues.companyId = this.companyId;
    this.searchFacade.loadflowSearchResult(formValues).subscribe();
  }

  setLocation(location: SearchModel): void {
    const isEU = this.searchFacade.checkGeo(location);
    this.searchFacade.setIsEU(isEU);
    if(this.searchFacade.checkGeo(location) || (this.user.accept_policy && this.user.accept_policy.accepted === 1)){
      this.locationValidationError = false;
      this.searchFacade.setSelectedLocation(location);
      this.locationForm.patchValue({
        location: location.description,
        place_id: location.place_id
      });
    } else {
      this.toastrService.warning("Blocked", this.form.blockedMessage);
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
